import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import classes from "./CseVote.module.scss";
import {
  Input,
  LargeContainer,
  MediumContainer,
  Modal,
  ModalClose,
  ModalOverlay,
  Title,
  Error,
} from "../../../utils/styled-components-library";
import CustomButton from "../../../components/buttons/CustomButton";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import { CseCollegeListType_Db } from "../../../types/cseCollegeList";
import API, { handleDownload } from "../../../utils/API";
import cn from "classnames";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { timeIsBefore, timeNowUtc } from "../../../utils/time";
import SignatureContainer from "../../../components/SignatureContainer";
import styled from "styled-components";
import useCseVoteReceipts from "../../../hooks/useCseVoteReceipts";
import ListPreview from "./ListPreview";
import { Tooltips } from "../../Tooltip";
import { HelperContext } from "../../../App";
import EncryptRsa from "encrypt-rsa";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChechboxInput = styled.input`
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmationLabel = styled.label`
  color: ${(props) => (props.disabled ? "#CCC" : "black")};
  text-align: left;
  cursor: pointer;
`;

const AvatarBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

let tooltipsNumber = 0;

const checkTimeValidity = (user: any) => {
  const now = moment.utc();
  const start = moment.utc(user.cseStartDate).tz("Europe/Paris");
  const end = moment.utc(user.cseEndDate).tz("Europe/Paris");

  let hasStarted = start < now;
  let hasEnded = end < now;

  return hasStarted && !hasEnded;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0px 5px",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    goBack: {
      display: "flex",
      alignItems: "center",
      gap: 5,
      zIndex: 2,
      cursor: "pointer",
      paddingLeft: 15,
    },
  })
);

const VoteChoice = ({
  collegeName = "",
  electionName = "",
  cseName = "",
  handleNext,
  hasVoted = false,
  canVote = false,
}) => {
  const helper = useContext(HelperContext);
  const updateTooltipNumber = () => {
    tooltipsNumber += 1;
  };
  return (
    <div className={classes.ElectionChoice_Vote}>
      <div>
        <p className={classes.ElectionChoice_CollegeName}>{cseName}</p>
        <p className={classes.ElectionChoice_ElectionName}>{electionName}</p>
      </div>
      {!hasVoted &&
        (canVote ? (
          <div>
            <CustomButton
              onClick={() => handleNext({ electionName })}
              style={
                helper.enabled
                  ? { width: "auto", zIndex: 200, position: "relative" }
                  : { width: "auto" }
              }
            >
              <>Voter</>
            </CustomButton>
            {(helper.enabled && (
              <>
                {updateTooltipNumber()}
                <Tooltips tooltipNumber={tooltipsNumber}>
                  <>
                    Cliquez sur <strong>“Voter”</strong> pour accéder au vote
                  </>
                </Tooltips>
              </>
            )) ||
              null}
          </div>
        ) : (
          <>
            <span className={classes.ElectionChoice_hasVoted}>
              Le vote est clos
            </span>
            {(helper.enabled && (
              <>
                <div style={{ zIndex: 500, position: "relative" }}>
                  <ModalHelper />
                </div>
              </>
            )) ||
              null}
          </>
        ))}
      {hasVoted && (
        <>
          <span className={classes.ElectionChoice_hasVoted}>
            Vous avez déjà voté
          </span>
          {(helper.enabled && (
            <>
              <div style={{ zIndex: 500, position: "relative" }}>
                <ModalHelper />
              </div>
            </>
          )) ||
            null}
        </>
      )}
    </div>
  );
};

const ElectionChoice = ({ user, handleNext }) => {
  const { isReady, votedReceipts, notVotedReceipts } = useCseVoteReceipts(user);
  const canVote = checkTimeValidity(user) && user.isClosed !== 1;
  const hasElection = notVotedReceipts.length || votedReceipts.length;
  tooltipsNumber = 0;
  return (
    <LargeContainer className={classes.ElectionLists}>
      <p className={classes.ElectionChoice_Title}>
        Bonjour{" "}
        <span
          className={classes.ElectionChoice_Name}
        >{`${user.cseVoterFirstname} ${user.cseVoterLastname}`}</span>
      </p>

      {!isReady && <p>Chargement...</p>}

      {isReady && (
        <>
          {!!notVotedReceipts.length && <p>Vous êtes invité(e) à voter</p>}
          <div className={classes.ElectionChoice_VoteChoiceContainer}>
            {notVotedReceipts.map((receipt) => (
              <VoteChoice
                canVote={canVote}
                handleNext={handleNext}
                cseName={user.cseName}
                collegeName={user.collegeName}
                electionName={receipt.collegeListElectionName}
              />
            ))}
          </div>

          {!hasElection && (
            <div
              style={{
                textAlign: "left",
                margin: 20,
                fontSize: 16,
              }}
            >
              <p>
                Merci de vous être connecté(e). <br />
                Aucune liste ne s’étant présentée, vous n’êtes pas invité(e) à
                voter. <br />
                <strong>
                  Nous vous remercions de conserver cependant vos codes d’accès
                  ProVote pour les éventuelles prochaines opérations.
                </strong>{" "}
                <br /> <br />
                Bien cordialement
              </p>
            </div>
          )}

          {!!votedReceipts.length && !!notVotedReceipts.length && (
            <div className={classes.borderSpacer} />
          )}
          {!!votedReceipts.length && <p>Vous avez déjà voté pour</p>}
          <div className={classes.ElectionChoice_VoteChoiceContainer}>
            {votedReceipts.map((receipt, ind) => {
              const latest = receipt.receipts[0];
              return (
                <>
                  <VoteChoice
                    hasVoted
                    handleNext={handleNext}
                    cseName={user.cseName}
                    collegeName={user.collegeName}
                    electionName={receipt.collegeListElectionName}
                  />
                  {latest.cseVoteResultDatetime && (
                    <span className={classes.VoteChoice_Name}>
                      Horodatage vote enregistré le{" "}
                      {moment
                        .utc(latest.cseVoteResultDatetime)
                        .format("DD/MM/YYYY [à] HH:mm")}{" "}
                      UTC
                    </span>
                  )}
                  <div style={{ textAlign: "left" }}>
                    <br />
                    <AttendanceButtons
                      electionName={latest.collegeListElectionName}
                      user={user}
                    />
                    <br />
                    <strong>Mon émargement</strong> atteste de ma connexion pour
                    avoir voté à{" "}
                    <strong>
                      {user.cseName} - {user.collegeName}
                    </strong>{" "}
                    ce document est téléchargeable autant de fois que vous
                    souhaitez.
                    <br />
                    <br />
                  </div>
                  {ind !== votedReceipts.length - 1 && (
                    <div className={classes.borderSpacer} />
                  )}
                </>
              );
            })}
          </div>
        </>
      )}
    </LargeContainer>
  );
};

const DocumentPreviewModal = ({
  title,
  onClose,
  isOpen,
  collegeList,
  filename,
  fileLink,
  downloadText,
}) => {
  if (!isOpen) {
    return <></>;
  }
  return (
    <ModalOverlay onClick={onClose}>
      <Modal
        style={{ marginTop: 100 }}
        smModal
        onClick={(e) => e.stopPropagation()}
      >
        <ModalClose onClick={onClose} />
        <div className={classes.ElectionListDocuments}>
          <span className={classes.ElectionListDocuments_title}>{title}</span>
          <div className={classes.ElectionListDocuments_ActionContainer}>
            <ListPreview collegeList={collegeList} />
            <CustomButton
              onClick={() => {
                handleDownload(fileLink, filename);
              }}
              invert
              style={{ width: "auto" }}
            >
              <>Télécharger {downloadText ? downloadText : filename}</>
            </CustomButton>
          </div>
          <div className={classes.spacer} />
          <iframe
            style={{ height: "60vh" }}
            src={fileLink}
            width="100%"
            title={filename}
          />
        </div>
      </Modal>
    </ModalOverlay>
  );
};

const ModalHelper = () => {
  const helper = useContext(HelperContext);
  const onClose = () => {
    if (helper.setEnabled) {
      helper.setEnabled();
    }
  };
  return (
    <ModalOverlay onClick={onClose}>
      <Modal
        style={{ marginTop: 100, zIndex: 500 }}
        smModal
        onClick={(e) => e.stopPropagation()}
      >
        <ModalClose onClick={onClose} />
        <div style={{ display: "flex", flexDirection: "column", gap: 25 }}>
          La mode accompagné est maintenant terminé, vous allez être redirigé
          vers la page de vote.
          <CustomButton
            onClick={() => {
              onClose();
            }}
          >
            <>Terminer</>
          </CustomButton>
        </div>
      </Modal>
    </ModalOverlay>
  );
};

const ElectionDocumentButtons = ({
  college,
  filename,
  fileLink,
  downloadText,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={classes.ElectionListDocuments_buttonContainer}>
      <CustomButton onClick={toggle} style={{ width: "auto" }}>
        <>Visualiser {downloadText ? downloadText : filename}</>
      </CustomButton>

      <CustomButton
        onClick={() => {
          handleDownload(fileLink, filename);
        }}
        invert
        style={{ width: "auto" }}
      >
        <>Télécharger {downloadText ? downloadText : filename}</>
      </CustomButton>
      <DocumentPreviewModal
        title={title}
        onClose={toggle}
        isOpen={isOpen}
        collegeList={college}
        filename={filename}
        fileLink={fileLink}
        downloadText={downloadText}
      />
    </div>
  );
};

const ElectionCandidatePreview = ({ candidate, college }) => {
  const [isOpenManifesto, setIsOpenManifesto] = useState(false);
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  const toggleManifesto = () => setIsOpenManifesto(!isOpenManifesto);
  const toggleVideo = () => setIsOpenVideo(!isOpenVideo);

  return (
    <div className={classes.ElectionListDocuments_Candidate}>
      <AvatarBox>
        <img
          src={
            candidate.candidateImage
              ? config.s3BaseUrl + candidate.candidateImage
              : "/img/avatar.png"
          }
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          alt="profile"
        />
      </AvatarBox>

      <span className={classes.ElectionListDocuments_Candidate_Name}>
        {sexTranslation(candidate.candidateSex)} {candidate.candidateFirstname}{" "}
        {candidate.candidateLastname}{" "}
        {candidate.candidateCustomField
          ? `(${candidate.candidateCustomField})`
          : ""}
      </span>
      <div className={classes.ElectionListDocuments_Candidate_Images}>
        {candidate.candidateManifesto ? (
          <img
            style={{ cursor: "pointer", objectFit: "contain" }}
            onClick={toggleManifesto}
            src={"/img/file.svg"}
            alt="file"
          />
        ) : (
          <div style={{ width: "24px" }} />
        )}
        {candidate.candidateVideo ? (
          <img
            style={{ cursor: "pointer", objectFit: "contain" }}
            onClick={toggleVideo}
            src={"/img/video.svg"}
            alt="video"
          />
        ) : (
          <div style={{ width: "24px" }} />
        )}
      </div>
      <DocumentPreviewModal
        title={candidate.candidateManifestoFilename}
        onClose={toggleManifesto}
        isOpen={isOpenManifesto}
        collegeList={college}
        filename={candidate.candidateManifestoFilename}
        fileLink={config.s3BaseUrl + candidate.candidateManifesto}
        downloadText={candidate.candidateManifestoFilename}
      />
      <DocumentPreviewModal
        title={candidate.candidateVideoFilename}
        onClose={toggleVideo}
        isOpen={isOpenVideo}
        collegeList={college}
        filename={candidate.candidatVideoFilename}
        fileLink={config.s3BaseUrl + candidate.candidateVideo}
        downloadText={candidate.candidateVideoFilename}
      />
    </div>
  );
};

export const ElectionListDocuments = ({
  onClose,
  isOpen,
  collegeList,
}: {
  collegeList: CseCollegeListType_Db;
  isOpen: boolean;
  onClose: () => void;
}) => {
  if (!isOpen) {
    return <></>;
  }
  return (
    <ModalOverlay onClick={onClose}>
      <Modal
        style={{ marginTop: 100 }}
        smModal
        onClick={(e) => e.stopPropagation()}
      >
        <ModalClose onClick={onClose} />
        <div className={classes.ElectionListDocuments}>
          <span className={classes.ElectionListDocuments_title}>
            Documentation
          </span>
          <ListPreview collegeList={collegeList} />
          <div className={classes.spacer} />
          {collegeList.collegeListCampaignVideo && (
            <div className={classes.ElectionListDocuments_container}>
              <span className={classes.ElectionListDocuments_subtitle}>
                VIDÉO DE CAMPAGNE
              </span>
              <video
                width="100%"
                src={config.s3BaseUrl + collegeList.collegeListCampaignVideo}
                controls
              />
            </div>
          )}
          {collegeList.collegeListManifesto && (
            <div className={classes.ElectionListDocuments_container}>
              <span className={classes.ElectionListDocuments_subtitle}>
                PROFESSION DE FOI
              </span>
              <ElectionDocumentButtons
                college={collegeList}
                filename={collegeList.collegeListManifestoFilename}
                fileLink={config.s3BaseUrl + collegeList.collegeListManifesto}
                downloadText={"la profession de foi"}
                title={"Profession de foi"}
              />
            </div>
          )}
          {!!collegeList.cseCandidates?.length && (
            <div className={classes.ElectionListDocuments_container}>
              <span className={classes.ElectionListDocuments_subtitle}>
                CANDIDATS
              </span>
              <div className={classes.ElectionListDocuments_container}>
                {collegeList.cseCandidates.map((candidate) => (
                  <ElectionCandidatePreview
                    candidate={candidate}
                    college={collegeList}
                  />
                ))}
              </div>
            </div>
          )}
          {!!collegeList.cseCollegeListDocuments?.length && (
            <div className={classes.ElectionListDocuments_container}>
              <span className={classes.ElectionListDocuments_subtitle}>
                AUTRE DOCUMENTS
              </span>
              {collegeList.cseCollegeListDocuments.map((document) =>
                document.documentFilename ? (
                  <ElectionDocumentButtons
                    college={collegeList}
                    filename={document.documentFilename}
                    fileLink={config.s3BaseUrl + document.document}
                    downloadText={document.documentFilename}
                    title={document.documentFilename}
                  />
                ) : (
                  <></>
                )
              )}
            </div>
          )}
        </div>
      </Modal>
    </ModalOverlay>
  );
};

const ElectionList = ({
  collegeList,
  handleNext,
  voteByList,
  stepHelp,
  setStepHelp,
  index,
}: {
  collegeList: CseCollegeListType_Db;
  handleNext: any;
  voteByList: number;
  stepHelp: number;
  setStepHelp: any;
  index: number;
}) => {
  const helper = useContext(HelperContext);

  const [isOpenList, setIsOpenList] = useState(false);

  const toggleOpen = () => {
    setIsOpenList(!isOpenList);
  };

  const candidateDocuments = collegeList.cseCandidates.filter(
    (candidate) => candidate.candidateManifesto || candidate.candidateVideo
  );

  const isDocumented =
    candidateDocuments.length !== 0 ||
    collegeList.cseCollegeListDocuments.length !== 0 ||
    collegeList.collegeListManifesto !== null ||
    collegeList.collegeListCampaignVideo !== null;

  tooltipsNumber += 1;

  const voteBy = collegeList.hasCandidate ? "liste" : "sigle";

  return (
    <div
      className={classes.ListInfoContainer}
      key={collegeList.cseCollegeListId}
    >
      <ListPreview collegeList={collegeList} />
      <div
        className={classes.CollegeList_buttonContainer}
        style={helper.enabled ? { alignItems: "baseline" } : {}}
      >
        <div>
          <CustomButton disabled={!isDocumented} onClick={toggleOpen} invert>
            <div className={classes.CollegeList_buttonContainer_children}>
              <img
                style={{
                  objectFit: "contain",
                }}
                src={"/img/folder.svg"}
                alt="folder"
              />
              <span>
                {isDocumented ? "Documentation" : "Pas de documentation"}
              </span>
            </div>
          </CustomButton>
          {helper.enabled && (
            <Tooltips
              tooltipNumber={stepHelp === 1 ? index + 1 : 0}
              handleNext={setStepHelp}
              step={stepHelp}
            >
              Vous pouvez consulter la documentation associée à la liste en
              cliquant sur ce bouton
            </Tooltips>
          )}
        </div>

        <div>
          <CustomButton
            onClick={() => handleNext({ collegeList, voteType: "list" })}
          >
            <div className={classes.CollegeList_buttonContainer_children}>
              <img
                style={{
                  objectFit: "contain",
                }}
                src={"/img/people-white.svg"}
                alt="people"
              />
              <span>{voteBy == "sigle" ? "Voter" : "Voter par " + voteBy}</span>
            </div>
          </CustomButton>
          {helper.enabled && (
            <Tooltips
              tooltipNumber={stepHelp === 2 ? index + 1 : 0}
              handleNext={setStepHelp}
              step={
                voteByList !== 1 && collegeList.hasCandidate
                  ? stepHelp
                  : stepHelp + 1
              }
            >
              Vous pouvez choisir de voter par {voteBy}
            </Tooltips>
          )}
        </div>

        {(voteByList !== 1 && collegeList.hasCandidate && (
          <div>
            <CustomButton
              onClick={() => handleNext({ collegeList, voteType: "candidate" })}
            >
              <div className={classes.CollegeList_buttonContainer_children}>
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={"/img/person-white.svg"}
                  alt="person"
                />
                <span>Voter par candidat</span>
              </div>
            </CustomButton>
            {helper.enabled && (
              <Tooltips
                tooltipNumber={stepHelp === 3 ? index + 1 : 0}
                handleNext={setStepHelp}
                step={stepHelp}
              >
                Vous pouvez également choisir de voter par candidat
              </Tooltips>
            )}
          </div>
        )) ||
          null}
      </div>
      <ElectionListDocuments
        collegeList={collegeList}
        isOpen={isOpenList}
        onClose={toggleOpen}
      />
    </div>
  );
};

const ElectionLists = ({ user, handleNext, electionName }) => {
  const helper = useContext(HelperContext);
  const [stepHelp, setStepHelp] = useState(1);

  return (
    <>
      <LargeContainer className={classes.ElectionLists}>
        <p className={classes.ElectionLists_Title}>Consultation des listes</p>
        <p className={classes.ElectionLists_CollegeName}>{user.collegeName}</p>
        <p className={classes.ElectionLists_ElectionName}>{electionName}</p>
        <div className={classes.CollegeList_Container}>
          {user.cseCollegeLists
            .filter(
              (college: CseCollegeListType_Db) =>
                college.collegeListElectionName === electionName
            )
            .map((collegeList: CseCollegeListType_Db, index) => (
              <ElectionList
                handleNext={handleNext}
                collegeList={collegeList}
                voteByList={user.voteByList}
                stepHelp={stepHelp}
                setStepHelp={setStepHelp}
                index={index}
              />
            ))}

          <CustomButton
            onClick={() => handleNext({ collegeList: null, voteType: "blank" })}
            style={{ padding: "15px 0" }}
          >
            <>Je vote blanc</>
          </CustomButton>
          {helper.enabled && (
            <Tooltips
              tooltipNumber={stepHelp === 4 ? 1 : 0}
              handleNext={setStepHelp}
              step={stepHelp}
            >
              Enfin, vous pouvez choisir de voter blanc
            </Tooltips>
          )}
        </div>
      </LargeContainer>
      {stepHelp === 5 && (
        <div style={{ zIndex: 500, position: "relative" }}>
          <ModalHelper />
        </div>
      )}
    </>
  );
};

const VoteByCandidatesExplanation = () => (
  <div className={classes.VoteExplanations}>
    <p className={classes.text}>
      <span className={classes.title}>Voter par candidat</span>
      <br />
      <br />
      Vous avez choisi l’option de voter par candidat, pour voter merci de
      sélectionner vos candidats puis de saisir en bas de page votre mot de
      passe et de cliquer sur « A voté »
      <br />
      <br />
      Pour rayer un candidat de la liste, cliquez sur son nom, si vous souhaitez
      le ré-ajouter cliquer sur son nom une nouvelle fois.
      <br />
      <br />
      Si vous ne souhaitez pas <strong>voter par candidat</strong> veuillez
      cliquer sur retour en haut à gauche.
      <br />
      <br />
      <strong>
        En cliquant sur « A voté », votre vote sera définitif et non modifiable.
      </strong>
    </p>
  </div>
);

const VoteByListExplanation = () => (
  <div className={classes.VoteExplanations}>
    <p className={classes.text}>
      <span className={classes.title}>Voter par liste</span>
      <br />
      <br />
      Vous avez choisi l’option de voter pour la liste complète, pour voter
      merci de saisir en bas de page <strong>votre mot de passe</strong> et de
      cliquer sur <strong>« A voté »</strong>
      <br />
      <br />
      Si vous ne souhaitez pas <strong>voter par liste</strong> veuillez cliquer
      sur retour en haut à gauche.
      <br />
      <br />
      <strong>
        En cliquant sur « A voté », votre vote sera définitif et non modifiable.
      </strong>
    </p>
  </div>
);

const VoteBySigleExplanation = () => (
  <div className={classes.VoteExplanations}>
    <p className={classes.text}>
      <span className={classes.title}>Voter</span>
      <br />
      <br />
      Pour voter, merci d'émarger, renseigner votre mot de passe et cliquer sur{" "}
      <strong>« A voté »</strong>
      <br />
      <br />
      <strong>
        Merci de noter qu'en cliquant sur « A voté », votre vote sera définitif
        et non modifiable.
      </strong>
      <br />
      <br />
      Si vous souhaitez retourner à la page précédente veuillez cliquer sur
      retour en haut à gauche.
    </p>
  </div>
);

const VoteBlank = () => (
  <div className={classes.VoteExplanations}>
    <p className={classes.text}>
      <span className={classes.title}>Voter blanc</span>
      <br />
      <br />
      Vous avez choisi l’option de voter blanc, pour voter merci de saisir en
      bas de page <strong>votre mot de passe</strong> et de cliquer sur{" "}
      <strong>“A voté”</strong>
      <br />
      <br />
      Si vous ne souhaitez <strong>voter blanc</strong> veuillez cliquer sur
      retour en haut à gauche.
      <br />
      <br />
      <strong>
        En cliquant sur “A Voté”, votre vote sera définitif et non modifiable.
      </strong>
    </p>
  </div>
);

const CandidateVote = ({
  candidate,
  isSelected,
  toggleSelection,
  voteByList,
}) => {
  return (
    <div
      onClick={toggleSelection}
      className={cn(classes.CandidateVote, {
        [classes.CandidateVote__selected]: isSelected,
        [classes.CandidateVote__voteByList]: voteByList,
      })}
    >
      <AvatarBox>
        <img
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          src={
            candidate.candidateImage
              ? config.s3BaseUrl + candidate.candidateImage
              : "/img/avatar.png"
          }
          alt="profile"
        />
        <span
          className={cn(classes.CandidateVote_Name, {
            [classes.CandidateVote_Name__unselected]: !isSelected,
          })}
        ></span>
      </AvatarBox>

      <span
        className={cn(classes.CandidateVote_Name, {
          [classes.CandidateVote_Name__unselected]: !isSelected,
        })}
      >
        {sexTranslation(candidate.candidateSex)} {candidate.candidateFirstname}{" "}
        {candidate.candidateLastname}{" "}
        {candidate.candidateCustomField
          ? `(${candidate.candidateCustomField})`
          : ""}
      </span>
      <div className={classes.CandidateVote_Images}>
        {isSelected ? (
          <img
            style={{
              objectFit: "contain",
            }}
            src={"/img/check.svg"}
            alt="validé"
          />
        ) : (
          <img
            style={{
              objectFit: "contain",
            }}
            src={"/img/check-close.svg"}
            alt="rayé"
          />
        )}
      </div>
    </div>
  );
};

const VoteExplanations = ({ voteType }) => {
  switch (voteType) {
    case "candidate":
      return <VoteByCandidatesExplanation />;
    case "list":
      return <VoteByListExplanation />;
    case "sigle":
      return <VoteBySigleExplanation />;
    case "blank":
      return <VoteBlank />;
    default:
      return <></>;
  }
};

const sexTranslation = (sex) => {
  if (sex === "male") return "Monsieur";
  if (sex === "female") return "Madame";
  return sex;
};

const VoteSummary = ({ candidatesVoteResults }) => (
  <div className={classes.VoteSummary}>
    <p className={classes.VoteSummary_title}>Récapitulatif du vote</p>
    <ol>
      {candidatesVoteResults.map((candidate) => (
        <li
          style={{
            textDecoration: candidate.isSelected ? "none" : "line-through",
          }}
          className={classes.VoteSummary_name}
        >
          {sexTranslation(candidate.candidateSex)}{" "}
          <strong>
            {candidate.candidateFirstname} {candidate.candidateLastname}{" "}
            {candidate.candidateCustomField
              ? `(${candidate.candidateCustomField})`
              : ""}
          </strong>
        </li>
      ))}
    </ol>
    {!candidatesVoteResults.filter((candidate) => candidate.isSelected)
      .length && <strong>Votre vote sera compté comme blanc</strong>}
  </div>
);

const VoteConfirmation = ({ handleVote, user }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signatureRef, setSignatureRef] = useState(null);
  const [checkboxConfirm, setCheckboxConfirm] = useState(null);

  const [showSmsPassword, setShowSmsPassword] = useState(false);

  return (
    <>
      <div className={classes.VoteAttendance}>
        <Title>
          Confirmation de la présence de{" "}
          <strong>
            {user.cseVoterFirstname + " " + user.cseVoterLastname.toUpperCase()}
          </strong>
        </Title>
        <></>

        <ConfirmationContainer>
          {user.signatureNeeded ? (
            <SignatureContainer
              ref={(ref) => {
                setSignatureRef(ref);
              }}
            />
          ) : (
            <CheckboxContainer>
              <ChechboxInput
                type="checkbox"
                ref={(ref) => {
                  setCheckboxConfirm(ref);
                }}
                id="confirmationCheckbox"
              />
              <ConfirmationLabel for="confirmationCheckbox">
                En cochant cette case, je confirme ma présence durant la session
                de vote.
              </ConfirmationLabel>
            </CheckboxContainer>
          )}
        </ConfirmationContainer>
      </div>
      <div className={classes.VoteConfirmation}>
        <img
          style={{
            objectFit: "contain",
          }}
          src="/img/password-lock.svg"
          alt="mot de passe"
        />
        <p style={{ maxWidth: 500 }}>
          En saisissant mon <strong>mot de passe</strong>, je certifie être la
          personne destinataire du code avec lequel je suis actuellement
          connecté à Provote
        </p>
        <>
          <Formik
            initialValues={{ password: "" }}
            validate={(values) => {
              const errors = {};
              if (signatureRef) {
                if (user.signatureNeeded) {
                  if (signatureRef?.isEmpty()) {
                    const message =
                      "Veuillez signer dans la zone prévue à cet effet pour confirmer votre présence";
                    errors.password = message;
                  }
                }
              } else {
                if (!checkboxConfirm?.checked) {
                  // handleUpdateCseVoterAttendance();
                  const message =
                    "Veuillez cocher la case de confirmation de votre présence";
                  errors.password = message;
                }
              }

              if (!values.password) {
                errors.password = `${
                  errors.password ? errors.password + "\n" : ""
                } Veuillez renseigner votre mot de passe`;
              }
              return errors;
            }}
            onSubmit={async (values) => {
              setIsSubmitting(true);

              handleVote({
                password: values.password,
                sign:
                  user.signatureNeeded && signatureRef
                    ? signatureRef.getTrimmedCanvas().toDataURL("image/png")
                    : null,
                setIsSubmitting,
              });
            }}
            validateOnMount
          >
            <Form className={classes.form} autoComplete="off">
              <div className={classes.CsePasswordInputContainer}>
                <Input
                  type={showSmsPassword ? "text" : "password"}
                  placeholder="Mot de passe"
                  name="password"
                />
                <i
                  className={!showSmsPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                  style={{
                    color: "#1D529C",
                    fontSize: "18px",
                    marginTop: -12,
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => setShowSmsPassword(!showSmsPassword)}
                />
              </div>
              <p>
                Validez définitivement votre vote en cliquant sur le bouton
                ci-dessous <strong>« A voté »</strong>
              </p>
              <CustomButton
                type="submit"
                disabled={isSubmitting}
                style={{ width: "auto" }}
              >
                <>A voté</>
              </CustomButton>
              <Error
                style={{ width: "100%", textAlign: "center", marginTop: 10 }}
                name="password"
                component="div"
              />
            </Form>
          </Formik>
        </>
      </div>
    </>
  );
};

const ElectionVoteBy = ({
  electionName,
  user,
  handleNext,
  collegeList,
  voteType,
}) => {
  const [candidatesVoteResults, setCandidatesVoteResults] = useState(
    collegeList?.cseCandidates?.map((candidate) => ({
      ...candidate,
      isSelected: true,
    })) || []
  );
  const handleToggleResult = (cseCandidateId) => () =>
    setCandidatesVoteResults((candidatesResults) =>
      candidatesResults.map((candidatesResult) => {
        return candidatesResult.cseCandidateId === cseCandidateId
          ? { ...candidatesResult, isSelected: !candidatesResult.isSelected }
          : candidatesResult;
      })
    );
  const handleVote = ({ setIsSubmitting, password, sign }) => {
    const encryptRsa = new EncryptRsa();
    let params = {
      password,
      sign,
      collegeListElectionName: electionName,
      candidates:
        voteType === "blank"
          ? []
          : candidatesVoteResults
              .map((candidate) => {
                const candidateIdHash =
                  encryptRsa.encryptStringWithRsaPublicKey({
                    text: String(candidate.cseCandidateId),
                    publicKey: user?.publicKey,
                  });
                const isCrossed = candidate.isSelected ? 0 : 1;

                return {
                  isCrossed,
                  candidateIdHash,
                  cseCollegeId: candidate.cseCollegeId
                    ? candidate.cseCollegeId
                    : collegeList.cseCollegeId,
                };
              })
              .sort((a, b) => 0.5 - Math.random()),
    };
    setIsSubmitting(true);

    API.post(
      "/cseVote",
      params,
      (data) => {
        setIsSubmitting(false);
        if (data.success) {
          toast.success(`Votre vote a été enregistré avec succès`);
          handleNext({ cseVoteKey: data.cseVoteKey });
        } else {
          toast.error(data.message);
        }
      },
      () => {
        setIsSubmitting(false);
        toast.error("Erreur interne, veuillez réessayer plus tard");
      }
    );
  };

  return (
    <LargeContainer className={classes.ElectionLists}>
      <VoteExplanations
        voteType={
          collegeList && !collegeList.hasCandidate && voteType != "blank"
            ? "sigle"
            : voteType
        }
      />
      <div className={classes.spacer} />
      {collegeList && voteType != "blank" && (
        <>
          <ListPreview collegeList={collegeList} />
          <div className={classes.spacer} />
        </>
      )}
      {(voteType !== "blank" && collegeList.hasCandidate && (
        <>
          <div className={classes.CandidateVoteContainer}>
            {candidatesVoteResults.map((candidate) => (
              <CandidateVote
                candidate={candidate}
                isSelected={candidate.isSelected}
                toggleSelection={handleToggleResult(candidate.cseCandidateId)}
                voteByList={voteType === "list"}
              />
            ))}
          </div>
          <div className={classes.spacer} />
          <VoteSummary candidatesVoteResults={candidatesVoteResults} />
        </>
      )) ||
        null}
      {(user.publicKey && (
        <VoteConfirmation handleVote={handleVote} user={user} />
      )) || <>Urne non scellée</>}
    </LargeContainer>
  );
};

const AttendanceButtons = ({ electionName, user }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSendAttendance = () => {
    setIsSending(true);
    API.post(
      "/cseAttendanceSend",
      { collegeListElectionName: electionName },
      (data) => {
        setIsSending(false);
        if (data.success) {
          toast.success("Votre émargement a été envoyé !");
        } else {
          toast.error("Votre émargement n'as pas pu être envoyé");
        }
      },
      () => {
        setIsSending(false);
        toast.error("Erreur interne, veuillez réessayer plus tard");
      }
    );
  };

  const handleAttendanceCertificate = () => {
    setIsDownloading(true);
    API.get(
      "/cseAttendance",
      { collegeListElectionName: electionName },
      (data) => {
        setIsDownloading(false);
        if (data.success) {
          handleDownload(
            `data:application/pdf;base64,${data.data.pdf}`,
            data.data.filename
          );
        } else {
          toast.error(data.message);
        }
      },
      () => {
        setIsDownloading(false);
        toast.error("Erreur interne, veuillez réessayer plus tard");
      }
    );
  };

  return (
    <div className={classes.AttendanceButtons}>
      <CustomButton
        disabled={isDownloading}
        onClick={handleAttendanceCertificate}
      >
        <>Télécharger l’émargement</>
      </CustomButton>
      <CustomButton
        onClick={handleSendAttendance}
        disabled={isSending || !user.cseVoterEmail}
      >
        <>M’envoyer mon émargement</>
      </CustomButton>
    </div>
  );
};

const DepositCertificateButtons = ({ electionName, cseVoteKey, user }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSendDepositCertificate = () => {
    setIsSending(true);
    API.post(
      "/sendCseDepositCertificate",
      { collegeListElectionName: electionName, cseVoteKey },
      (data) => {
        setIsSending(false);
        if (data.success) {
          toast.success("Le certificat de dépôt a été envoyé !");
        } else {
          toast.error("Le certificat de dépôt n'as pas pu être envoyé");
        }
      },
      () => {
        setIsSending(false);
        toast.error("Erreur interne, veuillez réessayer plus tard");
      }
    );
  };

  const handleDepositCertificate = () => {
    setIsDownloading(true);
    API.get(
      "/cseDepositCertificate",
      { collegeListElectionName: electionName, cseVoteKey },
      (data) => {
        setIsDownloading(false);
        if (data.success) {
          handleDownload(
            `data:application/pdf;base64,${data.data.pdf}`,
            data.data.filename
          );
        } else {
          toast.error(data.message);
        }
      },
      () => {
        setIsDownloading(false);
        toast.error("Erreur interne, veuillez réessayer plus tard");
      }
    );
  };

  return (
    <div className={classes.DepositCertificateButtons}>
      <CustomButton disabled={isDownloading} onClick={handleDepositCertificate}>
        <>Télécharger mon certificat de dépôt</>
      </CustomButton>

      <CustomButton
        onClick={handleSendDepositCertificate}
        disabled={isSending || !user.cseVoterEmail}
      >
        <>M’envoyer mon certificat de dépôt</>
      </CustomButton>
    </div>
  );
};

const VoteReceipt = ({ electionName, user, handleNext, cseVoteKey }) => {
  const { votedReceipts, notVotedReceipts } = useCseVoteReceipts(user);
  const receipt =
    votedReceipts.find(
      (votedReceipt) => votedReceipt.collegeListElectionName === electionName
    )?.[0] || {};
  const canVote = checkTimeValidity(user);

  return (
    <LargeContainer className={classes.ElectionLists}>
      <div className={classes.VoteReceipt}>
        <p className={classes.text}>
          <span className={classes.title}>Accusé de réception</span>
          <br />
          <br />
          Vous avez voté pour
          <br />
          <br />
          <div className={classes.VoteReceipt_DetailsContainer}>
            <strong>
              {user.cseName} - {user.collegeName}
            </strong>
            <span className={classes.time}>{electionName}</span>
            {receipt.cseVoteResultDatetime && (
              <span className={classes.time}>
                Horodatage vote enregistré le{" "}
                {moment
                  .utc(receipt.cseVoteResultDatetime)
                  .format("DD/MM/YYYY [à] HH:mm")}{" "}
                UTC
              </span>
            )}
          </div>
          <br />
          <AttendanceButtons user={user} electionName={electionName} />
          <br />
          <strong>Mon émargement</strong> atteste de ma connexion pour voter, ce
          document est téléchargeable autant de fois que vous souhaitez.
          <br />
          <br />
          <DepositCertificateButtons
            user={user}
            cseVoteKey={cseVoteKey}
            electionName={electionName}
          />
          <br />
          <strong>Mon certificat de dépôt</strong> atteste du dépôt et de la
          bonne prise en compte de mon bulletin de vote pour ce vote.
          <br />
          <strong style={{ color: "red" }}>
            Si vous quittez cette page, vous ne pourrez plus récupérer votre
            certificat de dépôt
          </strong>
        </p>
        {!!notVotedReceipts.length && <div className={classes.borderSpacer} />}
        {!!notVotedReceipts.length && <p>Vous êtes invité(e) à voter</p>}
        <div className={classes.ElectionChoice_VoteChoiceContainer}>
          {notVotedReceipts.map((receipt) => (
            <VoteChoice
              canVote={canVote}
              handleNext={() =>
                handleNext({
                  electionName: receipt.collegeListElectionName,
                  goTo: 1,
                })
              }
              cseName={user.cseName}
              collegeName={user.collegeName}
              electionName={receipt.collegeListElectionName}
            />
          ))}

          {!!notVotedReceipts.length && <div className={classes.spacer} />}
        </div>
        <CustomButton onClick={() => handleNext({ goTo: 0 })} invert>
          <>Retour</>
        </CustomButton>
      </div>
    </LargeContainer>
  );
};

function getStepContent(
  stepIndex: number,
  user: any,
  handleNext: any,
  selectedElectionName: string,
  collegeList?: CseCollegeListType_Db,
  voteType?: "candidate" | "list" | "blank",
  cseVoteKey: string
) {
  tooltipsNumber = 0;
  switch (stepIndex) {
    case 0:
      return <ElectionChoice user={user} handleNext={handleNext} />;
    case 1:
      return (
        <ElectionLists
          electionName={selectedElectionName}
          user={user}
          handleNext={handleNext}
        />
      );
    case 2:
      return (
        <ElectionVoteBy
          electionName={selectedElectionName}
          user={user}
          handleNext={handleNext}
          collegeList={collegeList}
          voteType={voteType}
        />
      );
    case 3:
      return (
        <VoteReceipt
          handleNext={handleNext}
          user={user}
          electionName={selectedElectionName}
          cseVoteKey={cseVoteKey}
        />
      );
    default:
      return "Unknown stepIndex";
  }
}

export default function CseVote({ user }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Choix de l’élection",
    "Consultation des listes",
    "Vote",
    "Accusé de reception",
  ];

  const [cseVoteKey, setCseVoteKey] = useState("");
  const [selectedElectionName, setSelectedElectionName] = useState("");
  const [selectedCollegeList, setSelectedCollegeList] = useState();
  // candidate || list
  const [selectedVoteType, setSelectedVoteType] = useState();

  /*
  // prevent reload / page leave
  window.onbeforeunload = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = "";
    }
    return "";
  };
*/

  const handleNext = (data: any) => {
    tooltipsNumber = 0;
    console.log(data, "data");
    if (data?.electionName) setSelectedElectionName(data.electionName);
    if (data?.collegeList) setSelectedCollegeList(data.collegeList);
    if (data?.voteType) setSelectedVoteType(data.voteType);
    if (data?.cseVoteKey) setCseVoteKey(data.cseVoteKey);
    if (data?.goTo || data?.goTo === 0) {
      setActiveStep(data.goTo);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    tooltipsNumber = 0;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const history = useHistory();

  const handleVoteNotStarted = () => {
    const hasStarted = timeIsBefore(
      user.cseStartDate,
      timeNowUtc().format("LLL")
    );
    if (!hasStarted) {
      history.push("/");
    }
  };

  useEffect(() => {
    handleVoteNotStarted();
    const interval = setInterval(() => {
      handleVoteNotStarted();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const showReturn = !!activeStep && activeStep !== 3;

  return (
    <>
      <LargeContainer className={classes.root}>
        <div>
          {showReturn && (
            <div onClick={handleBack} className={classes.goBack}>
              <img
                style={{
                  objectFit: "contain",
                }}
                src="/img/back.svg"
                alt="retour"
              />
              <span>Retour</span>
            </div>
          )}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ padding: "20px 10px" }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {getStepContent(
          activeStep,
          user,
          handleNext,
          selectedElectionName,
          selectedCollegeList,
          selectedVoteType,
          cseVoteKey
        )}
      </LargeContainer>
    </>
  );
}
