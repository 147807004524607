import React, { Component } from "react";
import "./App.css";
import Login from "./screens/Login";
import Header from "./components/Header";
import ClientNavigator from "./navigation/ClientNavigator";
import VoterNavigator from "./navigation/VoterNavigator";
import StaffAttendance from "./screens/StaffAttendance";
import API from "./utils/API";
import * as Auth from "./utils/Auth";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { customTheme } from "./utils/muiTheme";
import PresidentNavigator from "./navigation/PresidentNavigator";
import ResultsDisplayPrinting from "./components/ResultsDisplayPrinting.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CseVoterNavigator from "./navigation/CseVoterNavigator";
import CseLogin from "./screens/CseLogin";
import AdminNavigator from "./navigation/AdminNavigator";
import CseResultsDisplay from "./screens/Cse/CseResultsDisplay";
import AdminCse from "./screens/AdminCse";

const HeaderComplement = styled.div`
  padding-top: 100px;
  box-sizing: border-box;
  width: 98%;
`;

export const UserContext = React.createContext({
  userType: "Guest",
  strippingKey: "",
});

export const HelperContext = React.createContext({
  enabled: false,
  setEnabled: null,
});

class App extends Component {
  constructor(props) {
    super(props);
    const isStaffAttendance = window.location.pathname === "/emargement";
    this.state = {
      isLogged: !!Auth.getToken(),
      user: null,
      isStaffAttendance,
      helper: false,
    };
  }

  componentDidMount() {
    console.log("STATE : ", this.state);
    if (this.state.isLogged) {
      API.get(
        "/user",
        {},
        (user) => {
          this.setState({
            user,
          });
        },
        () => {
          Auth.removeToken();
          this.setState({ isLogged: false });
        }
      );
    }
  }

  render() {
    return (
      <ThemeProvider theme={customTheme}>
        <Router>
          <Switch>
            <Route
              exact
              path="/votant/:code"
              component={(props) => (
                <div className="App">
                  <Header
                    isLogged={this.state.isLogged}
                    user={this.state.user}
                    onLogout={() => {
                      this.setState({ isLogged: false });
                    }}
                  />
                  <HeaderComplement>
                    <CseLogin
                      onSuccess={(user) =>
                        this.setState({ isLogged: true, user })
                      }
                      user={this.props.user}
                      {...props}
                    />
                  </HeaderComplement>
                </div>
              )}
            />
            <Route
              exact
              path="/admin"
              component={(props) => (
                <div className="App">
                  <Header
                    isLogged={false}
                    user={undefined}
                    onLogout={() => {
                      this.setState({ isLogged: false });
                    }}
                  />
                  <HeaderComplement>
                    <AdminCse />
                  </HeaderComplement>
                </div>
              )}
            />
            <Route
              exact
              path="/resultsDisplay"
              component={() => <ResultsDisplayPrinting />}
            />
            <Route
              exact
              path="/cseResultsDisplay"
              component={() => <CseResultsDisplay />}
            />
            <Route
              path="/"
              component={() => (
                <div className="App">
                  {this.state.isStaffAttendance ? (
                    <StaffAttendance />
                  ) : (
                    <HelperContext.Provider
                      value={{
                        enabled: this.state.helper,
                        setEnabled: () => this.setState({ helper: false }),
                      }}
                    >
                      {this.state.helper && (
                        <div
                          onClick={() => {
                            this.setState({ helper: false });
                          }}
                          style={{
                            zIndex: 100,
                            position: "absolute",
                            width: "100%",
                            minHeight: "100%",
                            backgroundColor: "rgb(0, 0, 0, 0.5)",
                          }}
                        ></div>
                      )}
                      <Header
                        isLogged={this.state.isLogged}
                        user={this.state.user}
                        onLogout={() => {
                          this.setState({ isLogged: false });
                        }}
                        handlerHelp={() => {
                          this.setState({ helper: !this.state.helper });
                        }}
                        helper={this.state.helper}
                      />
                      <UserContext.Provider value={this.state.user}>
                        <HeaderComplement>
                          {!this.state.isLogged ? (
                            <Login
                              onSuccess={(user) =>
                                this.setState({ isLogged: true, user })
                              }
                            />
                          ) : this.state.user &&
                            this.state.user.userType === "voter" ? (
                            <VoterNavigator user={this.state.user} />
                          ) : this.state.user &&
                            (this.state.user.userType === "client" ||
                              this.state.user.userType === "clientAdmin") ? (
                            <ClientNavigator user={this.state.user} />
                          ) : this.state.user &&
                            this.state.user.userType === "president" ? (
                            <PresidentNavigator user={this.state.user} />
                          ) : this.state.user &&
                            this.state.user.userType === "cseVoter" ? (
                            <CseVoterNavigator user={this.state.user} />
                          ) : this.state.user &&
                            this.state.user.userType === "admin" ? (
                            <AdminNavigator user={this.state.user} />
                          ) : null}
                        </HeaderComplement>
                      </UserContext.Provider>
                    </HelperContext.Provider>
                  )}
                </div>
              )}
            />
          </Switch>
        </Router>
        <ToastContainer
          position="top-center"
          autoClose={6000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    );
  }
}

export default App;
