import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../components/buttons/CustomButton";
import config from "../../config";
import {
  MediumContainer,
  SmallContainer,
} from "../../utils/styled-components-library";
import moment from "moment";
import { dateFromNow, formatTimeWithMinutes } from "../../utils/time";
import classes from "./CseVoterLanding.module.scss";
import { HelperContext } from "../../App";
import { Tooltips } from "../Tooltip";

const renderVoteTitle = (hasStarted: boolean, hasEnded: boolean) => {
  if (hasStarted && hasEnded) return "Les votes sont clos";
  if (hasStarted && !hasEnded) return "Fermeture des votes dans";
  if (!hasStarted) return "Ouverture des votes dans";
};

const renderVoteText = (hasStarted: boolean, hasEnded: boolean) =>
  hasStarted ? "Suivant" : "Vote non disponible";

const jsonTimeToString = (time: any) =>
  `${
    time.days
      ? time.days > 1
        ? time.days + " jours, "
        : time.days + " jour, "
      : ""
  }${
    time.hours
      ? time.hours > 1
        ? time.hours + " heures, "
        : time.hours + " heure, "
      : ""
  } ${
    time.minutes
      ? time.minutes > 1
        ? time.minutes + " minutes"
        : time.minutes + " minute"
      : ""
  }`;

const renderVoteTime = (
  hasStarted: boolean,
  hasEnded: boolean,
  timeRemainingUntilEnd: any,
  timeRemainingUntilStart: any
) => {
  if (hasStarted && hasEnded) return "";
  if (hasStarted && !hasEnded) return jsonTimeToString(timeRemainingUntilEnd);
  if (!hasStarted && !hasEnded)
    return jsonTimeToString(timeRemainingUntilStart);
};

const VoteTime = ({
  title = "Ouverture des votes",
  logo = "/img/calendar-clock.svg",
  time = "",
}) => (
  <div className={classes.VoteContainer}>
    <img className={classes.VoteContainer_Logo} src={logo} alt="clock" />
    <div>
      <p className={classes.VoteContainer_Title}>{title}</p>
      <p className={classes.VoteContainer_Time}>{time}</p>
    </div>
  </div>
);

const CseVoterLanding = ({ user }: { user: any }) => {
  const helper = useContext(HelperContext);

  const [timeRemainingUntilEnd, setTimeRemainingUntilEnd] = useState(
    dateFromNow(user.cseEndDate)
  );

  const [timeRemainingUntilStart, setTimeRemainingUntilStart] = useState(
    dateFromNow(user.cseStartDate)
  );

  const [hasStarted, setHasStarted] = useState(
    moment.utc(user.cseStartDate).tz("Europe/Paris").isBefore(moment())
  );

  const [hasEnded, setHasEnded] = useState(
    moment.utc(user.cseEndDate).tz("Europe/Paris").isBefore(moment())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemainingUntilStart(dateFromNow(user.cseStartDate));
      setTimeRemainingUntilEnd(dateFromNow(user.cseEndDate));
      setHasStarted(
        moment.utc(user.cseStartDate).tz("Europe/Paris").isBefore(moment())
      );
      setHasEnded(
        moment.utc(user.cseEndDate).tz("Europe/Paris").isBefore(moment())
      );
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <MediumContainer>
      <img
        className="logo_landing"
        src={config.s3BaseUrl + user.clientLogoPath}
        alt="Entité"
      />
      <p className={classes.CseVoterLanding_Title}>Bienvenue dans le scrutin</p>
      <p className={classes.CseVoterLanding_CollegeName}>
        <strong>{user.cseName}</strong>
      </p>
      <p>{renderVoteTitle(hasStarted, hasEnded)}</p>
      <p className={classes.CseVoterLanding_Time}>
        {renderVoteTime(
          hasStarted,
          hasEnded,
          timeRemainingUntilEnd,
          timeRemainingUntilStart
        )}
      </p>
      <SmallContainer>
        <div style={{ marginBottom: 10 }}>
          <VoteTime time={formatTimeWithMinutes(user.cseStartDate)} />
        </div>
        <VoteTime
          title="Fermeture des votes"
          logo="/img/calendar-check.svg"
          time={formatTimeWithMinutes(user.cseEndDate)}
        />
      </SmallContainer>
      <SmallContainer>
        <CustomButton
          disabled={!hasStarted}
          className={classes.CseVoterLanding_Button}
          href="/vote"
          style={helper.enabled ? { zIndex: 200, position: "relative" } : {}}
        >
          <span> {renderVoteText(hasStarted, hasEnded)} </span>
        </CustomButton>
        {(helper.enabled && (
          <Tooltips tooltipNumber={1}>
            <>
              Cliquez sur le boutton <strong>“Suivant”</strong> pour aller dans
              votre espace
            </>
          </Tooltips>
        )) ||
          null}
      </SmallContainer>
    </MediumContainer>
  );
};

export default CseVoterLanding;
