import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import API from "../utils/API";
import styled from "styled-components";
import * as config from "../config";

const StyledInput = styled.input`
  width: 100%;
  border-radius: 2px;
  border: solid 1px #c0c3cb;
  font-family: inherit;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 8px;
  box-sizing: border-box;
  cursor: pointer;

  background-image: ${(props) =>
    props.isLoading ? "url('/img/loading.gif')" : ""};
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: top 12px right 12px;
`;

const StyledLink = styled.a`
  color: #06101f;
  :hover {
    color: #1d529c;
    text-decoration: underline;
  }
`;

const StyledImage = styled.img`
  cursor: pointer;
`;

const FilenameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;
`;

const ellipse = (value) =>
  value.substring(0, 20) + (value.length < 20 ? "" : "...");

const FileInput = ({ ...props }) => {
  const { setFieldValue, values } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const [field] = useField(props);

  const params = props.tmp ? { tmp: true } : {};
  const getPresignedS3URL = async (fileBody) => {
    console.log("starting upload");
    const response = await API.get("/getPresignedS3Url", params, (data) => {
      return fetch(data.url, {
        method: "PUT",
        body: fileBody,
      });
    });

    return response.url.split("?")[0].replace(config.s3BaseUrl, "");
  };

  return (
    <>
      <StyledInput
        accept={props.accept}
        type="file"
        disabled={props.disabled}
        isLoading={isLoading}
        multiple={props.multiple}
        onChange={async (e) => {
          if (props.customOnChange) {
            props.customOnChange(e);
          }
          setIsLoading(true);
          if (!props.multiple) {
            const fileBody = e.target.files[0];
            const documentFilename = fileBody?.name || null;
            const s3documentPath = await getPresignedS3URL(fileBody);
            if (props.filename?.key) {
              setFieldValue(props.filename?.key, documentFilename);
            }

            setFieldValue(field.name, s3documentPath);
            if (props.onChange) {
              props.onChange({
                documentFilename,
                document: s3documentPath,
              });
            }
          } else {
            const s3documentPaths = [];
            const documentFilenames = [];

            for (const file of e.target.files) {
              s3documentPaths.push(await getPresignedS3URL(file));
              documentFilenames.push(file?.name || null);
            }

            if (props.filename?.key) {
              setFieldValue(props.filename?.key, documentFilenames);
            }

            setFieldValue(field.name, s3documentPaths);
            if (props.onChange) {
              props.onChange(s3documentPaths);
            }
          }

          setIsLoading(false);
          if (props.onLoadingComplete) {
            props.onLoadingComplete();
          }
        }}
      />
      {props.filename?.value && (
        <FilenameContainer>
          <span>
            Fichier :{" "}
            <StyledLink
              target="_blank"
              href={config.s3BaseUrl + values[field.name]}
              rel="noreferrer"
              download
            >
              {props.filename?.isShort
                ? ellipse(props.filename.value)
                : props.filename.value}
            </StyledLink>
          </span>
          <StyledImage
            onClick={() => {
              setFieldValue(field.name, null);
              if (props.filename?.key) {
                setFieldValue(props.filename?.key, null);
              }
              if (props.onDelete) {
                props.onDelete();
              }
            }}
            src="/img/remove.svg"
            alt="Cliquez pour supprimer le fichier"
          />
        </FilenameContainer>
      )}
    </>
  );
};

export default FileInput;
