import React, { useEffect, useState } from "react";
import Accordion from "../../components/accordions/Accordion";
import AccordionTitle from "../../components/accordions/AccordionTitle";
import { CseCollegeType_Db } from "../../types/cseCollege";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledLink } from "../../utils/styled-components-library";
import CustomButton from "../../components/buttons/CustomButton";
import API from "../../utils/API";

const CseCollegeAccordion = ({
  college,
  hideModify = false,
}: {
  college: CseCollegeType_Db;
  hideModify: boolean;
}) => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {}, [isSubmitting]);

  return (
    <Accordion
      alwaysClosed
      initiallyOpen
      color="blue"
      key={college.cseCollegeId}
      title={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <AccordionTitle
            type="blue"
            left={<>{college.collegeName}</>}
            separator="–"
            right={
              <>
                {college.collegeNumberOfTitulars +
                  college.collegeNumberOfSubstitutes +
                  " Sièges"}
              </>
            }
          />
          {(!hideModify && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <StyledLink
                underline
                style={{
                  cursor: "pointer",
                  color: "#1D529C",
                  display: "contents",
                }}
                onClick={() => {
                  history.push(`colleges/modification/${college.cseCollegeId}`);
                }}
              >
                Modifier
                <img
                  style={{
                    marginLeft: 5,
                    objectFit: "contain",
                  }}
                  src={"/img/edit.svg"}
                  alt="Modifier le collège"
                />
              </StyledLink>
              <CustomButton
                style={{ backgroundColor: "red", fontWeight: 800 }}
                disabled={isSubmitting}
                onClick={() => {
                  setIsSubmitting(true);
                  API.post(
                    "/cseRouteCRUD",
                    {
                      cseCollegeId: college.cseCollegeId,
                      path: "/deleteCseCollege",
                      module: "college",
                    },
                    (data: { success: boolean; data: any }) => {
                      setIsSubmitting(false);
                      if (data.success) {
                        toast.success(
                          `Le college a été supprimé avec succès !`
                        );
                      }
                    },
                    () => {
                      setIsSubmitting(false);
                      toast.error(`Le college n'a pas été supprimé !`);
                    }
                  );
                }}
              >
                Supprimer
              </CustomButton>
            </div>
          )) || (
            <div style={{ display: "flex", alignItems: "center" }}>
              <StyledLink
                underline
                style={{
                  cursor: "pointer",
                  color: "#1D529C",
                  display: "contents",
                }}
                onClick={() => {
                  history.push(`colleges/modification/${college.cseCollegeId}`);
                }}
              >
                Voir
                <img
                  style={{
                    marginLeft: 5,
                    objectFit: "contain",
                    maxWidth: "30px",
                  }}
                  src={"/img/focus-eye.svg"}
                  alt="Modifier le collège"
                />
              </StyledLink>
            </div>
          )}
        </div>
      }
      children={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 5,
            marginTop: 8,
          }}
        >
          <Accordion
            alwaysClosed
            color="grey"
            key={college.cseCollegeId + "_titulars"}
            title={
              <AccordionTitle
                type="grey"
                left={<>Titulaires</>}
                separator="–"
                right={
                  <>
                    {Number(college.collegeNumberOfTitulars) <= 1
                      ? college.collegeNumberOfTitulars + " Siège"
                      : college.collegeNumberOfTitulars + " Sièges"}
                  </>
                }
              />
            }
          />
          <Accordion
            alwaysClosed
            color="grey"
            key={college.cseCollegeId + "_substitutes"}
            title={
              <AccordionTitle
                type="grey"
                left={<>Suppléants</>}
                separator="–"
                right={
                  <>
                    {Number(college.collegeNumberOfSubstitutes) <= 1
                      ? college.collegeNumberOfSubstitutes + " Siège"
                      : college.collegeNumberOfSubstitutes + " Sièges"}
                  </>
                }
              />
            }
          />
        </div>
      }
    />
  );
};

export default CseCollegeAccordion;
