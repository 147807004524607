import styled from "styled-components";
import "../components/Vote.css";
import React from "react";
import API from "../utils/API";
import SignatureContainer from "../components/SignatureContainer";
import Chart from "../components/Chart";
import VoteTile from "../components/VoteTile";
import AGBeforeStartMessage from "../components/AGBeforeStartMessage";
import moment from "moment";
import {
  Button,
  ChartContainer,
  HorizontalSeparator,
  Modal,
  ModalClose,
  ModalImage,
  ModalOverlay,
  Semibold,
  LargeClientLogo,
  WinnerText,
  DrawText,
  NoNewLineSpan,
} from "../utils/styled-components-library";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { sortVotesByPosition } from "../utils/votes";
import InfoIcon from "@material-ui/icons/Info";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import "moment/locale/fr";
import { Paper } from "@material-ui/core";
import * as config from "../config";
import DoneAllIcon from "@material-ui/icons/DoneAll";

const Container = styled.div`
  max-width: 900px;
  margin: auto;
  position: relative;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px 0px;
  align-items: flex-start;
`;

const DelegateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;
`;

const VoteStatValue = styled.div`
  font-size: 21px;
  font-weight: 500;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const VoteStatLabel = styled.div`
  @media (max-width: 500px) {
    font-size: 11px;
  }
`;

const VoteStatSubLabel = styled.div`
  background-color: #2c3e50;
  color: white;
  padding: 8px;
  border-radius: 2px;
  margin-top: 8px;
`;

const VoteName = styled.div`
  padding: 20px;
  font-size: 34px;
  font-weight: 500;
  color: #203147;
  padding-bottom: 0;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const VoteType = styled.div`
  font-size: 15px;
  color: #858585;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const AgNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 22px;
  background-color: #ecf0f1;
  margin-top: -36px;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
`;

const AGInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Message = styled.div`
  font-size: 22px;
  color: black;
  margin-top: 50px;
`;

const TitleAttendanceRequired = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
`;

const ConfirmationLabel = styled.label`
  color: ${(props) => (props.disabled ? "#CCC" : "black")};
  text-align: left;
`;

const AttendanceErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 18px;
  font-weight: 400;
`;

const VoteDescription = styled.p`
  white-space: pre-line;
  padding: 0 10px;
  padding-bottom: 20px;
  text-align: left;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const VoteMajorityCount = styled.div`
  white-space: pre-line;
  padding: 0 10px;
  padding-bottom: 10px;
  color: #203147;
  font-size: 14px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const LoadingMessageContainer = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const LoadingScreenAGTitle = styled.p`
  font-size: 25px;
  font-weight: 600;
`;

const ExcludedVoterMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 20px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const ModalIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const VoteStat = ({ label, value, subLabel }) => (
  <Paper className="VoteStatContainer">
    <VoteStatValue>{value}</VoteStatValue>
    <VoteStatLabel>{label}</VoteStatLabel>
    {subLabel ? (
      <VoteStatSubLabel>
        <div style={{ fontWeight: "500", fontSize: "20px" }}>Quorum</div>
        {subLabel}
      </VoteStatSubLabel>
    ) : null}
  </Paper>
);
class Vote extends React.Component {
  constructor(props) {
    super(props);
    const loadingUserStatus = this.props.user.userType !== "client";
    this.state = {
      voteId: props.match.params.voteId,
      vote: null,
      ag: null,
      isVoteOpen: false,
      previousVote: null,
      message: "",
      canVote: false,
      isVoting: false,
      actualVoteCount: "-",
      actualVotePowerCount: "-",
      voteResults: null,
      selectedOptionsByVoterId: {},
      attendanceErrorMsg: "",
      voterStatus: "",
      showDelegateModal: false,
      blockAllActions: true,
      loadingUserStatus,
      pendingValidationVotes: [],
    };
    this.chartColors = {
      voteFor: "#009688",
      voteAgainst: "#E91E63",
      voteAbstain: "#FF9800",
      voteExtraOption: "#42a5f5",
      null: "#1D529C",
    };
    this.sigPad = React.createRef();
  }

  componentDidMount() {
    if (window.location.hash === "#success") {
      this.setState({ showSuccessModal: true });
    }
    this.refreshVoteParams();

    this.interval = setInterval(() => {
      this.refreshVoteParams();

      if (this.state.voteId) {
        API.get(
          "/voteRealTimeStats",
          {
            voteId: this.state.voteId,
          },
          (data) => {
            this.setState({
              actualVoteCount: data.actualVoteCount,
              actualVotePowerCount: data.actualVotePowerCount,
              voteResults: data.voteResults,
              isQuorumReached: data.isQuorumReached,
              voterDisplayCount: data.voterDisplayCount,
            });
          }
        );
      }
    }, 3000);
  }

  getVoteAsPotentiallyFrozen(vote, voterId) {
    return {
      ...vote,
      isFrozen: vote.isFrozen || !this.canValidateVote(voterId),
    };
  }

  canValidateVote(voterId) {
    const hasValidVotes = this.state.vote.validatedVotes?.some(
      (validatedVote) =>
        validatedVote.voterId === voterId &&
        this.state.vote.voteId === validatedVote.voteId
    );
    return !hasValidVotes || this.state.ag.allowVoteEditionAfterValidation;
  }

  canValidateAllVotes(voterId) {
    let hasAllValidVotes = true;
    this.state.vote.delegatedVoters?.forEach((voter) => {
      const delegateHaveVoted = this.state.vote.validatedVotes?.some(
        (validatedVote) => validatedVote.voterId === voter.voterId
      );
      if (!delegateHaveVoted) {
        hasAllValidVotes = false;
      }
    });
    const mainVoterHasVoted = this.state.vote.validatedVotes?.some(
      (validatedVote) => validatedVote.voterId === voterId
    );
    hasAllValidVotes = hasAllValidVotes && mainVoterHasVoted;
    return !hasAllValidVotes || this.state.ag.allowVoteEditionAfterValidation;
  }

  refreshVoteParams() {
    let currentVoteId = this.state?.vote?.voteId;
    API.get(
      "/voteParams",
      {
        voteId: this.state.voteId,
      },
      (data) => {
        const sortedVotes = data.votes ? sortVotesByPosition(data.votes) : null;
        let vote =
          data.ag.isAsynchronous === 1 && sortedVotes
            ? sortedVotes[this.state.selectedVoteIndex || 0]
            : data.vote;
        if (
          vote &&
          this.props.user.userType !== "client" &&
          this.props.user.userType !== "president"
        ) {
          let selectedOptionsByVoterId = {
            [this.props.user.voterId]: vote.selectedOptions.map(
              (x) => x.optionId
            ),
          };
          if (vote.delegatedVoters) {
            for (let voter of vote.delegatedVoters) {
              selectedOptionsByVoterId[
                voter.voterId
              ] = voter.selectedOptions.map((x) => x.optionId);
            }
          }
          this.setState({
            selectedOptionsByVoterId,
          });
        }

        if (
          data.previousVote?.voteType === "oneRoundElection" ||
          data.previousVote?.voteType === "twoRoundsElection" ||
          data.previousVote?.voteType === "plurinominalElection"
        ) {
          if (data.previousVote.voteResults) {
            data.previousVote.originalOptionOrder = data.previousVote.voteResults.map(
              (x) => x.optionId
            );

            data.previousVote.voteResults.sort((a, b) => {
              return (b.count || 0) - (a.count || 0);
            });
          }
        }
        if (data.ag.isAsynchronous === 1 && data.votes) {
          const selectedVoteIndex = this.state.selectedVoteIndex || 0;

          this.setState({
            ag: data.ag,
            votes: sortedVotes,
            selectedVoteIndex: this.state.selectedVoteIndex || 0,
            vote: sortedVotes ? sortedVotes[selectedVoteIndex] : null,
            voteId:
              sortedVotes && sortedVotes[selectedVoteIndex]
                ? sortedVotes[selectedVoteIndex].voteId
                : null,
            isVoteOpen: data.isVoteOpen,
            message: data.message,
            previousVote: data.previousVote,
            blockAllActions: data.blockAllActions,
            canVote: true,
          });
        } else {
          this.setState({
            ag: data.ag,
            vote: data.vote,
            voteId: data.vote ? data.vote.voteId : null,
            isVoteOpen: data.isVoteOpen,
            message: data.message,
            previousVote: data.previousVote,
            blockAllActions: data.blockAllActions,
            canVote: true,
          });

          if (data.vote && currentVoteId !== data.vote.voteId) {
            this.setState({ loadingUserStatus: true, voterStatus: "" });
            this.getVoterAttendanceStatus();
          }
        }
        if (
          this.state.voterStatus === "" &&
          this.props.user.userType !== "client"
        ) {
          this.getVoterAttendanceStatus();
          this.fetchAgDocuments();
        }
      }
    );
  }

  getVoterAttendanceStatus() {
    if (this.state.voteId) {
      API.get(
        "/voterAttendance",
        { voterId: this.props.user.voterId, voteId: this.state.voteId },
        (data) => {
          this.setState({ loadingUserStatus: false });
          if (data.status) {
            this.setState({ voterStatus: data.status });
          }
        }
      );
    } else {
      this.setState({ loadingUserStatus: false });
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  vote(voterId, optionId) {
    let option = this.state.vote.options.find((x) => x.optionId == optionId);
    let shouldResetOtherVotes =
      this.state.vote.voteType != "plurinominalElection" ||
      option.optionType == "voteAbstain";
    const votingParams = {
      optionId,
      voterId,
      voteId: this.state.voteId,
      uniqueKey:
        this.state.vote.voteType == "plurinominalElection" ? optionId : -1,
    };
    this.setState({
      canVote: false,
      isVoting: true,
      pendingValidationVotes: [
        ...this.state.pendingValidationVotes,
        votingParams,
      ],
    });

    API.post(
      "/vote",
      {
        optionId,
        voterId,
        voteId: this.state.voteId,
        uniqueKey:
          this.state.vote.voteType == "plurinominalElection" ? optionId : -1,
      },
      (data) => {
        if (data.success) {
          this.setState({
            selectedOptionsByVoterId: {
              ...this.state.selectedOptionsByVoterId,
              [voterId]: shouldResetOtherVotes
                ? [optionId]
                : this.state.selectedOptionsByVoterId[voterId]?.includes(
                    optionId
                  )
                ? this.state.selectedOptionsByVoterId[voterId].filter(
                    (x) => x != optionId
                  )
                : [...this.state.selectedOptionsByVoterId[voterId], optionId],
            },
            canVote: true,
            isVoting: false,
          });
        } else if (data.message) {
          this.setState({
            showErrorMessageModal: true,
            errorMessage: data.message,
            isVoting: false,
          });
        }
      }
    );
  }

  confirmVoterAttendance() {
    let signature = null;
    if (this.state.ag.signatureNeeded) {
      if (!this.sigPad.isEmpty()) {
        signature = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
      } else {
        this.setState({
          attendanceErrorMsg: "Aucune signature tracée.",
        });
      }
    }
    if (
      (this.state.ag.signatureNeeded && !this.sigPad.isEmpty()) ||
      !this.state.ag.signatureNeeded
    ) {
      API.post(
        "/changeVoterAttendance",
        {
          voterId: this.props.user.voterId,
          voteId: this.state.vote.voteId,
          status: "present",
          signature,
        },
        (data) => {
          if (data.success) {
            this.setState({ voterStatus: "present" });
          } else {
            this.setState({
              attendanceErrorMsg:
                "Impossible de mettre à jour votre présence pour le moment.",
            });
          }
        }
      );
    }
  }

  fetchAgDocuments() {
    API.get(
      "/agDocuments",
      { agId: this.state.ag.agId, action: "get" },
      (data) => {
        if (data.success) {
          this.setState({ documents: data.documents });
        }
      }
    );
  }

  previousVote() {
    const selectedVoteIndex = this.state.selectedVoteIndex - 1;
    this.setState(
      {
        selectedVoteIndex,
        vote: this.state.votes[selectedVoteIndex],
        voteId: this.state.votes[selectedVoteIndex].voteId,
        selectedOptionsByVoterId: {},
        loadingUserStatus: true,
        showSoonOpen: false,
        voterStatus: "",
      },
      () => this.getVoterAttendanceStatus()
    );
  }

  nextVote() {
    const selectedVoteIndex = this.state.selectedVoteIndex + 1;
    this.setState(
      {
        selectedVoteIndex,
        vote: this.state.votes[selectedVoteIndex],
        voteId: this.state.votes[selectedVoteIndex].voteId,
        selectedOptionsByVoterId: {},
        loadingUserStatus: true,
        showSoonOpen: false,
        voterStatus: "",
      },
      () => this.getVoterAttendanceStatus()
    );
  }

  shouldShowSignaturePopUp() {
    let output =
      this.props.user.userType !== "client" &&
      this.props.user.userType !== "president" &&
      this.state.voterStatus !== "present" &&
      this.state.voteId &&
      !this.state.blockAllActions;
    return output;
  }

  getCurrentTotalNumberOfVotesExpected() {
    return this.state.vote.useVotePowers === 1
      ? this.state.vote.expectedVotePowerCount
      : this.state.vote.expectedVoteCount;
  }

  getCurrentNumberOfVotes() {
    return this.state.vote.useVotePowers === 1
      ? this.state.actualVotePowerCount
      : this.state.actualVoteCount;
  }

  getCurrentNumberOfVotesStillExpected() {
    if (
      this.getCurrentNumberOfVotes() !== "-" &&
      this.getCurrentTotalNumberOfVotesExpected() !== "-"
    ) {
      return (
        this.getCurrentTotalNumberOfVotesExpected() -
        this.getCurrentNumberOfVotes()
      );
    } else return "-";
  }

  findWinner(voteResults) {
    let max = 0;
    let winner = null;
    voteResults
      .filter((x) => x.optionType !== "voteAbstain")
      .forEach((voteResult) => {
        if (voteResult.count >= max) {
          max = voteResult.count;
          winner = voteResult;
        }
      });
    return [winner];
  }

  /**
   * Détermine si les résultats d'une élection à deux tours doivent être affichés
   * @param {*} vote
   */
  showTwoRoundsElectionsResults = (vote) => {
    // Deuxième tour de l'élection : on affiche les résultats
    if (vote.electionRound === 2) {
      return true;
    }

    // Moins de 25% des électeurs ont voté : on n'affiche pas les résultats
    if (vote.castedVotes < vote.expectedVotes / 4) {
      return false;
    }

    // Un candidat a au moins 50% des voix
    let totalNumberOfVotes = 0;
    vote.voteResults.forEach((result) => totalNumberOfVotes + result.count);

    return vote.voteResults.some(
      (result) => result.count > totalNumberOfVotes / 2
    );
  };

  isTie(voteResults) {
    let max = 0;
    let tie = false;
    voteResults
      .filter((x) => x.optionType !== "voteAbstain")
      .forEach((voteResult) => {
        if (voteResult.count > max) {
          max = voteResult.count;
          tie = false;
        } else if (voteResult.count === max) {
          tie = true;
        }
      });
    return tie;
  }

  computeVoteResultPercentage(voteResults, option) {
    // Total de voix
    let total = voteResults.reduce(function (acc, currentResult) {
      if (currentResult.count) {
        return acc + parseFloat(currentResult.count);
      } else return acc;
    }, 0);
    // Pourcentage
    return parseFloat((option.count / total) * 100).toFixed(2);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <>
        {this.state.ag?.isClosed ? (
          <div>
            <LargeClientLogo
              src={config.s3BaseUrl + this.props.user.clientLogoPath}
              alt="Client logo"
            />
            <LoadingMessageContainer>
              <p>Bonjour, vous êtes bien sur ProVote.</p>
              <p>
                Votre session de vote{" "}
                {this.state.ag !== null && this.state.ag.agName} est clôturée.
              </p>
            </LoadingMessageContainer>
          </div>
        ) : this.state.ag &&
          !this.state.ag?.isAsynchronous &&
          !this.state.ag?.isClosed &&
          !this.state.isVoteOpen &&
          !this.state.previousVote ? (
          <div>
            <LargeClientLogo
              src={config.s3BaseUrl + this.props.user.clientLogoPath}
              alt="Client logo"
            />

            <LoadingScreenAGTitle>{this.state.ag?.agName}</LoadingScreenAGTitle>
            <AGBeforeStartMessage ag={this.state.ag} />
          </div>
        ) : this.state.loadingUserStatus && !this.state.ag?.isClosed ? (
          <div>
            <LargeClientLogo
              src={config.s3BaseUrl + this.props.user.clientLogoPath}
              alt="Client logo"
            />

            <LoadingScreenAGTitle>{this.state.ag?.agName}</LoadingScreenAGTitle>

            <Loader type="Bars" color="#1D529C" height={70} width={70} />
            {this.state.ag &&
            !this.state.isVoteOpen &&
            !this.state.previousVote ? (
              <AGBeforeStartMessage ag={this.state.ag} />
            ) : null}
          </div>
        ) : (
          <>
            {this.shouldShowSignaturePopUp() ? (
              <ModalOverlay>
                <Modal
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  maxWidth="800"
                >
                  <TitleAttendanceRequired>
                    {this.state.ag && this.state.ag.signatureNeeded
                      ? "Pour accéder au vote, merci de confirmer votre présence en signant (avec le doigt si écran tactile, avec la souris si ordinateur)"
                      : "Avant de voter, merci de confirmer votre présence."}
                  </TitleAttendanceRequired>
                  {this.state.ag && this.state.ag.signatureNeeded ? (
                    <SignatureContainer
                      ref={(ref) => {
                        this.sigPad = ref;
                      }}
                    />
                  ) : (
                    <CheckboxContainer>
                      <CheckboxInput
                        id="checkboxAttendance"
                        type="checkbox"
                        ref={(ref) => {
                          this.checkboxConfirm = ref;
                        }}
                      />
                      <ConfirmationLabel htmlFor="checkboxAttendance">
                        Pour accéder au vote, merci de confirmer votre présence
                        en cochant cette case.
                      </ConfirmationLabel>
                    </CheckboxContainer>
                  )}
                  <AttendanceErrorMessage>
                    {this.state.attendanceErrorMsg}
                  </AttendanceErrorMessage>
                  <Button
                    style={{ marginTop: "20px" }}
                    disabled={
                      this.state.ag &&
                      !this.state.ag.signatureNeeded &&
                      (!this.checkboxConfirm || !this.checkboxConfirm.checked)
                    }
                    onClick={() => this.confirmVoterAttendance()}
                  >
                    Valider
                  </Button>
                </Modal>
              </ModalOverlay>
            ) : null}

            {this.state.ag && !this.state.blockAllActions && (
              <>
                <AgNameContainer>
                  <AGInfoContainer>
                    <span style={{ color: "#363636" }}>
                      {this.state.ag.agName}
                    </span>
                    <span style={{ color: "#858585", fontSize: "13px" }}>
                      {moment(this.state.ag.agStartDate).format("DD/MM/YYYY")}
                    </span>
                  </AGInfoContainer>
                </AgNameContainer>
              </>
            )}

            <Container>
              {this.state.isVoteOpen ? (
                <>
                  {this.state.ag &&
                    this.state.ag.isAsynchronous === 1 &&
                    this.state.votes && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          style={{
                            width: "200px",
                            maxWidth: "25%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                          onClick={() => this.previousVote()}
                          disabled={this.state.selectedVoteIndex === 0}
                        >
                          <NavigateBeforeIcon />
                          <span>Précédent</span>
                        </Button>

                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "18px",
                            color: "#212121",
                          }}
                        >
                          Vote {this.state.selectedVoteIndex + 1}/
                          {this.state.votes.length}
                        </div>
                        <Button
                          style={{
                            width: "200px",
                            maxWidth: "25%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                          onClick={() => this.nextVote()}
                          disabled={
                            this.state.selectedVoteIndex ===
                            this.state.votes.length - 1
                          }
                        >
                          <span>Suivant</span>
                          <NavigateNextIcon />
                        </Button>
                      </div>
                    )}

                  <VoteName>{this.state.vote.voteName}</VoteName>

                  <VoteType>
                    {this.state.vote.isNominative
                      ? "Vote nominatif"
                      : "Vote anonyme"}
                  </VoteType>

                  <VoteDescription>
                    {this.state.vote.voteDescription}
                  </VoteDescription>

                  {this.props.user.userType === "voter" &&
                    (this.state.vote.excludedVoterId ||
                      this.state.vote.isFrozen ||
                      this.props.user.voterPowerCount === 0) && (
                      <ExcludedVoterMessage>
                        <div>
                          <InfoIcon
                            style={{
                              marginRight: 10,
                              color: "#FF892D",
                              fontSize: "2rem",
                            }}
                          />
                        </div>
                        {(this.state.vote.excludedVoterId ||
                          this.props.user.voterPowerCount === 0) && (
                          <div style={{ color: "#212121", fontWeight: 500 }}>
                            Vous n’êtes pas invité(e) à voter sur ce vote, cette
                            interface vous permet de suivre le vote uniquement
                          </div>
                        )}

                        {!!this.state.vote.isFrozen && (
                          <div style={{ color: "#212121", fontWeight: 500 }}>
                            Ce vote est actuellement figé. Vous ne pouvez pas
                            voter ni modifier votre vote.
                          </div>
                        )}
                      </ExcludedVoterMessage>
                    )}

                  {(this.props.user.userType === "client" ||
                    this.props.user.userType === "president") &&
                  this.state.vote.isFrozen ? (
                    <ExcludedVoterMessage>
                      <div>
                        <InfoIcon
                          style={{
                            marginRight: 10,
                            color: "#FF892D",
                            fontSize: "2rem",
                          }}
                        />
                      </div>

                      {this.state.vote.isFrozen && (
                        <div style={{ color: "#212121", fontWeight: 500 }}>
                          Ce vote est actuellement figé.
                        </div>
                      )}
                    </ExcludedVoterMessage>
                  ) : null}

                  {this.state.vote.voteType === "resolutionVote" &&
                  this.state.vote.showRequiredMajority ? (
                    <VoteMajorityCount>
                      <span style={{ fontWeight: 600 }}>
                        Majorité (
                        {this.state.vote.majoriteCalculResultat === "simple"
                          ? "simple"
                          : "renforcée"}
                        ) requise :
                      </span>{" "}
                      {this.state.vote.requiredMajority} suffrages
                    </VoteMajorityCount>
                  ) : null}

                  {this.props.user.userType === "voter" ? (
                    <>
                      <HorizontalContainer>
                        <VoteTile
                          vote={this.getVoteAsPotentiallyFrozen(
                            this.state.vote,
                            this.props.user.voterId
                          )}
                          selectedOptions={
                            this.state.selectedOptionsByVoterId[
                              this.props.user.voterId
                            ]
                          }
                          disabled={
                            this.props.user.voterPowerCount == 0 ||
                            !this.canValidateVote(this.props.user.voterId)
                          }
                          canVote={this.state.canVote}
                          isVoting={this.state.isVoting}
                          onVote={(optionId) =>
                            this.vote(this.props.user.voterId, optionId)
                          }
                          voterId={this.props.user.voterId}
                        />
                      </HorizontalContainer>

                      {this.state.vote.delegatedVoters &&
                      this.state.vote.delegatedVoters.length > 0 ? (
                        this.state.vote.useVoteDelegations ? (
                          <>
                            <HorizontalSeparator
                              style={{ marginTop: "15px" }}
                            />
                            <div
                              style={{
                                fontWeight: 500,
                                fontSize: 20,
                                paddingTop: 40,
                                paddingBottom: 20,
                                color: "#212121",
                              }}
                            >
                              Vos pouvoirs
                            </div>

                            {this.state.vote.delegatedVoters.map((voter) => {
                              const delegatedVoterName = `${voter.voterFirstname} ${voter.voterLastname}`;
                              const delegatedVoterPower = voter.voterPowerCount;

                              return (
                                <DelegateRow key={voter.voterId}>
                                  <div style={{ flex: 1 }}>
                                    <span
                                      style={{
                                        color: "#212121",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {delegatedVoterName}
                                    </span>
                                    <p style={{ color: "#212121" }}>
                                      <small>
                                        Poids du vote : {delegatedVoterPower}
                                      </small>
                                    </p>
                                  </div>

                                  <VoteTile
                                    vote={this.getVoteAsPotentiallyFrozen(
                                      this.state.vote,
                                      voter.voterId
                                    )}
                                    selectedOptions={
                                      this.state.selectedOptionsByVoterId[
                                        voter.voterId
                                      ]
                                    }
                                    disabled={
                                      !this.canValidateVote(voter.voterId)
                                    }
                                    isFrozen={
                                      !this.canValidateVote(voter.voterId)
                                    }
                                    canVote={true}
                                    isVoting={this.state.isVoting}
                                    onVote={(optionId) =>
                                      this.vote(voter.voterId, optionId)
                                    }
                                    voterId={voter.voterId}
                                    isDelegate
                                  />
                                  {this.state.vote.voteType ===
                                    "resolutionVote" && (
                                    <div style={{ flex: 1 }} />
                                  )}
                                </DelegateRow>
                              );
                            })}
                          </>
                        ) : (
                          <ExcludedVoterMessage>
                            <div>
                              <InfoIcon
                                style={{
                                  marginRight: 10,
                                  color: "#FF892D",
                                  fontSize: "2rem",
                                }}
                              />
                            </div>
                            <div style={{ color: "#212121", fontWeight: 500 }}>
                              Le vote par délégation est désactivé pour ce vote
                            </div>
                          </ExcludedVoterMessage>
                        )
                      ) : null}
                    </>
                  ) : null}
                  {(this.props.user.userType === "client" ||
                    this.props.user.userType === "president") && (
                    <HorizontalContainer>
                      <VoteTile
                        vote={this.state.vote}
                        selectedOptions={
                          this.state.selectedOptionsByVoterId[
                            this.props.user.voterId
                          ]
                        }
                        disabled={true}
                        onVote={(optionId) =>
                          this.vote(this.props.user.voterId, optionId)
                        }
                      />
                    </HorizontalContainer>
                  )}
                  <HorizontalContainer style={{ marginTop: 20 }}>
                    {this.props.user.userType === "client" ||
                    this.props.user.userType === "president" ||
                    this.state.vote.showExpectedVoteCount ? (
                      <VoteStat
                        label="Suffrages attendus"
                        value={this.getCurrentTotalNumberOfVotesExpected()}
                      />
                    ) : null}

                    {this.state.vote.showQuorum &&
                    this.state.ag.hasSignatureList == 1 ? (
                      <VoteStat
                        label="Votants présents"
                        subLabel={`${this.state.vote.quorum}${
                          this.state.vote.quorumUnit === "% des votants"
                            ? " %"
                            : ""
                        } (${this.state.isQuorumReached ? "" : "non "}atteint)`}
                        value={this.state.voterDisplayCount}
                      />
                    ) : null}

                    {this.props.user.userType === "client" ||
                    this.props.user.userType === "president" ||
                    this.state.vote.showActualVoteCount ? (
                      <VoteStat
                        label="Suffrages exprimés"
                        value={this.getCurrentNumberOfVotes()}
                      />
                    ) : null}

                    {this.props.user.userType === "client" ||
                    this.props.user.userType === "president" ||
                    this.state.vote.showNoVoteCount ? (
                      <VoteStat
                        label="Non votés"
                        value={this.getCurrentNumberOfVotesStillExpected()}
                      />
                    ) : null}
                  </HorizontalContainer>

                  {this.props.user.userType === "voter" &&
                  this.state.ag &&
                  this.state.ag.isAsynchronous &&
                  this.props.user.voterPowerCount !== 0 ? (
                    <>
                      {!this.state.ag.allowVoteEditionAfterValidation &&
                        !!this.canValidateAllVotes(this.props.user.voterId) && (
                          <p style={{ marginBottom: 0 }}>
                            {!this.state.vote?.delegatedVoters?.length
                              ? "Attention, une fois validé, votre vote ne sera plus modifiable"
                              : "Attention, une fois validé, votre choix n'est pas modifiable"}
                          </p>
                        )}
                      <Button
                        disabled={
                          !this.canValidateAllVotes(this.props.user.voterId)
                        }
                        onClick={() => {
                          API.post(
                            "/validateVote",
                            {
                              pendingValidationVotes: this.state
                                .pendingValidationVotes,
                            },
                            () => {
                              this.refreshVoteParams();
                            }
                          );
                          if (
                            this.state.selectedVoteIndex ===
                            this.state.votes.length - 1
                          ) {
                            this.setState({
                              showAgEndModal: true,
                            });
                          } else {
                            this.nextVote();
                          }
                        }}
                        style={{
                          width: 200,
                          marginTop: 30,
                          marginBottom: 50,
                          paddingTop: 15,
                          paddingBottom: 15,
                        }}
                      >
                        <span style={{ fontWeight: 500 }}> Valider</span>
                      </Button>
                    </>
                  ) : null}
                  {this.props.user.userType === "client" &&
                  this.state.ag &&
                  !this.state.ag.isAsynchronous ? (
                    <>
                      {!this.state.vote.voteStartDatetime ? (
                        <Button
                          onClick={() => {
                            this.setState({ isStartingVote: true });
                            API.post(
                              "/startVote",
                              {
                                voteId: this.state.voteId,
                              },
                              (data) => {
                                this.setState({ isStartingVote: false });
                              }
                            );
                          }}
                          disabled={this.state.isStartingVote}
                          style={{ width: 200, marginTop: 30, marginRight: 10 }}
                        >
                          Démarrer le vote
                        </Button>
                      ) : null}
                      {this.state.vote.voteStartDatetime ? (
                        <Button
                          onClick={() => {
                            this.setState({ isResetingVote: true });
                            API.post(
                              "/resetVote",
                              {
                                voteId: this.state.voteId,
                              },
                              (data) => {
                                this.setState({ isResetingVote: false });
                              }
                            );
                          }}
                          disabled={this.state.isResetingVote}
                          style={{ width: 200, marginTop: 30, marginRight: 10 }}
                        >
                          Réinitialiser le vote
                        </Button>
                      ) : null}
                      {this.state.vote.voteStartDatetime ? (
                        <Button
                          onClick={() => {
                            this.setState({ isStoppingVote: true });
                            API.post(
                              "/stopVote",
                              {
                                voteId: this.state.voteId,
                              },
                              (data) => {
                                if (data.success) {
                                  this.setState({
                                    redirect: `/ag/${this.state.ag.agId}`,
                                  });
                                } else {
                                  console.log(data);
                                }
                              }
                            );
                          }}
                          disabled={this.state.isStoppingVote}
                          style={{ width: 200, marginTop: 30 }}
                        >
                          Terminer le vote
                        </Button>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : this.state.previousVote &&
                this.state.previousVote.voteResults ? (
                <>
                  <VoteName>
                    {this.state.previousVote.voteName} - Résultats
                  </VoteName>
                  <p>
                    Nombre de suffrages exprimés :{" "}
                    {this.state.previousVote.castedVotes} <br />
                    Nombre de non-votés :{" "}
                    {this.state.previousVote.expectedVotes -
                      this.state.previousVote.castedVotes}
                  </p>
                  <ChartContainer>
                    {this.state.previousVote.voteType === "resolutionVote" &&
                      this.state.previousVote.voteResults.reduce(
                        (acc, cur) => acc + (cur.count || 0),
                        0
                      ) > 0 &&
                      this.state.previousVote.showCalculResultat == 1 && (
                        <div style={{ fontWeight: "500" }}>
                          {this.state.previousVote.resolutionWord} :{" "}
                          {this.state.previousVote.isResolutionAccepted ? (
                            <WinnerText>Acceptation</WinnerText>
                          ) : (
                            <span style={{ color: "red" }}>Rejet</span>
                          )}
                          .
                        </div>
                      )}
                    {(this.state.previousVote.voteType === "oneRoundElection" ||
                      this.state.previousVote.voteType ===
                        "twoRoundsElection") && (
                      <div style={{ fontWeight: "500" }}>
                        {this.showTwoRoundsElectionsResults(
                          this.state.previousVote
                        ) ? (
                          this.isTie(this.state.previousVote.voteResults) ? (
                            <>
                              <DrawText>
                                Aucun candidat n'est élu en raison d'une égalité
                                de voix.
                              </DrawText>
                            </>
                          ) : (
                            <>
                              {this.findWinner(
                                this.state.previousVote.voteResults
                              ).length === 1 ? (
                                <>
                                  {this.findWinner(
                                    this.state.previousVote.voteResults
                                  ).map((winner) => {
                                    return (
                                      <WinnerText key={winner.optionId}>
                                        {winner.optionName}
                                      </WinnerText>
                                    );
                                  })}{" "}
                                  remporte le nombre le plus élevé de suffrages
                                  exprimés.
                                </>
                              ) : this.findWinner(
                                  this.state.previousVote.voteResults
                                ).length > 1 ? (
                                <>
                                  Les candidats élus sont :
                                  {this.findWinner(
                                    this.state.previousVote.voteResults
                                  ).map((winner) => {
                                    return (
                                      <WinnerText key={winner.optionId}>
                                        {winner.optionName}
                                      </WinnerText>
                                    );
                                  })}
                                </>
                              ) : null}
                            </>
                          )
                        ) : (
                          <DrawText>
                            Aucun candidat n'a été élu. Un deuxième tour doit
                            être organisé.
                          </DrawText>
                        )}
                      </div>
                    )}
                    {this.state.previousVote.resultGraphType === "table" ? (
                      <table style={{ width: "100%" }}>
                        <thead style={{ backgroundColor: "#bdc3c7" }}>
                          <tr>
                            <th>Option n°</th>
                            <th>Libellé</th>
                            <th>Nombre de voix</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#ecf0f1" }}>
                          {this.state.previousVote.voteResults.map(
                            (option, index) => (
                              <tr>
                                <td>
                                  {this.state.previousVote.originalOptionOrder
                                    ? this.state.previousVote.originalOptionOrder.findIndex(
                                        (x) => x === option.optionId
                                      ) + 1
                                    : index + 1}
                                </td>
                                <td>{option.optionName}</td>
                                <td>
                                  {this.state.previousVote.resultDisplayType ===
                                  "voteCount"
                                    ? `${option.count || "0"}`
                                    : null}
                                  {this.state.previousVote.resultDisplayType ===
                                  "votePercent"
                                    ? `${this.computeVoteResultPercentage(
                                        this.state.previousVote.voteResults,
                                        option
                                      )}%`
                                    : null}
                                  {this.state.previousVote.resultDisplayType ===
                                  "both"
                                    ? `${
                                        option.count || "0"
                                      } (${this.computeVoteResultPercentage(
                                        this.state.previousVote.voteResults,
                                        option
                                      )}%)`
                                    : null}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <Chart
                        chartType={this.state.previousVote.resultGraphType}
                        displayType={this.state.previousVote.resultDisplayType}
                        data={this.state.previousVote.voteResults.map(
                          (x) => x.count
                        )}
                        labels={this.state.previousVote.voteResults.map((x) =>
                          x.optionType === "voteAbstain" &&
                          this.state.previousVote.voteType !== "resolutionVote"
                            ? "Vote(s) blanc(s)"
                            : x.optionName
                        )}
                        colors={this.state.previousVote.voteResults.map(
                          (x) => this.chartColors[x.optionType]
                        )}
                      />
                    )}
                  </ChartContainer>
                </>
              ) : (
                <Message>{this.state.message}</Message>
              )}
            </Container>

            {this.state.showSuccessModal ? (
              <ModalOverlay
                onClick={() => {
                  window.location.hash = "";
                  this.setState({ showSuccessModal: false });
                }}
              >
                <Modal onClick={(e) => e.stopPropagation()}>
                  <ModalClose
                    onClick={() => {
                      window.location.hash = "";
                      this.setState({ showSuccessModal: false });
                    }}
                  />
                  <ModalImage src="/img/check.png" alt="" width="75px" />
                  <Semibold>Votre mot de passe a bien été modifié !</Semibold>
                </Modal>
              </ModalOverlay>
            ) : null}
            {this.state.showAgEndModal ? (
              <ModalOverlay
                onClick={() => {
                  this.setState({ showAgEndModal: false });
                }}
                smModal
              >
                <Modal
                  onClick={(e) => e.stopPropagation()}
                  textAlign="left"
                  smModal
                >
                  <ModalIcon>
                    <DoneAllIcon
                      style={{ fontSize: 80, color: "rgb(0, 213, 123)" }}
                    />
                  </ModalIcon>

                  <p>
                    Les votes sont terminés, votre dernier vote est bien
                    enregistré
                  </p>

                  {!!this.state.ag.allowVoteEditionAfterValidation && (
                    <p>
                      Tant que la session de votes n’est pas clôturée, vous
                      pouvez modifier vos votes. Pour cela, fermez cette fenêtre
                      et naviguez dans vos votes par les flèches{" "}
                      <NoNewLineSpan>« Précédent »</NoNewLineSpan> ou{" "}
                      <NoNewLineSpan>« Suivant »</NoNewLineSpan>
                    </p>
                  )}
                  <p>
                    Vous pouvez également consulter, télécharger ou imprimer la
                    trace des votes que vous avez effectués. Pour cela, allez
                    dans votre Menu (cliquez sur votre nom en haut à droite),
                    puis <NoNewLineSpan>« Mes Votes »</NoNewLineSpan>
                  </p>

                  <p>Merci pour votre participation</p>

                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => this.setState({ showAgEndModal: false })}
                  >
                    Fermer cette fenêtre
                  </Button>
                </Modal>
              </ModalOverlay>
            ) : null}
            {this.state.showErrorMessageModal ? (
              <ModalOverlay
                onClick={() => {
                  this.setState({ showErrorMessageModal: false });
                }}
              >
                <Modal onClick={(e) => e.stopPropagation()}>
                  <ModalClose
                    onClick={() => {
                      this.setState({ showErrorMessageModal: false });
                    }}
                  />
                  <ModalImage src="/img/warning.png" alt="" width="100px" />
                  <Semibold>{this.state.errorMessage}</Semibold>
                </Modal>
              </ModalOverlay>
            ) : null}
          </>
        )}
      </>
    );
  }
}

export default Vote;
