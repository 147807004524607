import React from "react";
import {
	Title,
	LargeContainer,
	MediumContainer,
	SmallContainer,
	Button,
	Label,
	Error,
	Input,
	Modal,
	ModalOverlay,
	ModalClose,
	ColoredMessage,
} from "../utils/styled-components-library";
import styled from "styled-components";
import API from "../utils/API";
import Loader from "react-loader-spinner";
import { Formik, Form } from "formik";
import SignatureContainer from "../components/SignatureContainer";
import Swal from "sweetalert2";
import { voterIsParentVoter } from "../utils/votes";

const AttendanceInfoContainer = styled.div`
	margin-bottom: 20px;
	text-align: left;
`;

const SearchInput = styled.input.attrs({
	type: "text",
	placeholder: "Taper ici pour rechercher une personne...",
})`
	width: 90%;
	padding: 5px 10px;
	font-size: 15px;
	margin: 0 5px;
`;

const AttendeesContainer = styled.div`
	overflow-y: auto;
	height: ${(props) => (props.height ? props.height : "60")}vh;
	margin: 0px 10px 0 10px;
	text-align: left;
	border: ${(props) => (props.noBorder ? "none" : "1px solid #CCC")};
	width: 95%;
`;

const Attendee = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 5px 10px;
	border-bottom: 1px solid #ccc;
	cursor: pointer;

	:hover {
		background-color: #fafafa;
	}
`;

const AttendeePower = styled(Attendee)`
	cursor: auto;
	:hover {
		background-color: white;
	}
`;

const Toggle = styled.div`
	margin: auto;
	max-width: 600px;
	border: 3px solid #1d529c;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
`;

const ToggleElement = styled.div`
	background-color: ${(props) => (props.active ? "#1D529C" : "white")};
	color: ${(props) => (props.active ? "white" : "#1D529C")};
	padding: 5px 0;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	cursor: pointer;
`;

const AttendeeInfoContainer = styled.div`
	width: 100%;
`;

const InfoField = styled.div`
	display: flex;
	align-items: center;
`;

const InfoLabel = styled(Label)`
	width: 25%;
	margin-bottom: 12px;
`;

const FlexContainer = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
`;

const PaddedContainer = styled.div`
	padding: 5px;
`;

const ToggleSignature = styled.div`
	cursor: pointer;
	color: #1574f2;
	margin-bottom: 5px;
	user-select: none;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
`;

const ChechboxInput = styled.input`
	min-width: 16px;
	min-height: 16px;
	margin-right: 10px;
`;

const ConfirmationContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ConfirmationLabel = styled.label`
	color: ${(props) => (props.disabled ? "#CCC" : "black")};
	text-align: left;
	cursor: pointer;
`;

const FilterWrapper = styled.div`
	margin: auto;
	margin-top: 25px;
	display: flex;
	flex-direction: row;
	width: 95%;
`;

const Filter = styled.div`
	display: flex;
	flex-direction: row;
	width: 25%;
	justify-content: center;
	background-color: ${(props) => (props.selected ? "#1D529C" : "#f5f5f5")};
	color: ${(props) => (props.selected ? "white" : "")};
	cursor: pointer;
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-bottom: none;
`;

const StaffHeader = styled.div`
	width: 100%;
	background-color: white;
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
	z-index: 1;
	height: 64px;

	display: flex;
	flex-direction: row;
	align-items: center;

	justify-content: center;
`;

const HeaderLink = styled.a`
	text-decoration: none !important;
	color: black;
`;

class StaffAttendance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchInputValue: "",
			searchPowerInputValue: "",
			data: null,
			attendees: null,
			powerAttendees: null,
			attendanceCode: null,
			isAttendanceCodeValid: false,
			showAttendeeModal: false,
			showConfirmationModal: false,
			isStatusChanged: false,
			selectedModalOption: "info",
			currentAttendeeSelected: null,
			isSignatureNeeded: false,
			isConfirmationLoading: false,
			showSignature: false,
			isAbsent: false,
			editInfo: false,
			attendeesFilter: "all",
			isQuorumReached: false,
			maximumDelegatedPower: null,
		};
	}

	sortByLastName = (array) => {
		array.sort((a, b) => {
			if (a.voterLastname.toLowerCase() < b.voterLastname.toLowerCase()) {
				return -1;
			}
			if (a.voterLastname.toLowerCase() > b.voterLastname.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return array;
	};

	componentDidMount() {
		const code = new URLSearchParams(window.location.search).get("code");
		if (code) {
			this.setState({ attendanceCode: code }, () => {
				this.getData();
			});
		}
		document.title = "ProVote - Emargement";
	}

	handleSearchChange = (event, key, keyInput) => {
		const filteredAttendees = this.state.data.filter(
			(x) =>
				x.voterFirstname
					.toLowerCase()
					.includes(event.target.value.toLowerCase()) ||
				x.voterLastname.toLowerCase().includes(event.target.value.toLowerCase())
		);
		this.sortByLastName(filteredAttendees);
		let newState = {
			...this.state,
		};
		newState[key] = filteredAttendees;
		newState[keyInput] = event.target.value;
		this.setState(newState);
	};

	computePresenceCount = () => {
		let actualAttendance = 0;
		if (this.state.data) {
			this.state.data.map((x) => {
				if (!x.delegateVoterId && x.status === "present") {
					actualAttendance += 1;
				}
			});
		}
		return actualAttendance;
	};

	computePresencePowerCount = () => {
		let actualAttendance = 0;
		if (this.state.data) {
			this.state.data.forEach((voter) => {
				if (!voter.delegateVoterId) {
					if (voter.status === "present") {
						actualAttendance += voter.voterPowerCount;
					}
				} else {
					let delegatedVoter = this.state.data.find(
						(el) => el.voterId == voter.delegateVoterId
					);
					if (delegatedVoter && delegatedVoter.status == "present") {
						actualAttendance += voter.voterPowerCount;
					}
				}
			});
		}
		return actualAttendance;
	};

	computeTotalAttendance = () => {
		let expectedAttendance = 0;
		if (this.state.data) {
			this.state.data.forEach((voter) => {
				if (!voter.delegateVoterId) {
					expectedAttendance++;
				}
			});
		}

		return expectedAttendance;
	};

	computeTotalPowerCount = () => {
		let actualAttendance = 0;
		if (this.state.data) {
			this.state.data.forEach((x) => {
				actualAttendance += x.voterPowerCount;
			});
		}
		return actualAttendance;
	};



	// Give currentAttendeeSelected if you want to update the current selected attendee's data when retrieving the data
	getData = (currentAttendeeSelectedId) => {
		API.get(
			"/attendanceDetails",
			{ codeEmargement: this.state.attendanceCode },
			(data) => {
				if (data.success) {
					let attendees = data.attendees;
					this.successCallback(
						attendees,
						this.state.attendanceCode,
						data.signatureNeeded,
						data.quorum,
						data.isQuorumReached,
						data.maximumDelegatedPower,
						currentAttendeeSelectedId
					);
				} else {
					this.setState({ isSubmitting: true });
				}
			}
		);
	};

	// Give currentAttendeeSelected if you want to update the current selected attendee's data when retrieving the data
	successCallback = (
		attendees,
		attendanceCode,
		signatureNeeded,
		quorum,
		isQuorumReached,
		maximumDelegatedPower,
		currentAttendeeSelectedId
	) => {
		console.log("SUCCESS CALLBACK", attendees);
		this.setState(
			{
				data: attendees,
				powerAttendees: attendees,
				attendees,
				isAttendanceCodeValid: true,
				attendanceCode,
				isSignatureNeeded: signatureNeeded === 1 ? true : false,
				quorum,
				isQuorumReached,
				maximumDelegatedPower,
				currentAttendeeSelected: currentAttendeeSelectedId
					? attendees.find((x) => x.voterId === currentAttendeeSelectedId)
					: this.state.currentAttendeeSelected,
			},
			() => {
				const newUrl =
					window.location.protocol +
					"//" +
					window.location.host +
					window.location.pathname +
					"?code=" +
					attendanceCode;
				window.history.pushState({ path: newUrl }, "", newUrl);
			}
		);
	};

	loadAttendeeData = (attendee) => {
		this.setState({
			showAttendeeModal: true,
			currentAttendeeSelected: attendee,
		});
	};

	changeAttendeePower = (delegateVoterId, voterId, status, refreshCallback) => {
		API.post(
			"/changeAttendeePower",
			{
				delegateVoterId,
				voterId,
				status,
			},
			refreshCallback
		);
	};

	sendNewStatus = (voterId, status, signature) => {
		API.post(
			"/changeVoterAttendance",
			{ voterId, status, signature },
			(data) => {
				this.setState({ isConfirmationLoading: false });
				if (data.success) {
					this.setState({
						isStatusChanged: true,
					});
					this.getData();
					setTimeout(() => {
						this.setState({
							showConfirmationModal: false,
							currentAttendeeSelected: {
								...this.state.currentAttendeeSelected,
								status,
								signature: this.state.currentSignature,
							},
							isStatusChanged: false,
							isAbsent: false,
							showAttendeeModal: false,
						});
						this.getData();
					}, 1500);
				}
			}
		);
	};

	changeAttendeeStatus = (voterId) => {
		this.setState({ isConfirmationLoading: true });
		const status = this.state.isAbsent ? "left" : "present";
		const signature = this.state.currentSignature || null;
		this.sendNewStatus(voterId, status, signature);
	};

	getAttendeeFormatedForId = (id) => {
		const obj = this.state.attendees.find((el) => el.voterId === id);
		return obj.voterFirstname + " " + obj.voterLastname.toUpperCase();
	};

	render() {
		const customFieldsInitialValues = {};
		if (this.state.currentAttendeeSelected !== null) {
			this.state.currentAttendeeSelected.customFields.forEach(
				(field) =>
					(customFieldsInitialValues[`customField${field.id}`] = field.data)
			);
		}

		// Calcule du nombre de personne par onglet
		let allCounter = 0;
		let outCounter = 0;
		let presentCounter = 0;
		let expectedCounter = 0;
		let delegateCounter = 0;
		if (this.state.attendees)
			this.state.attendees.forEach((attendee) => {
				if (attendee.status === "present") {
					presentCounter++;
				} else if (attendee.status === "left") {
					outCounter++;
				} else if (attendee.delegateVoterId !== null) {
					delegateCounter++;
				} else if (attendee.status === null) {
					expectedCounter++;
				}
				allCounter++;
			});

		return (
			<>
				<StaffHeader>
					<img alt="ProVote" src="/logo.jpg" height="60" />
					<HeaderLink href="/emargement">Emargement</HeaderLink>
				</StaffHeader>
				<LargeContainer
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: 64,
					}}
				>
					{!this.state.attendanceCode || !this.state.isAttendanceCodeValid ? (
						<SmallContainer>
							<Formik
								initialValues={{
									attendanceCode: "",
								}}
								validate={(values) => {
									const errors = {};
									if (!values.attendanceCode) {
										errors.required = "Required";
									}

									return errors;
								}}
								onSubmit={(values, { setSubmitting, setErrors }) => {
									API.get(
										"/attendanceDetails",
										{ codeEmargement: values.attendanceCode },
										(data) => {
											setSubmitting(false);
											if (data.success) {
												this.successCallback(
													data.attendees,
													values.attendanceCode,
													data.signatureNeeded,
													data.quorum,
													data.isQuorumReached
												);
											} else {
												setErrors({
													attendanceCode:
														"Le code d'émargement rentré ne correspond à aucune session de vote.",
												});
											}
										}
									);
								}}
								validateOnMount
							>
								{({ isSubmitting, isValid }) => (
									<Form>
										<Label>Code d'émargement</Label>
										<Input name="attendanceCode" type="text" />
										<Error name="attendanceCode" component="div" />
										<Button
											onClick={this.submit}
											disabled={!isValid || isSubmitting}
										>
											Envoyer code
										</Button>
									</Form>
								)}
							</Formik>
						</SmallContainer>
					) : (
						<>
							<AttendanceInfoContainer>
								<div>
									Nombre de personne(s) ayant émargé (individus) :{" "}
									{this.computePresenceCount()} /{" "}
									{this.computeTotalAttendance()}
								</div>
								<div>
									Nombre de personne(s) ayant émargé (pouvoirs) :{" "}
									{this.computePresencePowerCount()} /{" "}
									{this.computeTotalPowerCount()}
								</div>
								{this.state.quorum && this.state.quorum.value ? (
									<div>
										{"Quorum: " +
											this.state.quorum.value +
											" " +
											this.state.quorum.unit}{" "}
										({this.state.isQuorumReached ? "atteint" : "non atteint"})
									</div>
								) : this.state.quorum && this.state.quorum.message ? (
									<div>{this.state.quorum.message}</div>
								) : null}
							</AttendanceInfoContainer>
							<SearchInput
								value={this.state.searchInputValue}
								onChange={(event) =>
									this.handleSearchChange(
										event,
										"attendees",
										"searchInputValue"
									)
								}
							/>
							<FilterWrapper>
								<Filter
									onClick={() => this.setState({ attendeesFilter: "all" })}
									selected={this.state.attendeesFilter == "all"}
								>
									Tous ({allCounter})
								</Filter>
								<Filter
									onClick={() => this.setState({ attendeesFilter: null })}
									selected={this.state.attendeesFilter === null}
								>
									Attendus ({expectedCounter})
								</Filter>
								<Filter
									onClick={() => this.setState({ attendeesFilter: "present" })}
									selected={this.state.attendeesFilter == "present"}
								>
									Présents ({presentCounter})
								</Filter>
								<Filter
									onClick={() => this.setState({ attendeesFilter: "left" })}
									selected={this.state.attendeesFilter == "left"}
								>
									Sortis ({outCounter})
								</Filter>
								<Filter
									onClick={() => this.setState({ attendeesFilter: "delegate" })}
									selected={this.state.attendeesFilter === "delegate"}
								>
									Pouvoirs délégués ({delegateCounter})
								</Filter>
							</FilterWrapper>
							<AttendeesContainer>
								{this.state.attendees
									? this.state.attendees.map((attendee) => {
											if (
												(attendee.status === this.state.attendeesFilter &&
													attendee.delegateVoterId === null) ||
												this.state.attendeesFilter === "all" ||
												(this.state.attendeesFilter === "delegate" &&
													attendee.delegateVoterId !== null)
											) {
												return (
													<Attendee
														key={attendee.voterId}
														onClick={() => {
															console.log("SELECTED ATTENDEE", attendee);
															this.loadAttendeeData(attendee);
														}}
													>
														<div>
															<strong>
																{attendee.customFields.filter(x => x.name === "Nom")[0].data.toUpperCase()}
															</strong>{" "}
															{attendee.customFields.filter(x => x.name === "Prénom")[0].data}
														</div>
														<div>
															{attendee.status === "present" ? (
																<span style={{ color: "green" }}>Présent</span>
															) : attendee.delegateVoterId ? (
																<span style={{ color: "red" }}>
																	Pouvoir délégué
																</span>
															) : attendee.status === "left" ? (
																<span style={{ color: "#00A1AD" }}>Sorti</span>
															) : (
																<span style={{ color: "orange" }}>
																	En attente
																</span>
															)}
														</div>
													</Attendee>
												);
											}
									  })
									: null}
							</AttendeesContainer>
						</>
					)}
				</LargeContainer>
				{this.state.showAttendeeModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({
								showAttendeeModal: false,
								selectedModalOption: "info",
								showSignature: false,
								editInfo: false,
							});
						}}
					>
						<Modal
							onClick={(e) => {
								e.stopPropagation();
							}}
							maxWidth="800"
						>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({
										showAttendeeModal: false,
										selectedModalOption: "info",
										showSignature: false,
										editInfo: false,
									});
								}}
							/>
							<Toggle>
								<ToggleElement
									onClick={() => {
										this.setState({ selectedModalOption: "info" });
									}}
									active={this.state.selectedModalOption === "info"}
								>
									Informations
								</ToggleElement>
								{this.state.currentAttendeeSelected.voterPowerCount != 0 && (
									<ToggleElement
										onClick={() => {
											this.setState({ selectedModalOption: "power" });
										}}
										active={this.state.selectedModalOption === "power"}
									>
										Pouvoirs
									</ToggleElement>
								)}
							</Toggle>
							<MediumContainer style={{ marginTop: "20px" }} noPadding>
								{this.state.selectedModalOption === "info" ? (
									<AttendeeInfoContainer>
										<div>
											Vous pouvez ici modifier les informations du votant.
										</div>
										{this.state.currentAttendeeSelected ? (
											<>
												<Formik
													initialValues={{
														voterLastname:
															this.state.currentAttendeeSelected.voterLastname,
														voterFirstname:
															this.state.currentAttendeeSelected.voterFirstname,
														voterEmail:
															this.state.currentAttendeeSelected.voterEmail,
														voterId: this.state.currentAttendeeSelected.voterId,
														...customFieldsInitialValues,
													}}
													validate={(values) => {
														const errors = {};
														if (!values.voterLastname) {
															errors.required = "Required";
														}
														if (!values.voterFirstname) {
															errors.required = "Required";
														}
														if (!values.voterEmail) {
															errors.required = "Required";
														}
														// if (
														// 	!values.voterPhone ||
														// 	!/^((\+)33)[1-9](\d{2}){4}$/g.test(
														// 		values.voterPhone
														// 	)
														// ) {
														// 	errors.required = "Required";
														// 	errors.voterPhone =
														// 		"Le format du numéro de téléphone n'est pas accepté. (Format valide : +33XXXXXXXXX)";
														// }
														return errors;
													}}
													onSubmit={(values, { setSubmitting, setErrors }) => {
														// if (
														// 	!/^((\+)33)[1-9](\d{2}){4}$/g.test(
														// 		values.voterPhone
														// 	)
														// ) {
														// 	setErrors({
														// 		voterPhone:
														// 			"Le format du numéro de téléphone n'est pas accepté. (Format valide : +33XXXXXXXXX)",
														// 	});
														// 	setSubmitting(true);
														// } else {
														API.post(
															"/voterInfo",
															{ ...values, action: "update" },
															(data) => {
																setSubmitting(false);
																if (data.success) {
																	//TODO Message succès ?
																	Swal.fire({
																		text: "Le changement a été effectué",
																		icon: "success",
																		confirmButtonText: "Ok",
																	});
																	this.getData();
																}
															}
														);
														// }
													}}
													validateOnMount
												>
													{({ isSubmitting, isValid }) => (
														<Form style={{ marginTop: "10px" }}>
															{this.state.currentAttendeeSelected.customFields.map(
																(field) => {
																	if (field.hidden == 0) {
																		return (
																			<InfoField key={field.name}>
																				<InfoLabel>{field.name}</InfoLabel>
																				<Input
																					name={`customField${field.id}`}
																					type="text"
																				/>
																			</InfoField>
																		);
																	}
																}
															)}
															<Error name="voterPhone" component="div" />
															<Button
																width="30%"
																onClick={this.submit}
																disabled={!isValid || isSubmitting}
																style={{ marginBottom: "10px" }}
															>
																Enregistrer les informations
															</Button>
														</Form>
													)}
												</Formik>
												<FlexContainer>
													{this.state.currentAttendeeSelected.status ===
													"present" ? (
														<>
															<em>Présence confirmée</em>
															{this.state.currentAttendeeSelected.signature ? (
																<PaddedContainer>
																	<ToggleSignature
																		onClick={() => {
																			const currentToggle =
																				this.state.showSignature;
																			this.setState({
																				showSignature: !currentToggle,
																			});
																		}}
																	>
																		Afficher la signature
																	</ToggleSignature>
																	{this.state.showSignature ? (
																		<img
																			src={
																				this.state.currentAttendeeSelected
																					.signature
																			}
																			alt="signature"
																			style={{
																				padding: "5px",
																				border: "2px solid #DDD",
																			}}
																		/>
																	) : null}
																</PaddedContainer>
															) : null}
														</>
													) : this.state.currentAttendeeSelected.status ===
													  "left" ? (
														<em>Sortie confirmée</em>
													) : null}
													<>
														<ButtonContainer>
															<Button
																width="30%"
																onClick={() =>
																	this.setState({ showConfirmationModal: true })
																}
																disabled={
																	this.state.currentAttendeeSelected
																		.delegateVoterId ||
																	this.state.currentAttendeeSelected.status ==
																		"present"
																}
															>
																Confirmer la présence
															</Button>
															<Button
																width="30%"
																onClick={() =>
																	this.setState({
																		showConfirmationModal: true,
																		isAbsent: true,
																	})
																}
																disabled={
																	this.state.currentAttendeeSelected.status ==
																		"left" ||
																	this.state.currentAttendeeSelected.status ==
																		null
																}
															>
																Signaler une sortie
															</Button>
															<Button
																width="30%"
																onClick={() =>
																	this.sendNewStatus(
																		this.state.currentAttendeeSelected.voterId,
																		null,
																		null
																	)
																}
																disabled={
																	this.state.currentAttendeeSelected.status ==
																	null
																}
															>
																Signaler une absence
															</Button>
														</ButtonContainer>
													</>
												</FlexContainer>
											</>
										) : null}
									</AttendeeInfoContainer>
								) : this.state.selectedModalOption === "power" ? (
									<div>
										{this.state.currentAttendeeSelected
											.delegateVoterId ? null : (
											<SearchInput
												value={this.state.searchPowerInputValue}
												onChange={(event) =>
													this.handleSearchChange(
														event,
														"powerAttendees",
														"searchPowerInputValue"
													)
												}
											/>
										)}
										<AttendeesContainer
											height="40"
											noBorder={
												this.state.currentAttendeeSelected.delegateVoterId
											}
										>
											{this.state.currentAttendeeSelected.delegateVoterId ? (
												<Title>
													{"Votre pouvoir a été délégué à " +
														this.getAttendeeFormatedForId(
															this.state.currentAttendeeSelected.delegateVoterId
														)}
													{"."}
												</Title>
											) : this.state.currentAttendeeSelected.parentVoterId ? (
												<Title>
													{"Ce votant est le suppléant de " +
														this.getAttendeeFormatedForId(
															this.state.currentAttendeeSelected.parentVoterId
														)}
													{"."}
												</Title>
											) : this.state.powerAttendees ? (
												this.state.powerAttendees
													.filter((x) => {
														// Voter to delegate power
														const selectedVoterIsParentVoter =
															voterIsParentVoter(
																this.state.powerAttendees,
																this.state.currentAttendeeSelected
															);

														const voterHasParentVoter =
															x.parentVoterId &&
															x.parentVoterId ===
																this.state.currentAttendeeSelected.voterId;

														return (
															x.voterId !==
																this.state.currentAttendeeSelected.voterId &&
															(x.voterPowerCount !== 0 ||
																voterHasParentVoter) &&
															((!x.parentVoterId &&
																!selectedVoterIsParentVoter) ||
																voterHasParentVoter)
														);
													})
													.map((attendee) => {
														const isUnderDelegatedPowerLimits =
															this.state.maximumDelegatedPower === 0 ||
															this.state.currentAttendeeSelected
																.receivedDelegations +
																1 <=
																this.state.maximumDelegatedPower;

														return (
															<AttendeePower key={attendee.voterId}>
																<div>
																	<strong>
																		{attendee.voterLastname.toUpperCase()}
																	</strong>{" "}
																	{attendee.voterFirstname}
																</div>
																{attendee.delegateVoterId ===
																this.state.currentAttendeeSelected.voterId ? (
																	<i
																		className="fa fa-check-square"
																		style={{
																			color: "#81c784",
																			cursor: "pointer",
																			marginTop: "auto",
																			marginBottom: "auto",
																			fontSize: "20px",
																		}}
																		onClick={() => {
																			this.changeAttendeePower(
																				this.state.currentAttendeeSelected
																					.voterId,
																				attendee.voterId,
																				"remove",
																				() => {
																					this.getData(
																						this.state.currentAttendeeSelected
																							.voterId
																					);
																				}
																			);
																		}}
																	/>
																) : isUnderDelegatedPowerLimits ? (
																	<i
																		className="far fa-square"
																		style={{
																			color: "black",
																			cursor: "pointer",
																			marginTop: "auto",
																			marginBottom: "auto",
																			fontSize: "20px",
																		}}
																		onClick={() => {
																			this.changeAttendeePower(
																				this.state.currentAttendeeSelected
																					.voterId,
																				attendee.voterId,
																				"add",
																				() => {
																					this.getData(
																						this.state.currentAttendeeSelected
																							.voterId
																					);
																				}
																			);
																		}}
																	/>
																) : null}
															</AttendeePower>
														);
													})
											) : null}
										</AttendeesContainer>
									</div>
								) : null}
							</MediumContainer>
						</Modal>
					</ModalOverlay>
				) : null}
				{this.state.showConfirmationModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({
								showConfirmationModal: false,
								isStatusChanged: false,
								isConfirmationLoading: false,
								isAbsent: false,
							});
						}}
					>
						<Modal
							onClick={(e) => {
								e.stopPropagation();
							}}
							maxWidth="600"
						>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({
										showConfirmationModal: false,
										isStatusChanged: false,
										isConfirmationLoading: false,
										isAbsent: false,
									});
								}}
							/>
							<Title>
								Confirmation de{" "}
								{this.state.isAbsent ? "la sortie" : "la présence"} de{" "}
								<strong>
									{this.state.currentAttendeeSelected.voterFirstname +
										" " +
										this.state.currentAttendeeSelected.voterLastname.toUpperCase()}
								</strong>
							</Title>
							<>
								<ConfirmationContainer>
									{this.state.isSignatureNeeded && !this.state.isAbsent ? (
										<SignatureContainer
											ref={(ref) => {
												this.sigPad = ref;
											}}
										/>
									) : (
										<CheckboxContainer>
											<ChechboxInput
												type="checkbox"
												ref={(ref) => {
													this.checkboxConfirm = ref;
												}}
												id="confirmationCheckbox"
											/>
											<ConfirmationLabel for="confirmationCheckbox">
												En cochant cette case, je confirme{" "}
												{this.state.isAbsent
													? `la sortie de ${
															this.state.currentAttendeeSelected
																.voterFirstname +
															" " +
															this.state.currentAttendeeSelected.voterLastname.toUpperCase()
													  }`
													: "ma présence"}{" "}
												durant la session de vote.
											</ConfirmationLabel>
										</CheckboxContainer>
									)}
									<>
										{this.state.isConfirmationLoading ? (
											<Loader
												type="Bars"
												color="#1D529C"
												height={50}
												width={50}
											/>
										) : (
											<Button
												style={{ marginTop: 20 }}
												onClick={() => {
													if (
														this.state.isSignatureNeeded &&
														!this.state.isAbsent
													) {
														if (!this.sigPad.isEmpty()) {
															this.setState(
																{
																	currentSignature: this.sigPad
																		.getTrimmedCanvas()
																		.toDataURL("image/png"),
																},
																() => {
																	this.changeAttendeeStatus(
																		this.state.currentAttendeeSelected.voterId
																	);
																}
															);
														}
													} else {
														if (
															this.checkboxConfirm &&
															this.checkboxConfirm.checked
														) {
															this.changeAttendeeStatus(
																this.state.currentAttendeeSelected.voterId
															);
														}
													}
												}}
											>
												Valider
											</Button>
										)}
									</>
								</ConfirmationContainer>
								{this.state.isStatusChanged ? (
									<ColoredMessage color="green">
										Confirmation réussie
									</ColoredMessage>
								) : null}
							</>
						</Modal>
					</ModalOverlay>
				) : null}
			</>
		);
	}
}

export default StaffAttendance;
