import React, { useContext } from "react";
import {
  Button,
  Title,
  Label,
  Input,
  SmallContainer,
  Error,
} from "../utils/styled-components-library";
import { Formik, Form } from "formik";
import API from "../utils/API";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../App";

const PasswordStrengthIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const PasswordStrengthIndicator = styled.div`
  height: 10px;
  border-radius: 2px;
  background-color: grey;
  width: 25%;
  margin: 0px 5px;
  background-color: ${(props) => props.color};
`;

const passwordStrengthIndicator = (strength) => {
  let color1, color2, color3;
  color1 = color2 = color3 = "grey";
  let strengthText = "Faible";
  switch (strength) {
    case "weak":
      color1 = "red";
      break;
    case "medium":
      color1 = color2 = "yellow";
      strengthText = "Moyen";
      break;
    case "strong":
      color1 = color2 = color3 = "green";
      strengthText = "Fort";
      break;
    case "none":
    default:
      break;
  }
  return (
    <PasswordStrengthIndicatorContainer>
      <PasswordStrengthIndicator color={color1} />
      <PasswordStrengthIndicator color={color2} />
      <PasswordStrengthIndicator color={color3} />
      <div style={{ textAlign: "center" }}>
        <strong>{strengthText}</strong>
      </div>
    </PasswordStrengthIndicatorContainer>
  );
};

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getUri = (userType) => {
    if (userType === "client") {
      return "/updateClientPassword";
    }
    if (userType === "voter" || userType === "cseVoter") {
      return "/updateVoterPassword";
    }
    if (userType === "admin") {
      return "/updatePassword";
    }
  };

  testPasswordStrength(password) {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    ); // At least one letter lower case, one upper case, a number, a special char and min. 8 chars
    const mediumRegex = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    if (strongRegex.test(password)) {
      return "strong";
    } else if (mediumRegex.test(password)) {
      return "medium";
    } else return "weak";
  }

  render() {
    console.log(">>>", this.props.user);
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }
    return (
      <SmallContainer>
        <Title style={{ marginBottom: "10px" }}>
          Modifier mon mot de passe
        </Title>
        <div style={{ marginBottom: "10px", fontSize: "13px" }}>
          Afin de protéger au mieux vos données, nous vous conseillons d'entrer
          un mot de passe fort d'au moins 8 caractères, contenant au moins une
          lettre minuscule, une majuscule, un chiffre et un caractère spécial
          (!@#$%^&*).
        </div>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.oldPassword) {
              errors.required = "Required";
            }
            if (!values.newPassword) {
              errors.required = "Required";
            }
            if (!values.confirmNewPassword) {
              errors.required = "Required";
            }
            if (values.newPassword !== values.confirmNewPassword) {
              errors.confirmNewPassword =
                "Les mots de passe ne correspondent pas.";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            console.log("OK");
            const uri = this.getUri(this.props.userType);
            console.log("uri", uri);
            console.log();

            API.post(uri, { ...values, user: this.props.user }, (data) => {
              setSubmitting(false);
              if (data.success) {
                this.setState({ redirect: `/#success` });
              } else if (data.message === "oldPassword") {
                setErrors({
                  oldPassword:
                    "Le mot de passe rentré ne correspond pas à votre ancien mot de passe.",
                });
              } else if (data.message === "alreadyUsedPassword") {
                setErrors({
                  confirmNewPassword:
                    "Le mot de passe est le même que sur un autre compte !",
                });
              }
            });
          }}
          validateOnMount
        >
          {(props) => (
            <Form>
              <Label>Ancien mot de passe*</Label>
              <Input name="oldPassword" type="password" />
              <Error name="oldPassword" component="div" />
              <Label>Nouveau mot de passe*</Label>
              <Input name="newPassword" type="password" />
              {props.values.newPassword
                ? passwordStrengthIndicator(
                    this.testPasswordStrength(props.values.newPassword)
                  )
                : null}
              <Label>Confirmation du nouveau mot de passe*</Label>
              <Input name="confirmNewPassword" type="password" />
              {props.values.confirmNewPassword
                ? passwordStrengthIndicator(
                    this.testPasswordStrength(props.values.confirmNewPassword)
                  )
                : null}
              <Error name="confirmNewPassword" component="div" />
              <div style={{ display: "flex" }}>
                <Button
                  white
                  onClick={() => this.setState({ redirect: "/" })}
                  style={{ marginRight: 6 }}
                >
                  Retour
                </Button>
                <Button
                  onClick={this.submit}
                  disabled={!props.isValid || props.isSubmitting}
                >
                  Modifier
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </SmallContainer>
    );
  }
}

export default UpdatePassword;
