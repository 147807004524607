import React from "react";
import classes from "./CustomButton.module.scss";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { Button } from "../../utils/styled-components-library";

const CustomButton = ({
  variant = "original",
  onClick = () => {},
  icon = "",
  iconAlt = "",
  title = "",
  text = "",
  href = "",
  isBig = false,
  className = "",
  style = {},
  disabled = false,
  type = "button",
  children = <></>,
  white = false,
  invert = false,
  target = "_self",
  rel = "",
  ...props
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (href) {
      history.push(href);
    }
    onClick();
  };
  if (variant === "original") {
    return (
      <Button
        disabled={disabled}
        white={white}
        invert={invert}
        onClick={handleClick}
        style={style}
        className={className}
        type={type}
        {...props}
      >
        {children}
      </Button>
    );
  }
  return (
    <button
      type={type as "submit" | "reset" | "button"}
      onClick={handleClick}
      style={style}
      target={target}
      className={cn(className, classes.CustomButton, {
        [classes.CustomButton__isBig]: variant === "big",
        [classes.CustomButton__isWhite]: variant === "back",
        [classes.CustomButton__disabled]: disabled,
      })}
    >
      {icon && <img className={classes.icon} src={icon} alt={iconAlt}></img>}
      {title && (
        <div>
          <p className={classes.title}>{title}</p>
          {text && <p className={classes.text}>{text}</p>}
        </div>
      )}
      {children}
    </button>
  );
};

export default CustomButton;
