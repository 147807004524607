import React from "react";
import UpdatePassword from "../screens/UpdatePassword";
import PageNotFound from "../screens/PageNotFound";
import { Route, Switch } from "react-router-dom";
import TitleComponent from "../components/TitleComponent";
import CseList from "../screens/Cse/CseList";
import CseDetails from "../screens/Cse/CseDetails";
import CseColleges from "../screens/Cse/CseColleges";
import CseCollegeCreate from "../screens/Cse/CseCollegeCreate";
import CseAccess from "../screens/Cse/CseAccess";
import CsePollingStation from "../screens/Cse/CsePollingStation";
import CseAttendance from "../screens/Cse/CseAttendance";
import CseResults from "../screens/Cse/CseResults";
import CseVotersList from "../screens/Cse/CseVotersList";
import CsePreElectoral from "../screens/Cse/CsePreElectoral";
import CseStripping from "../screens/Cse/CseStripping";
import CseReload from "../screens/Cse/CseReload";
import { Redirect } from "react-router-dom";
import CseCerfa from "../screens/Cse/cseCerfa";
import CseLetter from "../screens/Cse/cseLetter";
import CseSecurity from "../screens/Cse/CseSecurity";
import CseEnterpriseInfo from "../screens/Cse/CseEnterpriseInfo";

class AdminNavigator extends React.Component {
  render() {
    return (
      <>
        <TitleComponent
          title={
            this.props.user.isAdmin
              ? "Provote - Organisateur"
              : "Provote - Assesseur"
          }
        />
        <Switch>
          <Route
            exact
            path="/"
            component={(props) => (
              <Redirect
                user={this.props.user}
                {...props}
                to={`/cse/${this.props.user.cseId}`}
              />
            )}
          />
          <Route
            exact
            path="/modifier-mot-de-passe"
            render={(props) => (
              <UpdatePassword
                userType="client"
                user={this.props.user}
                {...props}
              />
            )}
          />
          <Route exact path="/cse/:cseId" component={CseDetails} />

          <Route exact path="/cse/:cseId/info" component={CseDetails} />
          <Route exact path="/cse/:cseId/colleges" component={CseColleges} />
          <Route
            exact
            path="/cse/:cseId/colleges/creation"
            component={CseCollegeCreate}
          />
          <Route
            exact
            path="/cse/:cseId/colleges/modification/:cseCollegeId"
            render={(props) => <CseCollegeCreate isEdit={true} {...props} />}
          />
          <Route
            exact
            path="/cse/:cseId/acces-autorises"
            component={CseAccess}
          />

          <Route
            exact
            path="/cse/:cseId/bureaux-de-vote"
            component={CsePollingStation}
          />

          <Route
            exact
            path="/cse/:cseId/emargement"
            component={CseAttendance}
          />

          <Route exact path="/cse/:cseId/resultats" component={CseResults} />

          <Route
            exact
            path="/cse/:cseId/liste-des-votants"
            component={CseVotersList}
          />

          <Route
            exact
            path="/cse/:cseId/protocole-d-accord-pre-electoral"
            component={CsePreElectoral}
          />

          <Route
            exact
            path="/cse/:cseId/depouillement"
            component={CseStripping}
          />

          <Route exact path="/cse/:cseId/cerfa" component={CseCerfa} />

          <Route
            exact
            path="/cse/:cseId/generer-courrier"
            component={CseLetter}
          />

          <Route exact path="/cse/:cseId/reload/:tab" component={CseReload} />

          <Route exact path="/cse/:cseId/securite" component={CseSecurity} />

          <Route
            exact
            path="/cse/:cseId/info-entreprise"
            component={CseEnterpriseInfo}
          />

          <Route component={PageNotFound} />
        </Switch>
      </>
    );
  }
}

export default AdminNavigator;
