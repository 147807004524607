import React, { useState, useEffect } from "react";
import {
	ChartContainer,
	WinnerText,
	DrawText,
} from "../utils/styled-components-library";
import styled from "styled-components";
import Chart from "../components/Chart";
import API from "../utils/API";
import { sortVotesByPosition } from "../utils/votes";

const SmallTitle = styled.div`
	font-size: 24px;
	margin: 0 auto;
	margin-bottom: 15px;
	text-align: center;
`;

export default function VoteResultsComponent({ agId, voteId, zoom, printing }) {
	const searchParams = new URLSearchParams(window.location.search);

	const [votes, setVotes] = useState([]);
	const colors = {
		voteFor: "#009688",
		voteAgainst: "#E91E63",
		voteAbstain: "#FF9800",
		voteExtraOption: "#42a5f5",
		null: "#1D529C",
	};

	const findWinner = (voteResults) => {
		let max = 0;
		let winner = null;
		voteResults
			.filter((x) => x.optionType !== "voteAbstain")
			.forEach((voteResult) => {
				if (voteResult.count >= max) {
					max = voteResult.count;
					winner = voteResult;
				}
			});
		return [winner];
	};

	/**
	 * Détermine si les résultats d'une élection à deux tours doivent être affichés
	 * @param {*} vote
	 */
	const showTwoRoundsElectionsResults = (vote) => {
		if (vote.voteType === "oneRoundElection") {
			// à refactorer, on appelle cette méthode pour les élection à 1 tour, il faut toujours afficher les résultats pour ces élections
			return true;
		}

		let castedVotes = vote.useVotePowers
			? vote.castedVotesWithPowers
			: vote.castedVotes;
		let expectedVotes = vote.useVotePowers
			? vote.expectedVotes
			: vote.expectedVotesWithPowers;

		// Deuxième tour de l'élection : on affiche les résultats
		if (vote.electionRound === 2) {
			return true;
		}

		// Moins de 25% des électeurs ont voté : on n'affiche pas les résultats
		if (castedVotes < expectedVotes / 4) {
			return false;
		}

		// Un candidat a au moins 50% des voix
		let totalNumberOfVotes = vote.voteResults.reduce(
			(sum, val) => sum + val.count,
			0
		);

		return vote.voteResults
			.filter((x) => !x.optionType)
			.some((result) => result.count > totalNumberOfVotes / 2);
	};

	const getVotesData = () => {
		API.get(
			"/voteResults",
			{
				agId: agId || searchParams.get("agId"),
				voteId: voteId || searchParams.get("voteId"),
			},
			(votes) => {
				setVotes(votes);
			}
		);
	};

	useEffect(() => {
		getVotesData();
	}, [getVotesData]);

	const isTie = (voteResults) => {
		let max = 0;
		let tie = false;
		voteResults
			.filter((x) => x.optionType !== "voteAbstain")
			.forEach((voteResult) => {
				if (voteResult.count > max) {
					max = voteResult.count;
					tie = false;
				} else if (voteResult.count === max) {
					tie = true;
				}
			});
		return tie;
	};

	const computeVoteResultPercentage = (voteResults, option) => {
		// Total de voix
		let total = voteResults.reduce(function (acc, currentResult) {
			if (currentResult.count) {
				return acc + parseFloat(currentResult.count);
			} else return acc;
		}, 0);
		// Pourcentage
		return parseFloat((option.count / total) * 100).toFixed(2);
	};

	const sortedVotes = sortVotesByPosition(votes);

	return (
		<>
			{votes ? (
				votes.length > 0 ? (
					sortedVotes.map((vote, index) => {
						const originalVoteOrder = vote.voteResults.map((x) => x.optionId);

						if (
							vote.voteType === "oneRoundElection" ||
							vote.voteType === "twoRoundsElection" ||
							vote.voteType === "plurinominalElection"
						) {
							vote.voteResults.sort((a, b) => {
								return (b.count || 0) - (a.count || 0);
							});
						}

						const voteWinners = findWinner(vote.voteResults);

						return (
							<ChartContainer
								key={vote.voteId}
								index={index}
								printing={printing}
							>
								<SmallTitle>{vote.voteName}</SmallTitle>
								<p>
									Nombre de suffrage(s) exprimé(s) :{" "}
									{vote.useVotePowers
										? vote.castedVotesWithPowers
										: vote.castedVotes}{" "}
									<br />
									Nombre de non voté(s) :{" "}
									{vote.useVotePowers
										? vote.expectedVotesWithPowers - vote.castedVotesWithPowers
										: vote.expectedVotes - vote.castedVotes}
								</p>
								{vote.voteType === "resolutionVote" && vote.requiredMajority ? (
									<p>
										Majorité (
										{vote.majoriteCalculResultat === "simple"
											? "simple"
											: "renforcée"}
										) requise : {vote.requiredMajority} suffrages
									</p>
								) : null}

								{vote.voteType === "resolutionVote" &&
									vote.voteResults.reduce(
										(acc, cur) => acc + (cur.count || 0),
										0
									) > 0 &&
									vote.showCalculResultat == 1 && (
										<div style={{ fontWeight: "500" }}>
											{vote.resolutionWord} :{" "}
											{vote.isResolutionAccepted ? (
												<WinnerText>Acceptation</WinnerText>
											) : (
												<span style={{ color: "red" }}>Rejet</span>
											)}
											.
										</div>
									)}

								{(vote.voteType === "oneRoundElection" ||
									vote.voteType === "twoRoundsElection") && (
									<div style={{ fontWeight: "500", textAlign: "center" }}>
										{showTwoRoundsElectionsResults(vote) ? (
											isTie(vote.voteResults) ? (
												<>
													<DrawText>
														Pas de majorité dégagée en raison d'une ou plusieurs
														égalité(s)
													</DrawText>
												</>
											) : (
												<>
													{voteWinners.length === 1 ? (
														<>
															{voteWinners.map((winner) => {
																return (
																	<WinnerText key={winner.optionId}>
																		{winner.optionName}
																	</WinnerText>
																);
															})}{" "}
															remporte le nombre le plus élevé de suffrages
															exprimés.
														</>
													) : voteWinners.length > 1 ? (
														<>
															Les candidats élus sont :
															{voteWinners.map((winner) => {
																return (
																	<WinnerText key={winner.optionId}>
																		{winner.optionName}
																	</WinnerText>
																);
															})}
														</>
													) : null}
												</>
											)
										) : vote.voteType === "oneRoundElection" ? (
											<DrawText>
												Pas de majorité dégagée en raison d'une ou plusieurs
												égalité(s)
											</DrawText>
										) : (
											<DrawText>
												Aucun candidat n'a été élu. Un deuxième tour doit être
												organisé.
											</DrawText>
										)}
									</div>
								)}
								{vote.resultGraphType === "table" ? (
									<table style={{ width: "100%" }}>
										<thead style={{ backgroundColor: "#bdc3c7" }}>
											<tr>
												<th>Option n°</th>
												<th>Libellé</th>
												<th>Nombre de voix</th>
											</tr>
										</thead>
										<tbody style={{ backgroundColor: "#ecf0f1" }}>
											{vote.voteResults.map((option, index) => (
												<tr key={option.optionId}>
													<td>
														{originalVoteOrder.findIndex(
															(x) => x === option.optionId
														) + 1}
													</td>
													<td>
														{option.optionType === "voteAbstain" &&
														vote.voteType !== "resolutionVote"
															? "Vote(s) blanc(s)"
															: option.optionName}
													</td>
													<td>
														{vote.resultDisplayType === "voteCount"
															? `${option.count || "0"}`
															: null}
														{vote.resultDisplayType === "votePercent"
															? `${computeVoteResultPercentage(
																	vote.voteResults,
																	option
															  )}%`
															: null}
														{vote.resultDisplayType === "both"
															? `${
																	option.count || "0"
															  } (${computeVoteResultPercentage(
																	vote.voteResults,
																	option
															  )}%)`
															: null}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								) : (
									<Chart
										chartType={vote.resultGraphType}
										displayType={vote.resultDisplayType}
										data={vote.voteResults.map((x) => x.count)}
										labels={vote.voteResults.map((x) =>
											x.optionType === "voteAbstain" &&
											vote.voteType !== "resolutionVote"
												? "Vote(s) blanc(s)"
												: x.optionName
										)}
										colors={vote.voteResults.map((x) => colors[x.optionType])}
									/>
								)}
							</ChartContainer>
						);
					})
				) : (
					<SmallTitle style={{ textAlign: "center" }}>
						La ressource demandée n'existe pas.
					</SmallTitle>
				)
			) : null}
		</>
	);
}
