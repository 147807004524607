import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  if (props.withTime) {
    let d = (field.value && new Date(field.value)) || null;
    if (d) {
      d.setMinutes(d.getMinutes());
    }
    return (
      <DatePicker
        {...field}
        {...props}
        selected={d}
        timeCaption="Heure"
        showTimeSelect
        timeIntervals={1}
        locale="fr"
        dateFormat="dd/MM/yyyy HH:mm"
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
    );
  } else {
    return (
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        locale="fr"
        dateFormat="dd/MM/yyyy"
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
    );
  }
};
