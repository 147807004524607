import React, { useContext, useEffect, useState } from "react";
import * as Auth from "../../utils/Auth";
import config from "../../config";
import useCse from "../../hooks/useCse";
import { Button, StyledA } from "../../utils/styled-components-library";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import { UserContext } from "../../App";
import API from "../../utils/API";
import CustomButton from "../../components/buttons/CustomButton";

function downloadBase64FileXLS(contentBase64, fileName) {
  const mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = mediaType;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
}

const CseLetter = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const user = useContext(UserContext);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"generer-courrier"} />
          <CseLayout title={"Générer les courriers"} showResults={true}>
            <>
              {(user.userType === "client" && (
                <>
                  <p>
                    Chaque génération de fichier attribue de nouveaux mots de
                    passe aux votants.
                  </p>
                  <StyledA
                    style={{ width: "100%" }}
                    href={`${
                      config.apiUrl
                    }/cseLetterCRUDPDF?path=generate-all&cseId=${
                      cse?.cseId
                    }&token=${Auth.getToken()}`}
                  >
                    <Button>Générer au format PDF</Button>
                  </StyledA>
                  <CustomButton
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      API.post(
                        "/cseRouteExport",
                        {
                          cseId,
                          module: "credentialLetterXLS",
                          path: "/xls",
                        },
                        (data: { success: boolean; data: any }) => {
                          downloadBase64FileXLS(
                            data.data.body,
                            "Login votants"
                          );
                        },
                        () => {}
                      );
                    }}
                  >
                    <>Générer au format XLS</>
                  </CustomButton>
                </>
              )) || <>Vous n'avez pas accès à cette page</>}
            </>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseLetter;
