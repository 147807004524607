import {
  Button,
  Label,
  Input,
  Error,
  MediumContainer,
  LargeContainer,
  SmallClientLogo,
} from "../utils/styled-components-library";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import API from "../utils/API";
import FileInput from "../components/FileInput";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import * as config from "../config";
import CustomButton from "../components/buttons/CustomButton";
import { toast } from "react-toastify";

const AdminLoginForm = ({
  setIsLogged,
}: {
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <h1>Connexion</h1>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={() => {
          return {};
        }}
        onSubmit={(values, { setErrors }) => {
          API.post(
            "/cseRouteCRUD",
            { ...values, path: "login", module: "dashboard" },
            (data: any) => {
              if (data.success) {
                setIsSubmitting(false);
                setIsLogged(true);
              } else if (data.error) {
                setIsSubmitting(false);
                setErrors({ [data.field]: data.error });
              }
            }
          );
        }}
      >
        {({}) => (
          <Form>
            <Label>Email</Label>
            <Input name="email" type="text" />
            <Error name="email" component="div" />
            <Label>Mot de passe</Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                name="password"
                type={showPassword ? "text" : "password"}
                style={{ marginRight: "15px" }}
              />
              <i
                className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                style={{
                  color: "#1D529C",
                  fontSize: "18px",
                  marginTop: -12,
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <Error name="password" component="div" />
            <Button type="submit" disabled={isSubmitting}>
              Se connecter
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

const CreateClientForm = ({ client, setRefresh, setShowList, refresh }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const isEdit = client ? true : false;

  return (
    <>
      <h1>{isEdit ? "Modification d'un client" :"Création d'un client"}</h1>
      <Formik
        initialValues={{
          clientName: client ? client.clientName : "",
          clientEmail: client ? client.clientEmail : "",
          clientPassword: client ? client.clientPassword : "",
          clientPhone: client ? client.clientPhone : "",
          clientLogoPath: client ? client.clientLogoPath : "",
          clientType: client ? client.clientType : "",
          clientMaxCse: client ? client.clientMaxCse : 0,
          clientMaxVotersPerCse: client ? client.clientMaxVotersPerCse : 0,
          image: "",
        }}
        validate={() => {
          return {};
        }}
        onSubmit={(values, { setErrors }) => {
          API.post(
            "/cseRouteCRUD",
            {
              ...values,
              path: isEdit ? "update" : "create",
              clientId: isEdit ? client.clientId : null,
              module: "dashboard",
            },
            (data: any) => {
              if (data.success) {
                setRefresh(refresh + 1);
                setShowList(true);
                toast.success(
                  isEdit ? "Modification effectuées" : "Création effectuées"
                );
                setIsSubmitting(false);
              }
            }
          );
        }}
      >
        {({ values }) => (
          <Form>
            <Label>Nom du client</Label>
            <Input name="clientName" type="text" />
            <Label>Email</Label>
            <Input name="clientEmail" type="text" />
            <Label>Mot de passe</Label>
            <Input name="clientPassword" type="text" />
            <Label>Téléphone</Label>
            <Input name="clientPhone" type="text" />
            <Label>Forme juridique</Label>
            <Input component="select" name="clientType">
              {[
                "Fédération",
                "Association",
                "Entreprise",
                "Institutionnel",
                "Autre",
              ].map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </Input>
            <Label>Logo</Label>
            <FileInput
              accept="image/*"
              name="image"
              filename={{
                key: "imageFilename",
                value: values.clientLogoPath,
              }}
              customOnChange={() => {
                setIsFileUploading(true);
              }}
              onLoadingComplete={() => {
                setIsFileUploading(false);
              }}
            />
            <Label>Nombre de cession CSE max</Label>
            <Input type="number" name="clientMaxCse" />
            <Label>Nombre de votants CSE max</Label>
            <Input type="number" name="clientMaxVotersPerCse" />
            <Button type="submit" disabled={isSubmitting || isFileUploading}>
              {isEdit ? "Modifier" : "Créer"}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

const BackOffice = () => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(undefined);
  const [clientsList, setClientsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showList, setShowList] = useState(true);

  const [refresh, setRefresh] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createClient = () => {
    setShowList(false);
    setClient(undefined);
  };

  const editClient = (client) => {
    setShowList(false);
    setClient(client);
  };

  useEffect(() => {
    API.post(
      "/cseRouteCRUD",
      { path: "clients", module: "dashboard" },
      (data: any) => {
        if (data.success) {
          setClients(data.clients);
          setClientsList(data.clients);
          setIsLoading(false);
        }
      }
    );
  }, [refresh]);

  return (
    <>
      {(clients && !isLoading && (
        <>
          <div
            style={{
              display: "flex",
              padding: 25,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>Back Office Provote CSE</h1>
            <div style={{ display: "flex", gap: 15 }}>
              {(!showList && (
                <CustomButton
                  style={{ width: "auto", height: "fit-content" }}
                  onClick={() => {
                    setShowList(true);
                  }}
                >
                  <>Retour</>
                </CustomButton>
              )) ||
                null}

              <CustomButton
                style={{ width: "auto", height: "fit-content" }}
                onClick={() => {
                  setRefresh(refresh + 1);
                }}
              >
                <>Actualiser</>
              </CustomButton>
              <CustomButton
                style={{ width: "auto", height: "fit-content" }}
                onClick={() => {
                  createClient();
                }}
              >
                <>Ajouter un client</>
              </CustomButton>
            </div>
          </div>
          {(showList && (
            <>
              <div>
                <Formik
                  initialValues={{
                    search: "",
                  }}
                  validator={() => ({})}
                >
                  {({ values }) => (
                    <Form
                      style={{ display: "flex", gap: 15, padding: "10px 25px" }}
                    >
                      <Input
                        type="text"
                        name="search"
                        placeholder="Recherche un nom"
                      />
                      <Button
                        type="submit"
                        style={{ width: "auto", height: "fit-content" }}
                        onClick={() => {
                          setClients(
                            clientsList.filter((x) => {
                              return x.clientName
                                .toLowerCase()
                                .includes(values.search.toLowerCase());
                            })
                          );
                        }}
                      >
                        Chercher
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead
                    style={{
                      background: "gainsboro",
                      boxShadow: "1px 1px black",
                    }}
                  >
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Nom</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Mot de passe</TableCell>
                      <TableCell>Téléphone</TableCell>
                      <TableCell>Forme Juridique</TableCell>
                      <TableCell>Logo</TableCell>
                      <TableCell>Nombre de session Cse max</TableCell>
                      <TableCell>Nombre de votants Cse max</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clients
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((client) => (
                        <TableRow key={client.id}>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={() => {
                                  editClient(client);
                                }}
                              >
                                <img
                                  style={{
                                    marginLeft: 5,
                                    objectFit: "contain",
                                    height: 20,
                                    cursor: "pointer",
                                  }}
                                  src={"/img/edit.svg"}
                                  alt="Modifier"
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {client.clientName}
                          </TableCell>
                          <TableCell>{client.clientEmail}</TableCell>
                          <TableCell
                            style={{ maxWidth: 50, overflow: "hidden" }}
                          >
                            {client.clientPassword}
                          </TableCell>
                          <TableCell>{client.clientPhone}</TableCell>
                          <TableCell>{client.clientType}</TableCell>
                          <TableCell>
                            <SmallClientLogo
                              src={config.s3BaseUrl + client.clientLogoPath}
                              style={{ maxHeight: 25 }}
                              alt="logo"
                            />
                          </TableCell>
                          <TableCell>{client.clientMaxCse}</TableCell>
                          <TableCell>{client.clientMaxVotersPerCse}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25]}
                  component="div"
                  count={clients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Clients par page"}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} Total : ${count} clients`
                  }
                />
              </TableContainer>
            </>
          )) || (
            <div
              style={{
                width: "90vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CreateClientForm client={client} setRefresh={setRefresh} setShowList={setShowList} refresh={refresh} />
            </div>
          )}
        </>
      )) || (
        <>
          <img src="/img/loading.gif" />
        </>
      )}
    </>
  );
};

const AdminCse = () => {
  const [isLogged, setIsLogged] = useState(false);

  return (
    <>
      {(!isLogged && (
        <MediumContainer>
          <AdminLoginForm setIsLogged={setIsLogged} />
        </MediumContainer>
      )) || (
        <div style={{ width: "100vw" }}>
          <BackOffice />
        </div>
      )}
    </>
  );
};

export default AdminCse;
