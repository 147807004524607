import React from "react";
import Helmet from "react-helmet";

const pageTitle = ({ title }) => {
	return (
		<Helmet>
			<title>{title || "ProVote"}</title>
		</Helmet>
	);
};

export default pageTitle;