import { useContext, useEffect } from "react";
import CseNavigation from "./CseNavigation";
import classes from "./CseHeader.module.scss";
import moment from "moment";
import { CseType_Db } from "../../types/cse";
import { UserContext } from "../../App";
import useCseSecondTurn from "../../hooks/useCseSecondTurn";
import Link from "../../components/links/Link";
import useCseFirstdTurn from "../../hooks/useCseFirstRound";

const CseHeader = ({
  selectedTab,
  cse,
}: {
  selectedTab: string;
  cse: CseType_Db;
}) => {
  const user = useContext(UserContext);
  const cseFirstTurn = useCseFirstdTurn(cse.cseParentId);
  const cseSecondTurn = useCseSecondTurn(cse.cseId);
  const cseTurn = cseFirstTurn ? 2 : 1;
  const cseRound = cseTurn === 2 ? "2nd tour" : "1er tour";
  const cseFirstTurnId = cseFirstTurn ? cseFirstTurn.cseId : cse.cseId;
  const cseSecondTurnId = cseTurn === 2 ? cse.cseId : cseSecondTurn?.cseId;
  const tabs = window.location.pathname.split("/");
  const tab = tabs[3] ? tabs[3] : "info";

  return (
    <>
      <h1 className={classes.title}>
        {cse.cseName} - {cseRound}
      </h1>
      {(cseFirstTurn || cseSecondTurn) && (
        <div>
          {cseTurn === 1 ? (
            <>
              <span>[1er tour]</span>
              <Link href={`/cse/${cseSecondTurnId}/reload/${tab}`}>
                2nd tour
              </Link>
            </>
          ) : (
            <>
              <Link href={`/cse/${cseFirstTurnId}/reload/${tab}`}>
                1er tour
              </Link>
              <span>[2nd tour]</span>
            </>
          )}
        </div>
      )}
      <p className={classes.name}>
        {moment(cse.cseEndDate).format("DD/MM/YYYY")}{" "}
      </p>
      {user.userType !== "cseVoter" && (
        <>
          <CseNavigation selectedTab={selectedTab} cseId={cse.cseId} />
        </>
      )}
    </>
  );
};

export default CseHeader;
