import React, { useContext, useEffect, useState } from "react";
import * as Auth from "../../utils/Auth";
import config from "../../config";
import useCse from "../../hooks/useCse";
import {
  Button,
  Input,
  Label,
  StyledA,
} from "../../utils/styled-components-library";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import { UserContext } from "../../App";
import API from "../../utils/API";
import CustomButton from "../../components/buttons/CustomButton";
import { Formik, Form } from "formik";
import moment from "moment";
import { DatePickerField } from "../../components/DatePicker";

const CerfaForm = ({ cseId }: { cseId: number | undefined }) => {
  const [isSubmitting, setIsSubmmitting] = useState(false);

  const initialValues = {
    siret: "",
    social: "",
    address: "",
    zip: "",
    city: "",
    idcc: "",
    mandate: "",
    mandateDuration: "",
    mandateDurationUnit: "year",
    collegeNumber: "",
    lastElection: "",
    lastSiret: "",
    isPartial: "false",
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={() => {
        return {};
      }}
      onSubmit={(values) => {
        const data = { ...values };
        data.lastElection = moment.utc(data.lastElection).isValid()
          ? moment
              .utc(data.lastElection)
              .tz("Europe/Paris")
              .format("YYYY-MM-DD")
          : "";
      }}
    >
      {({}) => (
        <Form>
          <Label>SIRET</Label>
          <Input name="siret" type="number" max="99999999999999" />
          <Label>Raison sociale</Label>
          <Input name="social" type="text" />
          <Label>Adresse</Label>
          <Input name="address" type="text" />
          <Label>Code postal</Label>
          <Input name="zip" type="number" />
          <Label>Ville</Label>
          <Input name="city" type="text" />
          <Label>Numéro de convention collective (IDCC)</Label>
          <Input name="idcc" type="number" max="9999" />
          <Label>Durée du mandat</Label>
          <div style={{ display: "flex", gap: 15 }}>
            <Input name="mandateDuration" type="number" min="0" />
            <Input component="select" name="mandateDurationUnit">
              {[
                { value: "year", display: "Année" },
                { value: "month", display: "Mois" },
              ].map((x) => (
                <option key={x.display} value={x.value}>
                  {x.display}
                </option>
              ))}
            </Input>
          </div>
          <Label>S’agit-il d’une élection partielle</Label>
          <Input component="select" name="isPartial">
            {[
              { value: "false", display: "Non" },
              { value: "true", display: "Oui" },
            ].map((x) => (
              <option key={x.display} value={x.value}>
                {x.display}
              </option>
            ))}
          </Input>
          <Label>Nombre de collège électoraux que comporte le CSE</Label>
          <Input name="collegeNumber" type="number" min="0" />
          <Label>Date du précédent scrutin</Label>
          <DatePickerField name="lastElection" />
          <Label>
            SIRET déclaré lors du précédent scrutin (laissez vide si identique)
          </Label>
          <Input name="lastSiret" type="number" max="99999999999999" />

          <Button type="submit" disabled={isSubmitting}>
            Valider
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const CseEnterpriseInfo = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const user = useContext(UserContext);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"info-entreprise"} />
          <CseLayout title={"Informations de l'entreprise"} showResults={true}>
            <>
              {(user.userType === "client" && (
                <>
                  <CerfaForm cseId={cseId} />
                </>
              )) || <>Vous n'avez pas accès à cette page</>}
            </>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseEnterpriseInfo;
