import React, { useContext } from "react";
import CustomButton from "../../components/buttons/CustomButton";
import classes from "./CseLayout.module.scss";
import CseNoResult from "./CseNoResult";
import Loader from "react-loader-spinner";
import { UserContext } from "../../App";

const CseLayout = ({
  children = <></>,
  NoResultText = "",
  buttonText = "",
  href = "",
  target = "_self",
  visible = false,
  rel = "alternate",
  showResults = false,
  loading = false,
  title = "",
  alwaysShowChildren = false,
  onClick = () => {},
  additionalActions = <></>,
  subTitleAction = <></>,
}) => {
  const user = useContext(UserContext);

  return (
    <div className={classes.CseLayout}>
      <section className={classes.mainContainer2}>
        <section className={classes.titleContainer}>
          <div>
            <p className={classes.title}>{title}</p>
            {subTitleAction}
          </div>

          {(buttonText || visible === true) &&
            (user.userType === "client" ||
              user.userType === "clientAdmin" ||
              visible === true) && (
              <div className={classes.buttonWrapper}>
                {additionalActions}
                {buttonText && (
                  <CustomButton
                    width={{ width: "auto" }}
                    onClick={onClick}
                    href={href}
                    target={target}
                    rel={rel}
                  >
                    <>{buttonText}</>
                  </CustomButton>
                )}
              </div>
            )}
        </section>
        {loading && (
          <Loader type="Bars" color="#1d529c" height={35} width={35} />
        )}
        {!loading && (
          <>
            {showResults ? children : <CseNoResult text={NoResultText} />}
            {!showResults && alwaysShowChildren && children}
          </>
        )}
      </section>
    </div>
  );
};

export default CseLayout;
