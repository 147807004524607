import React, { useState } from "react";
import useCse from "../../hooks/useCse";
import {
  MediumContainer,
  Modal,
  ModalClose,
  ModalOverlay,
  SmallContainer,
} from "../../utils/styled-components-library";
import config from "../../config";
import styled from "styled-components";
import BackToVote from "../../components/BackTovote";

const Item = styled.div`
  cursor: pointer;
  color: white;
  background: #1d529c;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  box-shadow: 5px 6px 15px -7px #000000;
`;

const SectionItem = styled.section`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

const CseDocuments = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [openRules, setOpenRules] = useState(false);
  const [openDG, setOpenDG] = useState(false);
  const [openRH, setOpenRH] = useState(false);

  return (
    <section>
      <BackToVote />

      <SmallContainer>
        <h1>Documents mis à disposition par la Direction:</h1>
        <div style={{ marginTop: 35 }}>
          {cse && cse?.votingRulesPdf && (
            <SectionItem>
              <Item
                onClick={() => {
                  setOpenRules(!openRules);
                }}
              >
                Modalités de vote
              </Item>
              {openRules && (
                <ModalOverlay
                  onClick={() => {
                    setOpenRules(false);
                  }}
                >
                  <Modal style={{ maxWidth: 600 }}>
                    <ModalClose
                      onClick={() => {
                        setOpenRules(false);
                      }}
                    />
                    <h2>Modalités de vote</h2>
                    <object
                      data={config.s3BaseUrl + cse?.votingRulesPdf}
                      style={{ height: "100vh" }}
                      width="100%"
                      type="application/pdf"
                    ></object>
                  </Modal>
                </ModalOverlay>
              )}
            </SectionItem>
          )}
          {cse && cse.dgVideo && (
            <SectionItem>
              <Item
                onClick={() => {
                  setOpenDG(!openDG);
                }}
              >
                Vidéo de la direction générale
              </Item>
              {openDG && (
                <ModalOverlay
                  onClick={() => {
                    setOpenDG(false);
                  }}
                >
                  <Modal style={{ maxWidth: 600 }}>
                    <ModalClose
                      onClick={() => {
                        setOpenDG(false);
                      }}
                    />
                    <h2>Vidéo de la direction générale</h2>
                    <video
                      width="100%"
                      src={config.s3BaseUrl + cse.dgVideo}
                      controls
                    />
                  </Modal>
                </ModalOverlay>
              )}
            </SectionItem>
          )}

          {cse && cse.hrVideo && (
            <SectionItem>
              <Item
                onClick={() => {
                  setOpenRH(!openRH);
                }}
              >
                Vidéo des ressources humaines
              </Item>

              {openRH && (
                <ModalOverlay
                  onClick={() => {
                    setOpenRH(false);
                  }}
                >
                  <Modal style={{ maxWidth: 600 }}>
                    <ModalClose
                      onClick={() => {
                        setOpenRH(false);
                      }}
                    />
                    <h2> Vidéo des ressources humaines</h2>
                    <video
                      width="100%"
                      src={config.s3BaseUrl + cse.hrVideo}
                      controls
                    />
                  </Modal>
                </ModalOverlay>
              )}
            </SectionItem>
          )}
        </div>
      </SmallContainer>
    </section>
  );
};

export default CseDocuments;
