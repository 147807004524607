import "moment/locale/fr";
const moment = require("moment-timezone");
moment.locale("fr");
moment.tz.setDefault("Europe/Paris");

export const formatTimeWithMinutes = (date: string) =>
  // moment(date).format("DD/MM/YYYY [à] HH[h]MM");
  moment.utc(date).tz("Europe/Paris").format("DD/MM/YYYY [à] HH[h]mm");

export const timeBetween = (d1: string | undefined, d2: string | undefined) => {
  var start = moment.utc(d1).tz("Europe/Paris");
  var end = moment.utc(d2).tz("Europe/Paris");

  var duration = moment.duration(end.diff(start));

  //Get Days and subtract from duration
  var days = Math.floor(duration.asDays());
  duration.subtract(moment.duration(days, "days"));

  //Get hours and subtract from duration
  var hours = Math.floor(duration.hours());
  duration.subtract(moment.duration(hours, "hours"));

  //Get Minutes and subtract from duration
  var minutes = Math.floor(duration.minutes()) + 1;
  duration.subtract(moment.duration(minutes, "minutes"));

  if (minutes === 60) {
    minutes = 0;
    hours = hours + 1;
    if (hours === 24) {
      hours = 0;
      days = days + 1;
    }
  }
  //Get seconds
  var seconds = duration.seconds();
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const dateFromNow = (date: string) => {
  return timeBetween(undefined, date);
};

export const timeNow = () => moment.utc().tz("Europe/Paris");
export const timeNowUtc = () => moment.utc();

export const timeIsBefore = (d1: string, d2: string) =>
  !moment(d2).isBefore(moment(d1));
