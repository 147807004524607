import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import config from "../config";
import classes from "./CseLogin.module.scss";
import {
  Button,
  Label,
  Input,
  Error,
  SmallContainer,
  Title,
  ModalOverlay,
  Modal,
  ModalClose,
} from "../utils/styled-components-library";
import API from "../utils/API";
import * as Auth from "../utils/Auth";
import { withRouter, useHistory } from "react-router-dom";
import styled from "styled-components";

const ForgotPassword = styled.span`
  color: #1d529c;
  margin: 10px 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const CseLogin = ({ match, onSuccess }) => {
  const [cse, setCse] = useState({});
  const [voter, setVoter] = useState({});

  const [showEmailPassword, setShowEmailPassword] = useState(false);
  const [showSmsPassword, setShowSmsPassword] = useState(false);
  const [showLetterPassword, setShowLetterPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showPasswordResetQueried, setShowPasswordResetQueried] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  useEffect(() => {
    API.post("/getCseByVoterId", { identifier: match.params.code }, (data) => {
      if (data.success) {
        setCse(data.data.cse);
        setVoter(data.data.voter);
      } else if (data.error) {
        history.push("/");
      }
    });
  }, []);

  const objectToConsiderForCredentialsLogic = voter.hasOwnCredentialsLogic
      ? voter
      : cse;

  console.log(cse);

  return (
    <>
      <Title className={classes.CseTitle}>
        Bienvenue dans la session de vote
        <div className={classes.CseHeaderName}>{cse.cseName} </div>
      </Title>

      <div className={classes.CseContainer}>
        <div className={classes.CseLogin}>
          <div className={classes.CseLoginHeadText}>
            Renseignez vos identifiants ci-dessous puis cliquez sur{" "}
            <span className={classes.CseLoginBold}>connexion</span>
          </div>
          <Formik
            initialValues={{
              emailPassword: "",
              smsPassword: "",
              letterPassword: "",
              challenge: "",
            }}
            validate={(values) => {
              return {};
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              API.post(
                "/cseRouteCRUD",
                {
                  ...values,
                  identifier: match.params.code,
                  module: "cseLoginVoter",
                },
                (data) => {
                  setSubmitting(false);
                  if (data.success) {
                    Auth.setToken(data.token);
                    history.push("/");
                    onSuccess(data.user);
                  } else {
                    if (data.error) {
                      setErrors({ smsPassword: data.error });
                    } else {
                      setErrors({
                        smsPassword: `Au moins un mot de passe ${
                          cse?.isChallengeCredential ? "et/ou votre défi" : ""
                        } est incorrect`,
                      });
                    }
                  }
                }
              );
            }}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                {!!objectToConsiderForCredentialsLogic?.isEmailCredential && (
                  <>
                    <Label>
                      Mot de passe reçu par email ({voter?.cseVoterEmail})
                    </Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        name="emailPassword"
                        type={showEmailPassword ? "text" : "password"}
                        style={{ marginRight: "15px" }}
                      />
                      <i
                        className={
                          !showEmailPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        style={{
                          color: "#1D529C",
                          fontSize: "18px",
                          marginTop: -12,
                          cursor: "pointer",
                        }}
                        onClick={() => setShowEmailPassword(!showEmailPassword)}
                      />
                    </div>
                  </>
                )}
                {!!objectToConsiderForCredentialsLogic?.isSmsCredential && (
                  <>
                    <Label>
                      Mot de passe recu par SMS (
                      {"0" + voter?.cseVoterPhone?.slice(2)})
                    </Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        name="smsPassword"
                        type={showSmsPassword ? "text" : "password"}
                        style={{ marginRight: "15px" }}
                      />
                      <i
                        className={
                          !showSmsPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        style={{
                          color: "#1D529C",
                          fontSize: "18px",
                          marginTop: -12,
                          cursor: "pointer",
                        }}
                        onClick={() => setShowSmsPassword(!showSmsPassword)}
                      />
                    </div>
                  </>
                )}

                {!!objectToConsiderForCredentialsLogic?.isLetterCredential && (
                  <>
                    <Label>Mot de passe recu par courrier</Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        name="letterPassword"
                        type={showLetterPassword ? "text" : "password"}
                        style={{ marginRight: "15px" }}
                      />
                      <i
                        className={
                          !showLetterPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        style={{
                          color: "#1D529C",
                          fontSize: "18px",
                          marginTop: -12,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setShowLetterPassword(!showLetterPassword)
                        }
                      />
                    </div>
                  </>
                )}

                {!!cse?.isChallengeCredential && (
                  <>
                    <Label>{cse?.challengeName}</Label>
                    <Input name="challenge" type={"text"} />
                    <Error name="challenge" component="div" />
                  </>
                )}

                <Error name="smsPassword" component="div" />

                <ForgotPassword
                  onClick={() => {
                    setShowResetPassword(true);
                    setShowPasswordResetQueried(false);
                  }}
                >
                  Vous souhaitez regénérer vos codes d'authentification ?
                </ForgotPassword>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.CseConnexionButton}
                >
                  Connexion
                </Button>
              </Form>
            )}
          </Formik>
        </div>

        <div className={classes.CseLoginInfoContainer}>
          <div className={classes.CseLoginInfo}>
            <div className={classes.CseLoginInfoTitle}>
              <img
                className={classes.ListIcon}
                src="/img/password-lock.svg"
                alt="mot de passe"
              />
              Pour voter, vous avez besoin de
            </div>
            <div className={classes.CseLoginInfoList}>
              <ol>
                <li>Votre code électeur</li>
                <li>Vos mots de passe obtenus par mail / SMS / courrier</li>
              </ol>
              Si vous souhaitez recevoir à nouveau ces codes,{" "}
              <ForgotPassword
                onClick={() => {
                  setShowResetPassword(true);
                  setShowPasswordResetQueried(false);
                }}
              >
                cliquez ici
              </ForgotPassword>
            </div>
          </div>
          {((cse.phoneHelp || cse.emailHelp) && (
            <div className={classes.CseLoginInfo}>
              <div className={classes.CseLoginInfoTitle}>
                <img
                  className={classes.ListIcon}
                  src="/img/help.svg"
                  alt="mot de passe"
                />
                obtenir de l'aide
              </div>
              <div className={classes.CseLoginInfoList}>
                Vous avez des questions ? Besoin d’aide ?
                <div className={classes.CseLoginInfoList}>
                  Une équipe est à votre disposition afin de vous aider pour
                  voter. Retrouvez le bouton d’assistance en haut de votre
                  écran.
                </div>
                {(cse.phoneHelp && (
                  <>
                    <div>
                      <img
                        src="/img/phone-circle.svg"
                        style={{ margin: "-2px 5px" }}
                      />
                      Assistance téléphonique : <strong>01 45 50 29 48</strong>{" "}
                      <div>
                        {(cse.phoneHelpMinHours === 24 &&
                          cse.phoneHelpMaxHours === 24 && (
                            <>
                              Disponible <strong>24h/24</strong>.
                            </>
                          )) || (
                          <>
                            Disponible de{" "}
                            <strong>{cse.phoneHelpMinHours}h</strong> à{" "}
                            <strong>{cse.phoneHelpMaxHours}h</strong>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )) ||
                  null}
                {(cse.emailHelp && (
                  <div>
                    <img
                      src="/img/read-email-circle.svg"
                      style={{ margin: "-2px 5px" }}
                    />
                    Assistance eMail :{" "}
                    <strong>
                      <a href="mailto:assistance@media-vote.com">
                        assistance@media-vote.com
                      </a>
                    </strong>
                  </div>
                )) ||
                  null}
              </div>
            </div>
          )) ||
            null}
        </div>
      </div>

      {showResetPassword && (
        <ModalOverlay
          lgModal
          onClick={() => {
            window.location.hash = "";
            setShowResetPassword(false);
          }}
        >
          <Modal onClick={(e) => e.stopPropagation()}>
            <ModalClose
              onClick={() => {
                window.location.hash = "";
                setShowResetPassword(false);
              }}
            />
            {showPasswordResetQueried ? (
              <>
                <h4>
                  Votre demande de réinitialisation de mots de passe a été
                  envoyée.
                </h4>
                <Button
                  onClick={() => {
                    setShowResetPassword(false);
                  }}
                >
                  Fermer
                </Button>
              </>
            ) : (
              <>
                <h4>Vous avez oublié l'un de vos mots de passe ?</h4>
                {[objectToConsiderForCredentialsLogic?.isEmailCredential || objectToConsiderForCredentialsLogic?.isSmsCredential].some(
                  (elt) => elt
                ) && (
                  <>
                    <p>
                      En cliquant sur le bouton <strong>Réinitialiser</strong>{" "}
                      vous receverez dans les minutes qui suivent :
                    </p>
                    <ul>
                      {!!objectToConsiderForCredentialsLogic?.isEmailCredential && (
                        <li style={{ textAlign: "left" }}>
                          Un mot nouveau de passe par email (
                          {voter?.cseVoterEmail}){" "}
                        </li>
                      )}
                      {!!objectToConsiderForCredentialsLogic?.isSmsCredential && (
                        <li style={{ textAlign: "left", marginTop: 5 }}>
                          Un mot nouveau de passe par SMS (
                          {"0" + voter?.cseVoterPhone?.slice(2)})
                        </li>
                      )}
                    </ul>
                  </>
                )}
                {[objectToConsiderForCredentialsLogic?.isLetterCredential || cse?.isChallengeCredential].some(
                  (elt) => elt
                ) && (
                  <>
                    <p>
                      Veuillez contacter votre administrateur si votre demande
                      concerne
                    </p>
                    <ul>
                      {!!objectToConsiderForCredentialsLogic?.isLetterCredential && (
                        <li style={{ textAlign: "left" }}>
                          Votre mot de passe reçu par courrier
                        </li>
                      )}
                      {!!cse?.isChallengeCredential && (
                        <li style={{ textAlign: "left", marginTop: 5 }}>
                          Votre {cse?.challengeName}
                        </li>
                      )}
                    </ul>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsSubmitting(true);
                      API.post(
                        "/resendCseCredentials",
                        { identifier: match.params.code },
                        (data) => {
                          setIsSubmitting(false);
                          if (data.success) {
                            setShowPasswordResetQueried(true);
                          }
                        }
                      );
                    }}
                    disabled={isSubmitting}
                    style={{ marginRight: "10px" }}
                  >
                    Réinitialiser
                  </Button>
                  <Button
                    white
                    onClick={() => {
                      setShowResetPassword(false);
                    }}
                  >
                    Annuler
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
};

export default withRouter(CseLogin);
