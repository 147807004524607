import React from "react";
import API from "../utils/API";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import config from "../config";
import { LargeContainer } from "../utils/styled-components-library";
import * as Auth from "../utils/Auth";
import { Button } from "@material-ui/core";
import { ProVoteMainTheme } from "../utils/muiTheme";
import { ThemeProvider } from "@material-ui/core/styles";

const Table = styled.table`
  width: 100%;
  border-radius: 5px;
  border-collapse: collapse;
  margin-bottom: 10px;
`;

const Row = styled.tr``;

const RowCellHeader = styled.th`
  border: 1px #ddd solid;
  padding: 5px 0px;
`;

const RowCell = styled.td`
  border: 1px #ddd solid;
  padding: 8px;
`;

class BrowseVotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myVotes: [],
      delegatedVoters: [],
    };
  }

  componentDidMount() {
    this.fetchVotes();
  }

  fetchVotes() {
    API.get(
      "/voteParams",
      {
        myVotes: true,
      },
      (data) => {
        this.setState({
					myVotes: data.votes,
					delegatedVoters: data.delegatedVoters,
				});
      }
    );
  }

  onPrintButtonClickedHandler = () => {
    API.get(
      "/voteParams",
      {
        myVotes: true,
        generatePDF: true,
      },
      (data) => {
        const newWindow = window.open();
        newWindow.document.write(data.html);
      }
    );
  };

  render() {
    return (
      <LargeContainer className="printableContainer">
        <Table>
          <thead>
            <tr>
              <RowCellHeader>Vote</RowCellHeader>
              <RowCellHeader>Votre vote</RowCellHeader>
              {this.state.delegatedVoters.length !== 0 &&
                this.state.delegatedVoters.map((delegatedVoter) => {
                  return (
                    <RowCellHeader
                      key={delegatedVoter.voterId}
                      style={{ padding: "5px 15px" }}
                    >
                      Vote de {delegatedVoter.voterLastname}{" "}
                      {delegatedVoter.voterFirstname}
                    </RowCellHeader>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {this.state.myVotes.map((vote) => (
              <Row key={vote.voteId}>
                <RowCell>{vote.name}</RowCell>
                <RowCell>
                  {vote.options.map((x) => x.optionName).join(", ")}
                </RowCell>
                {this.state.delegatedVoters.length !== 0 &&
                  this.state.delegatedVoters.map((delegatedVoter) => {
                    return (
                      <RowCell key={delegatedVoter.voterId}>
                        {vote.delegatedVotes
                          .find(
                            (delegatedVote) =>
                              delegatedVote.voterId == delegatedVoter.voterId
                          )
                          .options.map((x) => x.optionName)
                          .join(", ")}
                      </RowCell>
                    );
                  })}
              </Row>
            ))}
          </tbody>
        </Table>
        <ThemeProvider theme={ProVoteMainTheme}>
          <Button
            href={`${
              config.apiUrl
            }/voteParams?myVotes=true&generatePDF=true&token=${Auth.getToken()}`}
            target="_blank"
            className="hideOnPrint"
            color={"primary"}
            variant={"contained"}
            style={{ display: "flex" }}
          >
            <i className="far fa-print" style={{ marginRight: 10 }} /> Imprimer
          </Button>
        </ThemeProvider>
      </LargeContainer>
    );
  }
}

export default withRouter(BrowseVotes);
