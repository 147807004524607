import React, { useEffect, useState } from "react";
import CustomButton from "../../components/buttons/CustomButton";
import useCse from "../../hooks/useCse";
import API from "../../utils/API";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import moment from "moment-timezone";
import { toast } from "react-toastify";

const CseSecurity = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"securite"} />
          <CseLayout
            title={"Sécurité"}
            loading={isLoading}
            NoResultText={""}
            buttonText={""}
            href={`#`}
            showResults
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "inherit" }}>
                CSE : {cse.isIntegrated ? "sécurisé" : "non sécurisé"}
              </div>

              <CustomButton
                disabled={
                  moment.utc(cse.cseStartDate).tz("Europe/Paris") >
                    moment.utc().tz("Europe/Paris") || isSubmitting
                }
                onClick={() => {
                  setIsSubmitting(true);
                  API.post(
                    "/cseRouteCRUD",
                    {
                      cseId: cse.cseId,
                      path: "/check",
                      module: "security",
                    },
                    (data) => {
                      setIsSubmitting(false);
                      if (data.data === true) {
                        toast.success("Le CSE est sécurisé");
                      } else {
                        toast.error("Le CSE n'est plus sécurisé");
                      }
                    },
                    () => {
                      setIsSubmitting(false);
                    }
                  );
                }}
              >
                <>Tester l'intégrité</>
              </CustomButton>
            </div>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseSecurity;
