import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { Button } from "../utils/styled-components-library";

const PresidentAccessContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
`;

function PresidentAccessTable({ presidentAccess, deletePresidentAccess }) {
	return (
		<PresidentAccessContainer>
			<TableContainer component={Paper}>
				<Table aria-label="Accès président">
					<TableBody>
						<TableRow>
							<TableCell component="td" scope="row">
								<b>Identifiant</b>
							</TableCell>
							<TableCell align="left">
								{presidentAccess.presidentEmail}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell component="td" scope="row">
								<b>Mot de passe</b>
							</TableCell>
							<TableCell align="left">
								{presidentAccess.presidentPassword}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				style={{ backgroundColor: "red", maxWidth: "100px", marginLeft: 50 }}
				onClick={() => deletePresidentAccess(presidentAccess)}
			>
				Supprimer
			</Button>
		</PresidentAccessContainer>
	);
}

export default PresidentAccessTable;
