import React, { useState } from "react";
import { useEffect } from "react";
import CseResultsAfterStripping from "./CseResults";

const CseResultsDisplay = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const cseId = searchParams.get("cseId");
  const match = {
    params: {
      cseId: cseId,
    },
  };
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    console.log("LOADED :", loaded);
  }, []);

  return (
    <React.StrictMode>
      <div style={{ zoom: 0.15 }}>
        <>
          <div
            style={{
              fontSize: "35px",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            Résultats du CSE
          </div>
          <CseResultsAfterStripping
            match={match}
            printing
            setLoaded={setLoaded}
          />
        </>
      </div>
    </React.StrictMode>
  );
};

export default CseResultsDisplay;
