import React from "react";
import TitleComponent from "../components/TitleComponent";
import PageNotFound from "../screens/PageNotFound";
import { Route, Switch } from "react-router-dom";
import CseVoterLanding from "../screens/Cse/CseVoterLanding";
import CseVote from "../screens/Cse/cseVote/CseVote";
import CsePollingStation from "../screens/Cse/CsePollingStation";
import CseResults from "../screens/Cse/CseResults";
import CseAccess from "../screens/Cse/CseAccess";
import CseElectionList from "../screens/Cse/CseElectionList";
import CseDocuments from "../screens/Cse/CseDocuments";
import CseReload from "../screens/Cse/CseReload";

class CseVoterNavigator extends React.Component {
  render() {
    return (
      <>
        <TitleComponent title="ProVote - Votant" />
        <Switch>
          <Route
            exact
            path="/"
            component={(props) => (
              <CseVoterLanding user={this.props.user} {...props} />
            )}
          />
          <Route
            exact
            path="/vote"
            component={(props) => <CseVote user={this.props.user} {...props} />}
          />
          <Route exacte path="/cse/:cseId/documents" component={CseDocuments} />
          <Route
            exact
            path="/cse/:cseId/bureaux-de-vote"
            component={CsePollingStation}
          />
          <Route
            exact
            path="/cse/:cseId/acces-autorises"
            component={CseAccess}
          />
          <Route exact path="/cse/:cseId/listes" component={CseElectionList} />
          <Route exact path="/cse/:cseId/resultats" component={CseResults} />
          <Route exact path="/cse/:cseId/reload/:tab" component={CseReload} />

          <Route component={PageNotFound} />
        </Switch>
      </>
    );
  }
}

export default CseVoterNavigator;
