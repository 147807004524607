import React from "react";
import styled from "styled-components";
import {
	SmallContainer
} from "../utils/styled-components-library";
import moment from "moment";
import "moment/locale/fr";

const LoadingMessageContainer = styled.div`
	font-size: 18px;
	font-weight: 500;
`;

const AGBeforeStarting = ({ ag }) => {
	return (
		<LoadingMessageContainer>
			<SmallContainer>
				<p>Bonjour, vous êtes bien sur ProVote.</p>
				<p>
					Votre session de vote {ag !== null && ag.agName} ouvrira le{" "}
					{ag !== null &&
						moment(ag.agStartDate).format("dddd D MMMM YYYY à HH:mm.")}
				</p>

				<p>
					Dans l'attente de voter, vous pouvez consulter le menu en cliquant sur
					votre nom en haut à droite
				</p>
			</SmallContainer>
		</LoadingMessageContainer>
	);
};

export default AGBeforeStarting;
