import React from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Label,
  Input,
  Error,
  SmallContainer,
  Title,
  ModalOverlay,
  Modal,
  ModalClose,
  MediumContainer,
} from "../utils/styled-components-library";
import API from "../utils/API";
import * as Auth from "../utils/Auth";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const ForgotPassword = styled.div`
  color: #1d529c;
  margin: 10px 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const LoginDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const FormDiv = styled.div`
  padding: 20px;
  border-radius: 15px;
  width: 320px;

  @media (max-width: 500px) {
    width: auto;
  }
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showModalForgotPassword: false,
      showModalForgotCode: false,
      passwordResetQueried: false,
      isAdmin: false,
      cses: [],
      isEmailSubmitting: false,
      voterEmail: "",
    };
    this.mailForm = () => (
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={(values) => {
          return {};
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          API.post("/login", values, (data) => {
            setSubmitting(false);
            if (data.success) {
              Auth.setToken(data.token);
              this.props.history.push("/");
              this.props.onSuccess(data.user);
            } else if (data.error) {
              setErrors({ [data.field]: data.error });
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Label>Identifiant</Label>
            <Input name="email" type="text" />
            <Error name="email" component="div" />
            <Label>Mot de passe</Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                name="password"
                type={this.state.showPassword ? "text" : "password"}
                style={{ marginRight: "15px" }}
              />
              <i
                className={
                  !this.state.showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                }
                style={{
                  color: "#1D529C",
                  fontSize: "18px",
                  marginTop: -12,
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.setState({
                    showPassword: !this.state.showPassword,
                  })
                }
              />
            </div>
            <Error name="password" component="div" />
            <Button type="submit" disabled={isSubmitting}>
              Se connecter
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    return (
      <>
        <MediumContainer>
          <Title>Connexion à ProVote</Title>
          {}
          {this.state.isAdmin && this.mailForm()}
          {!this.state.isAdmin && (
            <>
              <div
                style={{
                  marginBottom: 15,
                  fontStyle: "italic",
                  fontSize: 18,
                }}
              >
                Je suis <strong>électeur</strong>, je vote, je m'identifie
                ci-dessous
              </div>
              <LoginDiv>
                <div>
                  <img
                    style={{
                      objectFit: "contain",
                    }}
                    src={"/img/vote-picto.png"}
                    alt="people"
                  />
                </div>

                <div>
                  <FormDiv
                    style={{
                      backgroundColor: "rgb(141,198,237, 0.23)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textTransform: "uppercase",
                          fontSize: 20,
                        }}
                      >
                        <p> Je vote - Accès électeur </p>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        identifier: "",
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.identifier) {
                          errors.identifier = "Requis";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, setErrors }) => {
                        API.post("/identifyVoter", values, (data) => {
                          setSubmitting(false);
                          if (data.success) {
                            this.props.history.push(
                              "/votant/" + values.identifier
                            );
                          } else if (data.message) {
                            setErrors({ identifier: data.message });
                          }
                        });
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <Label>Code électeur</Label>
                          <Input name="identifier" type="identifier" />
                          <Error name="identifier" component="div" />
                          <Error name="password" component="div" />
                          <Button type="submit" disabled={isSubmitting}>
                            S'identifier
                          </Button>
                        </Form>
                      )}
                    </Formik>
                    <ForgotPassword
                      onClick={() =>
                        this.setState({
                          showModalForgotCode: true,
                          passwordResetQueried: false,
                        })
                      }
                    >
                      Code électeur perdu ?
                    </ForgotPassword>
                  </FormDiv>
                </div>
              </LoginDiv>
              <hr style={{ marginTop: 25, marginBottom: 25 }} />

              <div
                style={{ marginBottom: 15, fontStyle: "italic", fontSize: 18 }}
              >
                Je suis <strong>membre du bureau de vote</strong>, je
                m'identifie ci-dessous
              </div>
              <LoginDiv>
                <div>
                  <img
                    style={{
                      objectFit: "contain",
                      width: 97,
                      height: 97,
                    }}
                    src={"/img/polling-picto.png"}
                    alt="people"
                  />
                </div>
                <div>
                  <FormDiv
                    style={{
                      backgroundColor: "#fcece0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "red",
                        fontWeight: "bold",
                        marginBottom: 20,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textTransform: "uppercase",
                          fontSize: 20,
                        }}
                      >
                        <p>Accès bureau de vote</p>
                      </div>
                    </div>

                    {this.mailForm()}
                    <ForgotPassword
                      onClick={() =>
                        this.setState({
                          showModalForgotPassword: true,
                          passwordResetQueried: false,
                        })
                      }
                    >
                      Mot de passe oublié ?
                    </ForgotPassword>
                  </FormDiv>
                </div>
              </LoginDiv>
            </>
          )}

          <hr style={{ marginTop: 25, marginBottom: 25 }} />
          <SmallContainer>
            <div style={{ marginTop: 20, fontSize: 13 }}>
              ProVote® by Mediavote
              <br />
              Développement 100% français
              <br />
              <a href="https://www.media-vote.com/">www.media-vote.com</a>
              <img
                src="/img/home.png"
                style={{ width: "100%", marginTop: 20 }}
              />
            </div>
          </SmallContainer>
        </MediumContainer>

        {this.state.showModalForgotCode && (
          <ModalOverlay
            onClick={() => {
              window.location.hash = "";
              this.setState({ showModalForgotCode: false });
            }}
          >
            <Modal onClick={(e) => e.stopPropagation()}>
              <ModalClose
                onClick={() => {
                  window.location.hash = "";
                  this.setState({ showModalForgotCode: false });
                }}
              />
              {this.state.passwordResetQueried ? (
                <>
                  <h4>
                    Votre demande de réinitialisation de code électeur a été
                    envoyée.
                  </h4>
                  <Button
                    onClick={() => {
                      this.setState({ showModalForgotCode: false });
                    }}
                  >
                    Fermer
                  </Button>
                </>
              ) : (
                <>
                  <h4>Vous avez oublié votre code électeur ?</h4>
                  <p>
                    Nous allons vous assister dans sa récupération. Veuillez
                    entrer l'adresse mail liée à votre compte puis cliquer sur
                    "Envoyer". Selon le mode d'envoi défini par votre session de
                    vote, vous recevrez votre code électeur par email ou SMS
                    dans les prochaines minutes (si nous possédons votre email
                    dans notre base de donnée).
                  </p>

                  {(!this.state.isEmailSubmitting && (
                    <Formik
                      initialValues={{ email: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = "Veuillez entrer votre adresse email.";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, setErrors }) => {
                        setSubmitting(true);
                        API.post(
                          "/cseRouteCRUD",
                          {
                            path: "/getVoterCse",
                            module: "cseVoterIdentifier",
                            values: { ...values },
                          },
                          (data) => {
                            setSubmitting(false);
                            if (data.success) {
                              this.setState({ cses: data.cse });
                              this.setState({ isEmailSubmitting: true });
                              this.setState({
                                voterEmail: values.email,
                              });
                            }
                          }
                        );
                      }}
                      validateOnMount
                    >
                      {({ isSubmitting, isValid, values }) => (
                        <Form>
                          <Label>Email</Label>
                          <Input name="email" type="email" />
                          <Error
                            component="div"
                            name="email"
                            style={{ textAlign: "center" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "10px",
                            }}
                          >
                            <Button
                              onClick={this.submit}
                              disabled={!isValid || isSubmitting}
                              style={{ marginRight: "10px" }}
                            >
                              Rechercher
                            </Button>
                            <Button
                              white
                              onClick={() => {
                                this.setState({ showModalForgotCode: false });
                              }}
                            >
                              Annuler
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )) ||
                    null}

                  {(this.state.isEmailSubmitting && (
                    <>
                      {(this.state.cses.length === 0 && (
                        <>
                          Aucun CSE trouvé pour cette adresse email.
                          <Button
                            style={{ marginTop: 10 }}
                            white
                            onClick={() => {
                              this.setState({
                                isEmailSubmitting: false,
                              });
                            }}
                          >
                            Retour
                          </Button>
                        </>
                      )) || (
                        <>
                          <div style={{ marginBottom: 10 }}>
                            Pour quel CSE souhaitez vous régénérer vos codes ?
                          </div>
                          <Formik
                            initialValues={{ cse: this.state.cses[0].cseId }}
                            validate={(values) => {
                              const errors = {};
                              return errors;
                            }}
                            onSubmit={(
                              values,
                              { setSubmitting, setErrors }
                            ) => {
                              setSubmitting(true);
                              API.post(
                                "/cseRouteCRUD",
                                {
                                  path: "/sendVoterCodes",
                                  module: "cseVoterIdentifier",
                                  cseId: values.cse,
                                  email: this.state.voterEmail,
                                },
                                (data) => {
                                  setSubmitting(false);
                                  if (data.success) {
                                    this.setState({
                                      isEmailSubmitting: false,
                                    });
                                    this.setState({
                                      passwordResetQueried: true,
                                    });
                                  }
                                }
                              );
                            }}
                            validateOnMount
                          >
                            {({ isSubmitting, isValid, values }) => (
                              <Form>
                                <Input component="select" name="cse">
                                  {this.state.cses.map((x) => (
                                    <option key={x.cseId} value={x.cseId}>
                                      {x.cseName}
                                    </option>
                                  ))}
                                </Input>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Button
                                    onClick={this.submit}
                                    disabled={!isValid || isSubmitting}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Envoyer
                                  </Button>
                                  <Button
                                    white
                                    onClick={() => {
                                      this.setState({
                                        isEmailSubmitting: false,
                                      });
                                    }}
                                  >
                                    Retour
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </>
                      )}
                    </>
                  )) ||
                    null}
                </>
              )}
            </Modal>
          </ModalOverlay>
        )}

        {this.state.showModalForgotPassword && (
          <ModalOverlay
            onClick={() => {
              window.location.hash = "";
              this.setState({ showModalForgotPassword: false });
            }}
          >
            <Modal onClick={(e) => e.stopPropagation()}>
              <ModalClose
                onClick={() => {
                  window.location.hash = "";
                  this.setState({ showModalForgotPassword: false });
                }}
              />
              {this.state.passwordResetQueried ? (
                <>
                  <h4>
                    Votre demande de réinitialisation de mot de passe a été
                    envoyée.
                  </h4>
                  <Button
                    onClick={() => {
                      this.setState({ showModalForgotPassword: false });
                    }}
                  >
                    Fermer
                  </Button>
                </>
              ) : (
                <>
                  <h4>Vous avez oublié votre mot de passe de votant ?</h4>
                  <p>
                    Nous allons vous assister dans sa récupération. Veuillez
                    entrer l'adresse mail liée à votre compte puis cliquer sur
                    "Envoyer". Selon le mode d'envoi défini par votre session de
                    vote, vous recevrez votre mot de passe par email ou SMS dans
                    les prochaines minutes.
                  </p>
                  <Formik
                    initialValues={{ email: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Veuillez entrer votre adresse email.";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      setSubmitting(true);
                      API.post(
                        "/updateVoterPassword",
                        { forgotPassword: true, ...values },
                        (data) => {
                          setSubmitting(false);
                          if (data.success) {
                            this.setState({ passwordResetQueried: true });
                          } else {
                            setErrors({ email: data.message });
                          }
                        }
                      );
                    }}
                    validateOnMount
                  >
                    {({ isSubmitting, isValid, values }) => (
                      <Form>
                        <Label>Email</Label>
                        <Input name="email" type="email" />
                        <Error
                          component="div"
                          name="email"
                          style={{ textAlign: "center" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            onClick={this.submit}
                            disabled={!isValid || isSubmitting}
                            style={{ marginRight: "10px" }}
                          >
                            Envoyer
                          </Button>
                          <Button
                            white
                            onClick={() => {
                              this.setState({ showModalForgotPassword: false });
                            }}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Modal>
          </ModalOverlay>
        )}
      </>
    );
  }
}

export default withRouter(Login);
