import React from "react";
import CustomButton from "../components/buttons/CustomButton";
import classes from "./ClientLandingPage.module.scss";

const ClientLandingPage = () => {
  return (
    <>
      <p className={classes.title}>
        Bienvenue sur <strong>Provote</strong>
      </p>
      <section className={classes.buttonContainer}>
        <CustomButton
          title={"Sessions de vote"}
          text={"Assemblées générales et conseils d'administration"}
          icon={"/img/inbox.svg"}
          iconAlt={"inbox icon"}
          href="/ag"
          variant="big"
        />
        <CustomButton
          title={"Scrutins électroniques"}
          text={
            "Comité Social et Economique (CSE) & Élections des représentants du personnel"
          }
          iconAlt={"people icon"}
          icon={"/img/people.svg"}
          href="/cse"
          variant="big"
        />
      </section>
    </>
  );
};

export default ClientLandingPage;
