import React, { useState, useEffect, useContext } from "react";
import classes from "./CseElectionList.module.scss";
import { MediumContainer } from "../../utils/styled-components-library";
import config from "../../config";
import { CseCollegeListType_Db } from "../../types/cseCollegeList";
import { UserContext } from "../../App";
import CustomButton from "../../components/buttons/CustomButton";
import ListPreview from "./cseVote/ListPreview";
import { ElectionListDocuments } from "./cseVote/CseVote";
import BackToVote from "../../components/BackTovote";

const ElectionList = ({
  isDocumented,
  collegeList,
}: {
  isDocumented: boolean;
  collegeList: CseCollegeListType_Db;
}) => {
  const [isOpenList, setIsOpenList] = useState(false);

  const toggleOpen = () => {
    setIsOpenList(!isOpenList);
  };
  return (
    <>
      <div
        className={classes.ListInfoContainer}
        key={collegeList.cseCollegeListId}
      >
        <ListPreview collegeList={collegeList} />
        <div className={classes.CollegeList_buttonContainer}>
          <CustomButton
            disabled={!isDocumented}
            onClick={toggleOpen}
            invert
            style={{ width: "auto" }}
          >
            <div className={classes.CollegeList_buttonContainer_children}>
              <img
                style={{
                  objectFit: "contain",
                }}
                src={"/img/folder.svg"}
                alt="folder"
              />
              <span>
                {isDocumented ? "Documentation" : "Pas de documentation"}
              </span>
            </div>
          </CustomButton>
        </div>
      </div>

      <ElectionListDocuments
        collegeList={collegeList}
        isOpen={isOpenList}
        onClose={toggleOpen}
      />
    </>
  );
};

const ElectionLists = ({
  electionName,
  cseCollegeLists,
}: {
  electionName: any;
  cseCollegeLists: CseCollegeListType_Db[];
}) => {
  return (
    <MediumContainer style={{ maxWidth: 800 }}>
      <div className={classes.ElectionListElement}>
        {cseCollegeLists
          .filter(
            (college: CseCollegeListType_Db) =>
              college.collegeListElectionName === electionName
          )
          .map((collegeList: CseCollegeListType_Db) => {
            const candidateDocuments = collegeList.cseCandidates.filter(
              (candidate) =>
                candidate.candidateManifesto || candidate.candidateVideo
            );

            const isDocumented =
              candidateDocuments.length !== 0 ||
              collegeList.cseCollegeListDocuments.length !== 0 ||
              collegeList.collegeListManifesto !== null ||
              collegeList.collegeListCampaignVideo !== null;

            return (
              <ElectionList
                collegeList={collegeList}
                isDocumented={isDocumented}
              />
            );
          })}
      </div>
    </MediumContainer>
  );
};

const CseElectionList = () => {
  const user = useContext(UserContext);
  const cseCollegeList = user.cseCollegeLists;
  const electionsName = user.electionNames;

  return (
    <>
      {(user.userType === "cseVoter" && <BackToVote />) || null}

      <MediumContainer style={{ textAlign: "left" }}>
        <h3>Listes :</h3>
        {electionsName.map((electionName) => (
          <ElectionLists
            electionName={electionName}
            cseCollegeLists={cseCollegeList}
          />
        ))}
      </MediumContainer>
    </>
  );
};

export default CseElectionList;
