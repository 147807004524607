import {
  Input,
  Label,
  ErrorContainer,
  Modal,
  ModalOverlay,
} from "../../utils/styled-components-library";
import FileInput from "../../components/FileInput";

import classes from "./CseCandidateCreate.module.scss";
import CustomButton from "../../components/buttons/CustomButton";
import { StrictMode, useEffect, useState } from "react";

const ErrorMessage = ({
  touched,
  errors,
  isSubmitting,
  name,
}: {
  touched: { [key: string]: any };
  errors: { [key: string]: any };
  isSubmitting: boolean;
  name: string;
}) =>
  (touched[`${name}`] || isSubmitting) && (
    <ErrorContainer>{errors[`${name}`]}</ErrorContainer>
  );
const CseCandidatCreate = ({
  setIsFileUploading = (e: any) => {},
  index = "",
  errors = {},
  touched = {},
  isSubmitting = false,
  onDelete = () => {},
  values,
  egalityManagement,
}) => {
  const [isOpenValidation, setIsOpenValidation] = useState(false);
  const toggleIsOpenValidation = () => setIsOpenValidation(!isOpenValidation);

  useEffect(() => {}, []);
  console.log(">>>", egalityManagement);
  console.log("alues !", values);
  return (
    <StrictMode>
      <div className={classes.CseCandidateForm}>
        <div style={{ minWidth: 110 }}>
          <Label>Genre*</Label>
          <Input
            style={{ padding: "12px 5px" }}
            component="select"
            name={`sex_${index}`}
          >
            {[
              {
                value: "female",
                display: "Madame",
              },
              {
                value: "male",
                display: "Monsieur",
              },
            ].map((x) => (
              <option key={x.value} value={x.value}>
                {x.display}
              </option>
            ))}
          </Input>
          <ErrorMessage
            name={`sex_${index}`}
            touched={touched}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </div>
        <div style={{ minWidth: 100 }}>
          <Label>Nom*</Label>
          <Input style={{ padding: "13px 5px" }} name={`lastname_${index}`} />
          <ErrorMessage
            name={`lastname_${index}`}
            touched={touched}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </div>
        <div style={{ minWidth: 100 }}>
          <Label>Prénom*</Label>
          <Input style={{ padding: "13px 5px" }} name={`firstname_${index}`} />
          <ErrorMessage
            name={`firstname_${index}`}
            touched={touched}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </div>
        <div style={{ minWidth: 100 }}>
          <Label>Autre information</Label>
          <Input
            style={{ padding: "13px 5px" }}
            touched={touched}
            errors={errors}
            isSubmitting={isSubmitting}
            name={`customField_${index}`}
          />
        </div>
        {(egalityManagement == "candidat-age" ||
          egalityManagement == "candidat-seniority") && (
          <div style={{ minWidth: 100 }}>
            <Label>
              {egalityManagement === "candidat-age"
                ? "Âge du candidat"
                : "Ancienneté du candidat"}
            </Label>
            <Input
              type="number"
              style={{ padding: "13px 5px" }}
              touched={touched}
              errors={errors}
              isSubmitting={isSubmitting}
              name={
                egalityManagement === "candidat-age"
                  ? `candidateAge_${index}`
                  : `candidateSeniority_${index}`
              }
            />
          </div>
        )}
        <div style={{ minWidth: 125 }}>
          <Label>Photo</Label>
          <FileInput
            accept="image/*"
            name={`image_${index}`}
            filename={{
              key: `imageFilename_${index}`,
              value: values[`imageFilename_${index}`],
              isShort: true,
            }}
            customOnChange={() => {
              setIsFileUploading(true);
            }}
            onLoadingComplete={() => {
              setIsFileUploading(false);
            }}
          />
          <ErrorMessage
            name={`image_${index}`}
            touched={touched}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </div>
        <div style={{ minWidth: 125, height: "100%" }}>
          <Label>Vidéo</Label>
          <FileInput
            accept="video/*"
            name={`video_${index}`}
            filename={{
              key: `videoFilename_${index}`,
              value: values[`videoFilename_${index}`],
              isShort: true,
            }}
            customOnChange={() => {
              setIsFileUploading(true);
            }}
            onLoadingComplete={() => {
              setIsFileUploading(false);
            }}
          />
          <ErrorMessage
            name={`video_${index}`}
            touched={touched}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </div>
        <div style={{ minWidth: 130 }}>
          <Label>Profession de foi</Label>
          <FileInput
            name={`manifesto_${index}`}
            filename={{
              key: `manifestoFilename_${index}`,
              value: values[`manifestoFilename_${index}`],
              isShort: true,
            }}
            customOnChange={() => {
              setIsFileUploading(true);
            }}
            onLoadingComplete={() => {
              setIsFileUploading(false);
            }}
          />
          <ErrorMessage
            name={`manifesto_${index}`}
            touched={touched}
            errors={errors}
            isSubmitting={isSubmitting}
          />
        </div>

        <img
          style={{
            objectFit: "contain",
            paddingTop: "24px",
            cursor: "pointer",
          }}
          className={classes.DeleteButton}
          onClick={toggleIsOpenValidation}
          src="/img/delete-button.svg"
          alt="delete"
        ></img>
        {isOpenValidation && (
          <ModalOverlay>
            <Modal
              style={{ marginTop: "10%" }}
              smModal
              onClick={(e: any) => e.stopPropagation()}
            >
              <h3>
                Le candidate {values[`lastname_${index}`]}{" "}
                {values[`firstname_${index}`]} sera supprimé
              </h3>
              <CustomButton
                style={{ width: "auto", marginRight: 20 }}
                invert
                onClick={toggleIsOpenValidation}
              >
                <>Ne pas supprimer le candidat</>
              </CustomButton>
              <CustomButton
                style={{ width: "auto" }}
                onClick={() => {
                  toggleIsOpenValidation();
                  onDelete();
                }}
              >
                <>Supprimer le candidat</>
              </CustomButton>
            </Modal>
          </ModalOverlay>
        )}
      </div>
    </StrictMode>
  );
};

export default CseCandidatCreate;
