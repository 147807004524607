import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import API from "../../utils/API";
import { UserContext } from "../../App";
import classes from "./CseClosure.module.scss";
import { Button, Label, Input } from "../../utils/styled-components-library";
import { toast } from "react-toastify";
import CustomButton from "../../components/buttons/CustomButton";
import useCseFirstdTurn from "../../hooks/useCseFirstRound";
import moment from "moment-timezone";

const StrippingKeyForm = ({
  cseAdmins,
  user,
  handler,
  cse,
}: {
  cseAdmins: any;
  user: any;
  handler: Function;
  cse: any;
}) => {
  const [showSmsPassword, setShowSmsPassword] = useState(
    user.userType === "client" || user.userType === "clientAdmin"
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const now = moment().tz("Europe/Paris");
  const endDate = moment.utc(cse.cseEndDate).tz("Europe/Paris");

  return (
    <div>
      {cseAdmins?.map((admin: any) => {
        const disabled =
          user.userType === "client" || user.userType === "clientAdmin"
            ? false
            : user.csePollingAdminId !== admin.csePollingAdminId;
        const placeholder = admin.strippingKeyUsed
          ? "Clef de dépouillement déjà renseignée"
          : "Entrez votre clef de dépouillement";
        return (
          <div
            className={classes.CseStrippingKeyForm}
            key={admin.csePollingAdminId}
          >
            <Formik
              onSubmit={(values) => {
                setIsSubmitting(true);
                if (now > endDate || admin.isClosed) {
                  API.post(
                    "/cseRouteCRUD",
                    {
                      strippingKey: values.strippingKey,
                      csePollingAdminId: admin.csePollingAdminId,
                      cseId: admin.cseId,
                      path: "/cseUseStrippingKey",
                      module: "cseStrippingCRUD",
                    },
                    (data: { success: boolean; data: any }) => {
                      setIsSubmitting(false);
                      if (data.success) {
                        handler();
                        toast.success(
                          `La clef de dépouillement a bien été envoyée !`
                        );
                      } else {
                        setIsSubmitting(false);
                        toast.error(
                          `La clef de dépouillement n'est pas valide`
                        );
                      }
                    },
                    () => {
                      setIsSubmitting(false);
                      toast.error(`La clef de dépouillement n'est pas valide`);
                    }
                  );
                } else {
                  setIsSubmitting(false);
                  toast.error(
                    `Vous devez attendre la fin du scrutin [${endDate
                      .format("dddd DD MMM [à] HH[:]mm")
                      .toString()}] pour dépouiller `
                  );
                }
              }}
              initialValues={{
                strippingKey:
                  user.userType === "client" || user.userType === "clientAdmin"
                    ? admin.strippingKey
                    : "",
              }}
            >
              <Form>
                <Label htmlFor="strippingKey">
                  Clef de dépouillement de {admin.csePollingAdminFirstname}{" "}
                  {admin.csePollingAdminLastname}
                </Label>
                <div className={classes.CsePasswordInputContainer}>
                  <Input
                    name="strippingKey"
                    disabled={disabled}
                    type={showSmsPassword ? "text" : "password"}
                    style={{ marginRight: "15px" }}
                    placeholder={placeholder}
                  />
                  <i
                    className={
                      !showSmsPassword ? "fa fa-eye" : "fa fa-eye-slash"
                    }
                    style={{
                      color: "#1D529C",
                      fontSize: "18px",
                      marginTop: -12,
                      cursor: "pointer",
                    }}
                    onClick={() => setShowSmsPassword(!showSmsPassword)}
                  />
                </div>
                <Button
                  type="submit"
                  className={classes.CseConnexionButton}
                  disabled={isSubmitting || disabled}
                >
                  Valider
                </Button>
              </Form>
            </Formik>
          </div>
        );
      })}
    </div>
  );
};

const StrippingKeyRemaining = ({
  remainingKey,
  strippingKeyTotalNeeded,
  strippingKeyTotalSent,
  cse,
}: {
  remainingKey: number;
  strippingKeyTotalNeeded: number;
  strippingKeyTotalSent: number;
  cse: any;
}) => {
  const usedStrippingKey = strippingKeyTotalSent - remainingKey;

  return (
    <div style={{ textAlign: "left" }}>
      {((usedStrippingKey > strippingKeyTotalNeeded ||
        cse.isPublished === 1) && (
        <span>
          Toutes les clefs de dépouillements ont été renseignées. L'urne est
          déscellée : le vote est clos et les résultats sont accessibles
        </span>
      )) || (
        <span>
          Il manque {strippingKeyTotalNeeded - usedStrippingKey} clef(s) de
          dépouillement à renseigner afin de pouvoir sceller les urnes et
          procéder au dépouillement
        </span>
      )}
    </div>
  );
};

const PublishResult = ({
  remainingKey,
  cse,
}: {
  remainingKey: number;
  cse: any;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPublished, setIsPublished] = useState(
    cse.isPublished === 0 ? false : true
  );
  const cseFirstTurn = useCseFirstdTurn(cse.cseParentId);
  const cseRound = cseFirstTurn ? "2nd tour" : "1er tour";

  return (
    <div>
      <span>
        <p style={{ color: "red", textAlign: "left" }}>
          En cliquant sur « Publier les résultats », vous rendez les résultats
          accessibles et visibles à l’ensemble des électeurs
          <br />
          Les électeurs sont informés par un email qu’ils peuvent consulter et
          télécharger les résultats via leur espace ProVote
        </p>
        <Button
          type="submit"
          className={classes.CseConnexionButton}
          disabled={
            isSubmitting ||
            isPublished ||
            cse.cseId === 328 ||
            cse.cseId === 373
          }
          onClick={() => {
            setIsSubmitting(true);
            API.post(
              "/cseRouteCRUD",
              {
                cseId: cse.cseId,
                cseRound: cseRound,
                path: "/csePublishResult",
                module: "result",
              },
              (data: { success: boolean; data: any }) => {
                if (data.success) {
                  setIsPublished(true);
                  toast.success(`Les résultats ont bien été publiés !`);
                } else {
                  setIsSubmitting(false);
                  toast.error(`Les résultats n'ont pas pu être publiés`);
                }
              },
              () => {
                toast.error(`Les résultats n'ont pas pu être publiés`);
              }
            );
          }}
        >
          {isPublished ? "Déjà publié" : "Publier"}
        </Button>
      </span>
    </div>
  );
};

const CheckKey = ({
  cseId,
  handler,
  setCanPublish,
}: {
  cseId: number;
  handler: any;
  setCanPublish: any;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Button
      type="submit"
      className={classes.CseConnexionButton}
      disabled={isSubmitting}
      onClick={() => {
        setIsSubmitting(true);
        API.post(
          "/cseRouteCRUD",
          {
            cseId: cseId,
            path: "/checkKey",
            module: "cseStrippingCRUD",
          },
          (data: any) => {
            setIsSubmitting(false);
            if (data) {
              if (data.success) {
                toast.success(`Les clefs sont valides`);
                setCanPublish(true);
                //handler();
              } else {
                if (data.message) {
                  toast.error(data.message);
                } else {
                  toast.error(`Les clefs sont invalides`);
                }
              }
            }
          }
        );
      }}
    >
      Vérifier les clefs
    </Button>
  );
};

const CseClosure = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [remainingKey, setRemainingKey] = useState(5);
  const [strippingKeyTotal, setStrippingKeyTotal] = useState(5);
  const [strippingKeyTotalNeeded, setStrippingKeyTotalNeeded] = useState(5);
  const [strippingKeyTotalSent, setStrippingKeyTotalSent] = useState(5);

  const [loaded, setIsloaded] = useState(true);
  const [cseAdmins, setCseAdmins] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const user = useContext(UserContext);

  const handler = () => {
    setRefresh(!refresh);
  };

  const getStrippingKeyTotal = (cseAdmins: any) => {
    let strippingKeyTotalTmp = 0;
    cseAdmins.map((admin: any) => {
      strippingKeyTotalTmp += admin.strippingKey === "" ? 0 : 1;
    });
    setStrippingKeyTotal(strippingKeyTotalTmp);
  };

  const getRemainingKey = (cseAdmins: any) => {
    let remainingKeyTmp = 0;
    cseAdmins.map((admin: any) => {
      remainingKeyTmp += admin.strippingKeyUsed ? 0 : 1;
    });
    setRemainingKey(remainingKeyTmp);
  };

  const getCseStrippingUsers = (cseId: number) => {
    API.post(
      "/cseRouteCRUD",
      { cseId, path: "/getCseStrippingKey", module: "cseStrippingCRUD" },
      (data: { success: boolean; data: any; cseInfoStripping: any }) => {
        setStrippingKeyTotalNeeded(
          data.cseInfoStripping.strippingKeyNecessaryCount
        );
        setStrippingKeyTotalSent(data.cseInfoStripping.strippingKeyCount);
        setCseAdmins(data.data);
        getRemainingKey(data.data);
        getStrippingKeyTotal(data.data);
        setIsloaded(false);
      }
    );
  };

  useEffect(() => {
    getCseStrippingUsers(cseId);
  }, [loaded, refresh, canPublish]);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"depouillement"} />
          <CseLayout
            title={"Dépouillement"}
            showResults={!loaded}
            loading={loaded}
            NoResultText="Aucune clef de dépouillement n'a été associée à un organisateur."
          >
            {(strippingKeyTotal >= 1 && (
              <>
                <StrippingKeyRemaining
                  remainingKey={remainingKey}
                  strippingKeyTotalSent={strippingKeyTotalSent}
                  strippingKeyTotalNeeded={strippingKeyTotalNeeded}
                  cse={cse}
                />
                <div style={{ textAlign: "left", width: "100%" }}>
                  <ul>
                    <li>Nombre de clefs envoyées : {strippingKeyTotalSent}</li>
                    <li>
                      Nombre de clefs nécessaires : {strippingKeyTotalNeeded}
                    </li>
                    <li>
                      Nombre de clefs entrées :{" "}
                      {strippingKeyTotalSent - remainingKey}
                    </li>
                  </ul>
                </div>
                <StrippingKeyForm
                  cseAdmins={cseAdmins}
                  user={user}
                  handler={handler}
                  cse={cse}
                />
                {((cse.isValidKey === 1 || canPublish) && (
                  <PublishResult remainingKey={remainingKey} cse={cse} />
                )) || (
                  <CheckKey
                    cseId={cse?.cseId}
                    handler={handler}
                    setCanPublish={setCanPublish}
                  />
                )}
              </>
            )) || (
              <div>
                <p style={{ textAlign: "left" }}>
                  Vous devez attribuer une ou plusieurs clefs de dépouillement
                </p>
                <CustomButton
                  type="submit"
                  style={{ width: "auto", padding: "8px 41px" }}
                  href="acces-autorises"
                >
                  <>Attribuer des clefs</>
                </CustomButton>
              </div>
            )}
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseClosure;
