import React, { useState, useEffect } from "react";
import VoteResultsComponent from "./VoteResults.component";
import styled from "styled-components";
import API from "../utils/API";
import "moment/locale/fr";
const moment = require("moment-timezone");
moment.locale("fr");
moment.tz.setDefault("Europe/Paris");

const MainContainer = styled.div`
	flex: 1;
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
	zoom: 0.15;
`;

const AgTitle = styled.div`
	font-size: 42px;
	font-weight: bold;
	margin: 40px auto;
	text-align: center;
`;

const AgDescription = styled.div`
	page-break-after: always;
	flex: 1;
`;

const DateDisplay = styled.div`
	font-size: 20px;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 15px;
`;

const DocumentTitle = styled.div`
	font-size: 31px;
	font-weight: bold;
	margin-bottom: 40px;
`;

export default function ResultsDisplayPrinting() {
	const searchParams = new URLSearchParams(window.location.search);
	const [ag, setAg] = useState({});

	const getAgDetails = () => {
		API.get(
			"/agDetails",
			{
				agId: searchParams.get("agId"),
			},
			(data) => {
				console.log(data);
				setAg(data);
			}
		);
	};

	useEffect(() => {
		getAgDetails();
	}, [getAgDetails]);

	const startDate = moment(ag.agStartDate).format("dddd D MMMM YYYY");

	const endDate = moment(ag.agEndDate).format("dddd D MMMM YYYY");

	return (
		<MainContainer>
			<AgDescription>
				<AgTitle>Session de vote {ag.agName}</AgTitle>
				<DocumentTitle>Résultats</DocumentTitle>
				<DateDisplay>{startDate}</DateDisplay>
				<span>-</span>
				<DateDisplay>{endDate}</DateDisplay>
			</AgDescription>
			<VoteResultsComponent printing />
		</MainContainer>
	);
}
