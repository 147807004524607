import React from "react";
import classes from "./CseNoResult.module.scss";

const CseNoResult = ({ text }) => {
  return (
    <section className={classes.noResults}>
      <p className={classes.noResultText}>{text}</p>
    </section>
  );
};

export default CseNoResult;
