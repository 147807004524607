import React, { useEffect, useState } from "react";
import * as Auth from "../../utils/Auth";
import config from "../../config";
import useCse from "../../hooks/useCse";
import {
  Button,
  Input,
  Label,
  StyledA,
} from "../../utils/styled-components-library";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import API from "../../utils/API";
import CustomButton from "../../components/buttons/CustomButton";
import { Form, Formik } from "formik";
import { DatePickerField } from "../../components/DatePicker";
import moment from "moment-timezone";

const encodeDataToURL = (data) => {
  return Object.keys(data)
    .map((value) => `${value}=${encodeURIComponent(data[value])}`)
    .join("&");
};

const CerfaForm = ({ cseId }: { cseId: number | undefined }) => {
  const [isSubmitting, setIsSubmmitting] = useState(false);

  const initialValues = {
    siret: "",
    social: "",
    address: "",
    zip: "",
    city: "",
    idcc: "",
    mandate: "",
    mandateDuration: "",
    mandateDurationUnit: "year",
    collegeNumber: "",
    lastElection: "",
    lastSiret: "",
    isPartial: "false",
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={() => {
        return {};
      }}
      onSubmit={(values) => {
        const url = `${
          config.apiUrl
        }/cerfaExportPDF?cseId=${cseId}&token=${Auth.getToken()}`;
        const data = { ...values };
        data.lastElection = moment.utc(data.lastElection).isValid()
          ? moment
              .utc(data.lastElection)
              .tz("Europe/Paris")
              .format("YYYY-MM-DD")
          : "";
        const downloadUrl = url + "&" + encodeDataToURL(data);
        window.open(downloadUrl);
      }}
    >
      {({}) => (
        <Form>
          <Label>SIRET</Label>
          <Input name="siret" type="number" max="99999999999999" />
          <Label>Raison sociale</Label>
          <Input name="social" type="text" />
          <Label>Adresse</Label>
          <Input name="address" type="text" />
          <Label>Code postal</Label>
          <Input name="zip" type="number" />
          <Label>Ville</Label>
          <Input name="city" type="text" />
          <Label>Numéro de convention collective (IDCC)</Label>
          <Input name="idcc" type="number" max="9999" />
          <Label>Durée du mandat</Label>
          <div style={{ display: "flex", gap: 15 }}>
            <Input name="mandateDuration" type="number" min="0" />
            <Input component="select" name="mandateDurationUnit">
              {[
                { value: "year", display: "Année" },
                { value: "month", display: "Mois" },
              ].map((x) => (
                <option key={x.display} value={x.value}>
                  {x.display}
                </option>
              ))}
            </Input>
          </div>
          <Label>S’agit-il d’une élection partielle</Label>
          <Input component="select" name="isPartial">
            {[
              { value: "false", display: "Non" },
              { value: "true", display: "Oui" },
            ].map((x) => (
              <option key={x.display} value={x.value}>
                {x.display}
              </option>
            ))}
          </Input>
          <Label>Nombre de collège électoraux que comporte le CSE</Label>
          <Input name="collegeNumber" type="number" min="0" />
          <Label>Date du précédent scrutin</Label>
          <DatePickerField name="lastElection" />
          <Label>
            SIRET déclaré lors du précédent scrutin (laissez vide si identique)
          </Label>
          <Input name="lastSiret" type="number" max="99999999999999" />

          <Button type="submit" disabled={isSubmitting}>
            Valider
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const CseCerfa = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"cerfa"} />
          <CseLayout title={"CERFA"} showResults={true}>
            {cse && cse.voteByList == 1 ? (
              <>
              La génération de CERFA n'est pas disponible pour les votes par liste.
              </>
            ) : (
            <>
              <section style={{ textAlign: "left" }}>
                <ul style={{ lineHeight: "1.7em" }}>
                  <li>
                    <strong>
                      Merci de noter que le scrutin doit être dépouillé pour
                      effectuer la génération des CERFA.
                    </strong>
                  </li>
                  <li>
                    Les CERFA{" "}
                    <strong>sont à compléter et signer avant envoi</strong>.
                  </li>
                  <li>
                    <span style={{ color: "red" }}>
                      Si vous souhaitez que les informations ci-dessous (encart
                      I (1) du CERFA) soient complétées automatiquement dans
                      votre CERFA, merci de renseigner les champs puis de
                      cliquer sur le bouton «Valider».
                    </span>

                    <ul>
                      <li>
                        <strong>A noter</strong> que si les champs ne sont pas
                        renseignés, vous pouvez éditer le CERFA (cliquer sur le
                        bouton « Valider » sans renseigner les champs). Les
                        champs non renseignés dans ProVote seront à renseigner
                        manuellement une fois le CERFA édité.
                      </li>
                      <li>
                        <strong>En cas d’erreur</strong>, le CERFA peut être
                        réédité. Pour cela il vous suffit de modifier les
                        informations à modifier puis de cliquer sur le bouton «
                        Valider ». Le CERFA modifié sera alors téléchargeable.
                      </li>
                    </ul>
                  </li>

                  <li>
                    Pour toute question, contactez le Chef de projet Mediavote
                    qui gère votre projet.
                  </li>
                </ul>
              </section>
              {!cse?.isValidKey && (
                <>
                  <i>
                    Vous devez publier les résultats avant de pouvoir générer
                    les CERFA
                  </i>
                </>
              )}
              {(cse?.isValidKey && (
                <>
                  <CerfaForm cseId={cse?.cseId} />
                </>
              )) || <>Urne non dépouillée</>}
            </>
            
            
            )}
          
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseCerfa;
