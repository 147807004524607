import moment from "moment";
import React, { useEffect, useState } from "react";
import Accordion from "../../components/accordions/Accordion";
import AccordionTitle from "../../components/accordions/AccordionTitle";
import { CseType_Db } from "../../types/cse";
import API from "../../utils/API";
import CseLayout from "./CseLayout";
import Link from "../../components/links/Link";
import CseCollegeAccordion from "./CseCollegeAccordion";

const CseList = () => {
  const [cseList, setCseList] = useState<CseType_Db[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    API.post(
      "/cseRouteCRUD",
      { path: "/getCseList", module: "cse" },
      (data) => {
        setCseList(data.data);
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <CseLayout
      title={"Vos scrutins"}
      loading={isLoading}
      NoResultText={"Vous n’avez pas encore de scrutin"}
      buttonText={"Créer un scrutin"}
      href={"creer-cse"}
      showResults={!!cseList.length}
    >
      <>
        {cseList.map((cse) => (
          <Accordion
            key={cse.cseId}
            title={
              <AccordionTitle
                left={<>{moment(cse.cseStartDate).format("DD/MM/YYYY")}</>}
                right={
                  <Link type="streamlined" href={`/cse/${cse.cseId}`}>
                    {cse.cseName}
                  </Link>
                }
              />
            }
            children={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 5,
                }}
              >
                {cse.cseColleges?.map((college, index) => (
                  <React.Fragment key={index}>
                    <CseCollegeAccordion hideModify college={college} />
                  </React.Fragment>
                ))}
              </div>
            }
          />
        ))}
      </>
    </CseLayout>
  );
};

export default CseList;
