module.exports = {
  apiUrl:
    process.env.NODE_ENV === "development" ||
    (typeof window !== "undefined" &&
      window.location.hostname === "staging.provote.fr")
      ? "https://75iak1zu36.execute-api.eu-west-3.amazonaws.com/dev"
      : typeof window !== "undefined" &&
        window.location.hostname === "valeo.provote.fr"
      ? "https://bnp9p8i54a.execute-api.eu-west-3.amazonaws.com/valeo"
      : "https://55frim8qfd.execute-api.eu-west-3.amazonaws.com/staging",
  s3BaseUrl: "https://mediavote-uploads.s3.eu-west-3.amazonaws.com/",
};
