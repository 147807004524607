import React, { useEffect, useState, useContext } from "react";
import classes from "./CseCreate.module.scss";
import {
  Input,
  Label,
  Error,
  SmallContainer,
  TooltipContainer,
  TooltipList,
  TooltipListItem,
} from "../../utils/styled-components-library";
import { Formik, Form, Field } from "formik";
import API from "../../utils/API";
import { DatePickerField } from "../../components/DatePicker";
import FileInput from "../../components/FileInput";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import InfoIcon from "@material-ui/icons/Info";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import { toast } from "react-toastify";

import CustomButton from "../../components/buttons/CustomButton";
import {
  CseType_Db,
  CseType_Params,
  CseType_Params__Error,
} from "../../types/cse";
import moment from "moment";
import { IconButton, Tooltip } from "@material-ui/core";
import { UserContext } from "../../App";

const ROUNDING = 30 * 60 * 1000;
const defaultStartDate = moment(Math.ceil(+moment.utc() / ROUNDING) * ROUNDING);

const credentialsMailTemplateEmailPartOne = `Madame, Monsieur,\n
Vous êtes invité(e) à élire vos représentants au Comité Social et Économique (CSE)\n
L’organisation du vote a été confiée à la société Mediavote qui présente toutes les garanties de sécurité et de confidentialité :\n
CNIL : délibération n°2019-053 du 25 avril 2019
Code du travail: articles L. 1111-2 et L. 1251-54
RGPD : norme NF 552\n
Vous êtes inscrit(e) pour voter au scrutin {{TITRE_DU_SCRUTIN}} :\n
Veuillez trouver ci-dessous vos identifiants de connexion à l’application ProVote® qui vous permettront de prendre part aux votes durant cette séance (aucune installation de logiciel n’est nécessaire).\n
Vous pouvez voter depuis un ordinateur, un smartphone, une tablette\n
L’ouverture du 1er tour du scrutin est le {{DATE_DEBUT}} et sera clos le {{DATE_FIN}}. Vos identifiants sécurisés de connexion à ProVote® sont les suivants :\n
Code électeur : {{IDENTIFIANT}}
Mot de passe : {{MOT_DE_PASSE}}
Lien de connexion : {{LIEN_CONNEXION}}\n\n`;

const credentialsMailTemplateEmailPartTwo = `Ces élections sont susceptibles (selon le résultat du 1er tour) de se réaliser avec un second tour. Pensez à bien conserver vos codes ci-dessus, ils serviront si vous êtes invité(e) à voter pour un 2nd tour\n
Dans le cas d’un 2nd tour, vous serez informé(e) par email de l’ouverture du scrutin\n
Une assistance est mise à votre disposition pour vous apporter l’aide nécessaire et ainsi vous permettre de voter en toute simplicité et sérénité\n
Nous vous remercions par avance pour votre participation et vous prions de croire à nos salutations respectueuses\n
L’équipe ProVote®  pour la Direction des Ressources Humaines`;

let credentialsMailTemplateEmail =
  credentialsMailTemplateEmailPartOne + credentialsMailTemplateEmailPartTwo;

const checkOrCrossIcon = (value) => {
  if (value) {
    return <CheckIcon style={{ paddingLeft: 5, fontSize: 14 }} />;
  } else {
    return <ClearIcon style={{ paddingLeft: 5, fontSize: 14 }} />;
  }
};

const createTimeUnitSelector = (inputName, isClosed) => {
  return (
    <Input component="select" name={inputName} disabled={isClosed}>
      {[
        { value: "minutes", display: "Minute(s)" },
        { value: "hours", display: "Heure(s)" },
        { value: "days", display: "Jour(s)" },
      ].map((x) => (
        <option key={x.value} value={x.value}>
          {x.display}
        </option>
      ))}
    </Input>
  );
};

const createReminderInputs = (
  inputName,
  inputPlaceholder,
  unitSelectionName,
  isClosed
) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Input
        name={inputName}
        disabled={isClosed}
        style={{ marginRight: "5px" }}
        placeholder={inputPlaceholder}
      />
      {createTimeUnitSelector(unitSelectionName, isClosed)}
    </div>
  );
};

const validateCredentialMailTemplatesVariables = (values) => {
  const errors = {} as any;
  const mailTemplate = values.credentialsMailTemplate;

  errors.startDate = mailTemplate.includes("{{DATE_DEBUT}}");
  errors.endDate = mailTemplate.includes("{{DATE_FIN}");
  errors.identifier = mailTemplate.includes("{{IDENTIFIANT}}");
  errors.password = mailTemplate.includes("{{MOT_DE_PASSE}}");
  errors.link = mailTemplate.includes("{{LIEN_CONNEXION}}");
  errors.scrutinTitle = mailTemplate.includes("{{TITRE_DU_SCRUTIN}}");

  return errors;
};

const createCredentialsMailTemplateErrorMessage = (values) => {
  const errors = validateCredentialMailTemplatesVariables(values);

  const valid = Object.entries(errors).reduce((accumulator, currentValue) => {
    if (!accumulator) return false;
    else if (!currentValue[1]) return false;
    else return true;
  }, true);

  return !valid ? (
    <>
      Variable(s) manquante(s)
      <ul>
        {!errors.scrutinTitle && <li>{"{{TITRE_DU_SCRUTIN}}"}</li>}
        {!errors.startDate && <li>{"{{DATE_DEBUT}}"}</li>}
        {!errors.endDate && <li>{"{{DATE_FIN}}"}</li>}
        {!errors.identifier && <li>{"{{IDENTIFIANT}}"}</li>}
        {!errors.password && <li>{"{{MOT_DE_PASSE}}"}</li>}
        {!errors.link && <li>{"{{LIEN_CONNEXION}}"}</li>}
      </ul>
    </>
  ) : null;
};

const createCredentialsMailTemplateTooltip = (values) => {
  const errors = validateCredentialMailTemplatesVariables(values);

  return (
    <TooltipContainer>
      Variables Obligatoires :
      <TooltipList>
        <TooltipListItem isValid={errors.scrutinTitle}>
          {"{{TITRE_DU_SCRUTIN}}"} {checkOrCrossIcon(errors.scrutinTitle)}
        </TooltipListItem>
        <TooltipListItem isValid={errors.startDate}>
          {"{{DATE_DEBUT}}"} {checkOrCrossIcon(errors.startDate)}
        </TooltipListItem>
        <TooltipListItem isValid={errors.endDate}>
          {"{{DATE_FIN}}"} {checkOrCrossIcon(errors.endDate)}
        </TooltipListItem>
        <TooltipListItem isValid={errors.identifier}>
          {"{{IDENTIFIANT}}"} {checkOrCrossIcon(errors.identifier)}
        </TooltipListItem>
        <TooltipListItem isValid={errors.password}>
          {"{{MOT_DE_PASSE}}"} {checkOrCrossIcon(errors.password)}
        </TooltipListItem>
        <TooltipListItem isValid={errors.link}>
          {"{{LIEN_CONNEXION}}"} {checkOrCrossIcon(errors.link)}
        </TooltipListItem>
      </TooltipList>
    </TooltipContainer>
  );
};

const CseCreate = ({
  isEdit = false,
  cse,
}: {
  isEdit: boolean;
  cse: CseType_Db;
}) => {
  const history = useHistory();

  const initialValuesTemplate = {
    title: null,
    startDate: defaultStartDate,
    endDate: null,
    credentialsSendingType: "Email",
    isEmailCredential: 0,
    isSmsCredential: 0,
    isLetterCredential: 0,
    isChallengeCredential: 0,
    phoneHelp: 0,
    emailHelp: 0,
    credentialsMailTemplate: credentialsMailTemplateEmail,
    reminderBeforeUnit: "minutes",
    reminderBeforeValue: null,
    challengeName: "",
    reminderAfterUnit: "minutes",
    reminderAfterValue: null,
    sendDate: moment.utc(),
    signatureNeeded: 1,
    votingRulesPdf: null,
    dgVideo: null,
    hrVideo: null,
    dgVideoFilename: null,
    hrVideoFilename: null,
    votingRulesPdfFilename: null,
    phoneHelpMinHours: null,
    phoneHelpMaxHours: null,
    voteByList: 0,
    egalityManagement: "list-order",
    disableParticipationdisplay: 0,
    strippingKeyCount: 0,
    strippingKeyNecessaryCount: 0,
    cseSecondTurnStartDate: null,
    cseSecondTurnEndDate: null,
    quorumNeeded: 50,
  } as CseType_Params;

  const [initialValues, setInitialValues] = useState<CseType_Params>(
    initialValuesTemplate
  );
  const [isReady, setIsReady] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (isEdit) {
        setInitialValues({
          title: cse.cseName,
          startDate: moment(cse.cseStartDate).isValid()
            ? moment.utc(cse.cseStartDate)
            : defaultStartDate,
          endDate: moment(cse.cseEndDate).isValid()
            ? moment.utc(cse.cseEndDate)
            : defaultStartDate,
          sendDateSecondTurn: moment(cse.sendDateSecondTurn).isValid()
            ? moment.utc(cse.sendDateSecondTurn)
            : null,
          credentialsSendingType: cse.credentialsSendingType,
          isEmailCredential: cse.isEmailCredential,
          isSmsCredential: cse.isSmsCredential,
          isLetterCredential: cse.isLetterCredential,
          isChallengeCredential: cse.isChallengeCredential,
          challengeName: cse.challengeName,
          reminderBeforeUnit: cse.reminderBeforeUnit
            ? cse.reminderBeforeUnit
            : "minutes",
          reminderBeforeValue: cse.reminderBeforeValue,
          reminderAfterUnit: cse.reminderAfterUnit
            ? cse.reminderAfterUnit
            : "minutes",
          reminderAfterValue: cse.reminderAfterValue,
          credentialsMailTemplate: cse.credentialsMailTemplate,
          sendDate: moment(cse.sendDate).isValid()
            ? moment.utc(cse.sendDate)
            : moment.utc(),
          signatureNeeded: cse.signatureNeeded,
          votingRulesPdf: cse.votingRulesPdf,
          votingRulesPdfFilename: cse.votingRulesPdfFilename,
          dgVideo: cse.dgVideo,
          hrVideo: cse.hrVideo,
          dgVideoFilename: cse.dgVideoFilename,
          hrVideoFilename: cse.hrVideoFilename,
          phoneHelp: cse.phoneHelp,
          emailHelp: cse.emailHelp,
          phoneHelpMinHours: cse.phoneHelpMinHours,
          phoneHelpMaxHours: cse.phoneHelpMaxHours,
          disableParticipationdisplay: cse.disableParticipationdisplay,
          voteByList: cse.voteByList,
          egalityManagement: cse.egalityManagement,
          strippingKeyCount: cse.strippingKeyCount,
          strippingKeyNecessaryCount: cse.strippingKeyNecessaryCount,
          cseSecondTurnStartDate: moment(cse.cseSecondTurnStartDate).isValid()
            ? moment.utc(cse.cseSecondTurnStartDate)
            : null,
          cseSecondTurnEndDate: moment(cse.cseSecondTurnEndDate).isValid()
            ? moment.utc(cse.cseSecondTurnEndDate)
            : null,
          quorumNeeded: cse.quorumNeeded * 100,
        });
        setIsReady(true);
      } else {
        setIsReady(true);
      }
    })();
  }, [isEdit]);
  return (
    <>
      {/* Same as */}
      <SmallContainer>
        <h1 className={classes.title}>
          {isEdit ? "Informations du scrutin" : "Créer un scrutin"}
        </h1>

        {isReady ? (
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: Partial<CseType_Params__Error> = {};

              if (!values.title) {
                errors.title = "Requis";
              }
              if (!values.startDate) {
                errors.startDate = "Requis";
              }
              if (!values.endDate) {
                errors.endDate = "Requis";
              }
              if (!values.sendDate) {
                errors.sendDate = "Requis";
              }
              if (!values.strippingKeyCount) {
                errors.strippingKeyCount = "Requis";
              }
              if (!values.strippingKeyNecessaryCount) {
                errors.strippingKeyNecessaryCount = "Requis";
              }
              if (
                values.strippingKeyNecessaryCount > values.strippingKeyCount
              ) {
                errors.strippingKeyNecessaryCount =
                  "Le nombre de clefs nécessaire dépasse le nombre de clefs envoyées.";
              }

              if (values.quorumNeeded > 100 || values.quorumNeeded < 0) {
                errors.quorumNeeded =
                  "Le quorum doit êtrre compris entre 0 et 100.";
              }

              if (
                [
                  values.isEmailCredential,
                  values.isSmsCredential,
                  values.isLetterCredential,
                  values.isChallengeCredential,
                ].filter((cred) => cred).length === 0
              ) {
                errors.credentialsSendingType = "Requis";
              }
              if (values.isChallengeCredential && !values.challengeName) {
                errors.challengeName = "Requis";
              }
              if (values.isEmailCredential) {
                const credentialErrors =
                  createCredentialsMailTemplateErrorMessage(values);
                if (credentialErrors) {
                  errors.credentialsMailTemplate = credentialErrors;
                }
              }

              return errors;
            }}
            onSubmit={async (values, { setErrors }) => {
              values.quorumNeeded =
                values.quorumNeeded === 0 ? 0 : values.quorumNeeded / 100;
              console.log("Values : ", values);
              setIsSubmitting(true);
              if (isEdit) {
                API.post(
                  "/cseRouteCRUD",
                  {
                    ...values,
                    cseId: cse.cseId,
                    path: "/updateCse",
                    module: "cse",
                  },
                  (data) => {
                    setIsSubmitting(false);
                    if (data.success) {
                      history.push(`/cse/${cse.cseId}/colleges`);
                      toast.success("La modification a été prise en compte !");
                    } else {
                      toast.error(data.error);
                    }
                  }
                );
              } else {
                API.post(
                  "/cseRouteCRUD",
                  { ...values, path: "/createCse", module: "cse" },
                  (data) => {
                    setIsSubmitting(false);
                    if (data.success) {
                      history.push(`/cse/${data.cseId}/colleges`);
                    } else {
                      toast.error(
                        "Erreur interne, veuillez réessayer plus tard"
                      );
                    }
                  }
                );
              }
            }}
            validateOnMount
          >
            {({
              values,
              setFieldValue,
              handleChange,
              validateField,
              errors,
            }) => (
              <Form className={classes.form} autoComplete="off">
                <fieldset
                  disabled={
                    user.userType === "client" ||
                    user.userType === "clientAdmin"
                      ? false
                      : true
                  }
                  style={{ border: "none" }}
                >
                  <div>
                    <Label>Titre*</Label>
                    <Input name="title" />
                    <Error name="title" component="div" />
                  </div>
                  <div>
                    <Label>Date de début*</Label>
                    <DatePickerField
                      name="startDate"
                      withTime
                      disabled={isClosed}
                    />
                    <Error name="startDate" component="div" />
                  </div>
                  <div>
                    <Label>Date de fin*</Label>
                    <DatePickerField
                      name="endDate"
                      withTime
                      disabled={isClosed}
                    />
                    <Error name="endDate" component="div" />
                  </div>
                  {(((cse && cse?.cseParentId === null) || !cse) && (
                    <>
                      <div>
                        <Label>
                          Mode d'envoi des identifiants aux votants*
                        </Label>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {[
                          { display: "Email", field: "isEmailCredential" },
                          { display: "SMS", field: "isSmsCredential" },
                          { display: "Courrier", field: "isLetterCredential" },
                          { display: "Défi", field: "isChallengeCredential" },
                        ].map((entry) => (
                          <>
                            <Field
                              disabled={isClosed}
                              name={entry.field}
                              type="checkbox"
                              id={entry.field}
                              checked={!!values[entry.field]}
                              value={!!values[entry.field]}
                            />
                            <label
                              style={{ cursor: "pointer", padding: "5px" }}
                              htmlFor={entry.field}
                            >
                              {entry.display}
                            </label>
                          </>
                        ))}
                      </div>
                      {[
                        values.isEmailCredential,
                        values.isSmsCredential,
                        values.isLetterCredential,
                        values.isChallengeCredential,
                      ].filter((cred) => cred).length !== 2 && (
                        <p style={{ textAlign: "left", color: "chocolate" }}>
                          Il est conseillé d'avoir exactement deux modes d'envoi
                        </p>
                      )}
                      <Error name="credentialsSendingType" component="div" />

                      {(values.isLetterCredential && (
                        <>
                          <div>
                            <Label>
                              Date de début du second tour (à titre indicatif)
                            </Label>
                            <DatePickerField
                              name="cseSecondTurnStartDate"
                              withTime
                              disabled={isClosed}
                            />
                          </div>
                          <div>
                            <Label>
                              Date de fin du second tour (à titre indicatif)
                            </Label>
                            <DatePickerField
                              name="cseSecondTurnEndDate"
                              withTime
                              disabled={isClosed}
                            />
                          </div>
                        </>
                      )) ||
                        null}

                      {!!values.isChallengeCredential && (
                        <div>
                          <Label>Nom du défi*</Label>
                          <Input name="challengeName" />
                          <Error name="challengeName" component="div" />
                        </div>
                      )}

                      {!!values.isEmailCredential && (
                        <>
                          <Label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Mail d'envoi des identifiants de connexion
                            <Tooltip
                              title={createCredentialsMailTemplateTooltip(
                                values
                              )}
                            >
                              <IconButton
                                aria-label="delete"
                                style={{ marginLeft: 4 }}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Label>
                          <Input
                            name="credentialsMailTemplate"
                            disabled={isClosed}
                            component="textarea"
                            style={{ height: 200 }}
                          />
                          <Error
                            name="credentialsMailTemplate"
                            component="div"
                          />
                          <div
                            style={{
                              fontSize: "12px",
                              textAlign: "left",
                              marginBottom: "10px",
                            }}
                          >
                            Vous pouvez modifier le mail qui sera envoyer aux
                            votants contenant leurs informations de connexion.
                            Les éléments contenus entre double accolade{" "}
                            {'"{{}}"'} seront remplacé par les informations de
                            votre session de vote.
                          </div>
                        </>
                      )}
                      {[
                        values.isEmailCredential,
                        values.isSmsCredential,
                      ].filter((entry) => entry).length > 0 && (
                        <div>
                          <Label>
                            Date et heure de l’envoi des identifiants*
                          </Label>
                          <DatePickerField
                            name="sendDate"
                            withTime
                            disabled={isClosed}
                          />
                          <Error name="sendDate" component="div" />
                        </div>
                      )}
                    </>
                  )) || (
                    <div>
                      <Label>Date d'envoi du mail du second tour</Label>
                      <DatePickerField
                        name="sendDateSecondTurn"
                        withTime
                        disabled={isClosed}
                      />
                      <Error name="sendDateSecondTurn" component="div" />
                    </div>
                  )}
                  <div>
                    <Label>Quorum necessaire (en %)</Label>
                    <Input
                      type="number"
                      name="quorumNeeded"
                      min={0}
                      max={100}
                      disabled={isClosed}
                    />
                    <Error name="quorumNeeded" component="div" />
                  </div>
                  <div>
                    <Label>Vote par : </Label>
                    <Input
                      component="select"
                      name="voteByList"
                      disabled={isClosed}
                    >
                      {[
                        { value: 0, display: "Liste avec rature" },
                        { value: 1, display: "Liste sans rature" },
                      ].map((x) => (
                        <option key={x.display} value={x.value}>
                          {x.display}
                        </option>
                      ))}
                    </Input>
                    <Error name="emailHelp" component="div" />
                  </div>
                  <div>
                    <Label>Gestion des égalités : </Label>
                    <Input
                      component="select"
                      name="egalityManagement"
                      disabled={isClosed}
                    >
                      {[
                        {
                          value: "list-order",
                          display: "Ordre au sein de la liste",
                        },
                        { value: "candidat-age", display: "Âge du candidat" },
                        {
                          value: "candidat-seniority",
                          display: "Ancienneté du candidat",
                        },
                      ].map((x) => (
                        <option key={x.display} value={x.value}>
                          {x.display}
                        </option>
                      ))}
                    </Input>
                    <Error name="emailHelp" component="div" />
                  </div>
                  <div>
                    <Label>Nombre de clefs de dépouillement a envoyer </Label>
                    <Input
                      type="number"
                      name="strippingKeyCount"
                      disabled={isClosed}
                      min={0}
                    />
                    <Error name="strippingKeyCount" component="div" />
                  </div>
                  <div>
                    <Label>Nombre de clefs de dépouillement necessaire </Label>
                    <Input
                      type="number"
                      name="strippingKeyNecessaryCount"
                      min={0}
                      disabled={isClosed}
                    />
                    <Error name="strippingKeyNecessaryCount" component="div" />
                  </div>
                  <div>
                    <Label>
                      Taux de participation visible pour les électeurs :{" "}
                    </Label>
                    <Input
                      component="select"
                      name="disableParticipationdisplay"
                      disabled={isClosed}
                    >
                      {[
                        { value: 0, display: "Oui" },
                        { value: 1, display: "Non" },
                      ].map((x) => (
                        <option key={x.display} value={x.value}>
                          {x.display}
                        </option>
                      ))}
                    </Input>
                    <Error name="emailHelp" component="div" />
                  </div>
                  <div>
                    <Label>Assistance par mail*</Label>
                    <Input
                      component="select"
                      name="emailHelp"
                      disabled={isClosed}
                    >
                      {[
                        { value: 1, display: "Oui" },
                        { value: 0, display: "Non" },
                      ].map((x) => (
                        <option key={x.display} value={x.value}>
                          {x.display}
                        </option>
                      ))}
                    </Input>
                    <Error name="emailHelp" component="div" />
                  </div>
                  <div>
                    <Label>Assistance par téléphone*</Label>
                    <Input
                      component="select"
                      name="phoneHelp"
                      disabled={isClosed}
                    >
                      {[
                        { value: 1, display: "Oui" },
                        { value: 0, display: "Non" },
                      ].map((x) => (
                        <option key={x.display} value={x.value}>
                          {x.display}
                        </option>
                      ))}
                    </Input>
                    <Error name="phoneHelp" component="div" />
                  </div>
                  {(values.phoneHelp && (
                    <div>
                      <Label>Horaire d'assistance*</Label>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        De{" "}
                        <Input
                          component="select"
                          name="phoneHelpMinHours"
                          disabled={isClosed}
                        >
                          {[...Array(25).keys()].map((x) => (
                            <option key={x} value={x}>
                              {`${x}h`}
                            </option>
                          ))}
                        </Input>
                        à{" "}
                        <Input
                          name="phoneHelpMaxHours"
                          component="select"
                          disabled={isClosed}
                        >
                          {[...Array(25).keys()].map((x) => (
                            <option key={x} value={x}>
                              {`${x}h`}
                            </option>
                          ))}
                        </Input>
                      </div>
                      <Error name="asstianceMax" component="div" />
                      <Error name="asstianceMin" component="div" />
                    </div>
                  )) ||
                    null}
                  {[values.isEmailCredential, values.isSmsCredential].filter(
                    (entry) => entry
                  ).length > 0 && (
                    <>
                      <Label>
                        Envoi du mail/SMS de rappel <b>AVANT</b> l'ouverture des
                        votes
                      </Label>
                      {createReminderInputs(
                        "reminderBeforeValue",
                        "Rappel AVANT",
                        "reminderBeforeUnit",
                        isClosed
                      )}
                      <Label>
                        Envoi du mail/SMS de rappel <b>APRÈS</b> l'ouverture des
                        votes
                      </Label>
                      {createReminderInputs(
                        "reminderAfterValue",
                        "Rappel APRÈS",
                        "reminderAfterUnit",
                        isClosed
                      )}
                    </>
                  )}
                  <div>
                    <Label>Émargement*</Label>
                    <Input
                      component="select"
                      name="signatureNeeded"
                      disabled={isClosed}
                    >
                      {[
                        { value: 1, display: "Avec Signature" },
                        { value: 0, display: "Sans Signature" },
                      ].map((x) => (
                        <option key={x.display} value={x.value}>
                          {x.display}
                        </option>
                      ))}
                    </Input>
                    <Error name="signatureNeeded" component="div" />
                  </div>
                  <div>
                    <Label>Modalités du vote</Label>
                    <FileInput
                      name="votingRulesPdf"
                      filename={{
                        key: "votingRulesPdfFilename",
                        value: values.votingRulesPdfFilename,
                      }}
                      customOnChange={() => {
                        setIsFileUploading(true);
                      }}
                      onLoadingComplete={() => {
                        setIsFileUploading(false);
                      }}
                      disabled={isClosed}
                    />
                    <Error name="votingRulesPdf" component="div" />
                  </div>
                  <div>
                    <Label>Vidéo Direction Générale</Label>
                    <FileInput
                      accept="video/*"
                      name="dgVideo"
                      filename={{
                        key: "dgVideoFilename",
                        value: values.dgVideoFilename,
                      }}
                      customOnChange={() => {
                        setIsFileUploading(true);
                      }}
                      onLoadingComplete={() => {
                        setIsFileUploading(false);
                      }}
                      disabled={isClosed}
                    />
                    <Error name="dgVideo" component="div" />
                  </div>
                  <div>
                    <Label>Vidéo Ressources humaines</Label>
                    <FileInput
                      filename={{
                        key: "hrVideoFilename",
                        value: values.hrVideoFilename,
                      }}
                      accept="video/*"
                      name="hrVideo"
                      customOnChange={() => {
                        setIsFileUploading(true);
                      }}
                      onLoadingComplete={() => {
                        setIsFileUploading(false);
                      }}
                      disabled={isClosed}
                    />
                    <Error name="hrVideo" component="div" />
                  </div>
                  {user.userType !== "admin" && (
                    <div className={cn(classes.buttonContainer)}>
                      <CustomButton white href="/cse">
                        <>Retour</>
                      </CustomButton>
                      <CustomButton
                        onClick={() => {
                          const numberOfErrors = Object.keys(errors).length;
                          if (Object.keys(errors).length) {
                            toast.error(
                              `${numberOfErrors} erreur(s) dans le formulaire`
                            );
                          }
                        }}
                        type="submit"
                        disabled={
                          isSubmitting ||
                          isFileUploading ||
                          isClosed ||
                          user.userType === "admin"
                        }
                      >
                        <>Valider</>
                      </CustomButton>
                    </div>
                  )}
                </fieldset>
              </Form>
            )}
          </Formik>
        ) : null}
      </SmallContainer>
    </>
  );
};

export default CseCreate;
