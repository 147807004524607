import React, { StrictMode, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Input,
  Label,
  Error,
  TabletWideHide,
  TabletWideShow,
  Modal,
  ModalOverlay,
} from "../../utils/styled-components-library";
import { Formik, Form, FieldArray } from "formik";
import FileInput from "../../components/FileInput";
import cn from "classnames";
import classes from "./CseCollegeListCreate.module.scss";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import CustomButton from "../../components/buttons/CustomButton";
import {
  CseCollegeListType_Params,
  CseCollegeListType_Params__Error,
} from "../../types/cseCollegeList";
import CseCandidatCreate from "./CseCandidateCreate";
import { CseCandidateType_Params } from "../../types/cseCandidate";
import { cseCollegeListDocument_Params } from "../../types/cseCollegeListDocument";
import useCse from "../../hooks/useCse";

const arrayMove = (arr, oldIndex, newIndex) => {
  const copyArr = [...arr];
  let copyOldIndex = oldIndex;
  let copyNewIndex = newIndex >= arr.length ? 0 : newIndex;
  while (copyOldIndex < 0) {
    copyOldIndex += copyArr.length;
  }
  while (copyNewIndex < 0) {
    copyNewIndex += copyArr.length;
  }
  if (copyNewIndex >= copyArr.length) {
    let k = copyNewIndex - copyArr.length + 1;
    while (k--) {
      copyArr.push(undefined);
    }
  }
  copyArr.splice(copyNewIndex, 0, copyArr.splice(copyOldIndex, 1)[0]);
  return copyArr; // for testing purposes
};

const DataMover = ({ data, order, setData }) => {
  const handleMove = (newPosition) => {
    const newList = arrayMove(data, order, newPosition).map((cont, ind) => {
      cont.order = ind;
      return cont;
    });
    setData(newList);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingRight: 10,
      }}
    >
      <div
        onClick={() => handleMove(order - 1)}
        style={{
          cursor: "pointer",
        }}
      >
        <KeyboardArrowUpIcon />
      </div>
      <p style={{ margin: 0 }}>{order + 1}</p>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          handleMove(order + 1);
        }}
      >
        <KeyboardArrowDownIcon />
      </div>
    </div>
  );
};

const CseCollegeListCreate = ({
  collegeLists,
  listOrder,
  isEdit = false,
  cseId,
  cseCollegeList,
  onSubmit = (e: any) => {},
  isOpen = false,
  onClose = () => {},
}: {
  collegeLists: any;
  listOrder: number;
  isEdit?: boolean;
  cseId: number;
  cseCollegeList?: CseCollegeListType_Params;
  onSubmit?: (e: any) => void;
  isOpen?: boolean;
  onClose?: () => void;
}) => {
  const cse = useCse(cseId);
  const [createdListOrder, setCreatedListOrder] = useState(
    collegeLists.filter(
      (collegeLists) => collegeLists.electionName === "Titulaire"
    ).length + 1
  );

  const [realListOrder, setRealListOrder] = useState(
    isEdit ? Number(listOrder) : createdListOrder
  );

  const initialValuesTemplate = {
    index: uuidv4(),
    cseCollegeListId: 0,
    name: null,
    image: null,
    imageFilename: null,
    manifesto: null,
    manifestoFilename: null,
    affiliation: null,
    campaignVideo: null,
    campaignVideoFilename: null,
    documents: [],
    candidates: [],
    electionName: "Titulaire",
    hasCandidate: 1,
    listOrder: isEdit ? Number(listOrder) : createdListOrder,
  } as CseCollegeListType_Params;

  const initialCandidate = {
    cseCandidateId: 0,
    sex: "female",
    image: null,
    video: null,
    manifesto: null,
    imageFilename: null,
    videoFilename: null,
    manifestoFilename: null,
    lastname: null,
    firstname: null,
    customField: null,
    candidateAge: null,
    candidateSeniority: null,
  } as CseCandidateType_Params;

  const [initialValues, setInitialValues] = useState<CseCollegeListType_Params>(
    initialValuesTemplate
  );
  const [isReady, setIsReady] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [documents, setDocuments] = useState<cseCollegeListDocument_Params[]>(
    []
  );
  const [isOpenValidation, setIsOpenValidation] = useState(false);

  const toggleIsOpenValidation = () => setIsOpenValidation(!isOpenValidation);

  const handleAddDocument = () => {
    setDocuments([...documents, { document: null, documentFilename: null }]);
  };

  const handleDocumentChange = (file, id: number) => {
    setDocuments(
      documents.map((doc, ind) => (id === ind ? { ...doc, ...file } : doc))
    );
  };

  const handleDocumentDelete = (id: number) => () => {
    setDocuments(documents.filter((doc, ind) => id !== ind));
  };

  const handleAddCandidates = (setValues: any) => () => {
    const index = uuidv4();
    const extractedCandidate: { [key: string]: any } = {};
    Object.keys(initialCandidate).forEach((key) => {
      extractedCandidate[`${key}_${index}`] = initialCandidate[key];
    });
    setValues((values: any) => {
      return {
        ...values,
        ...extractedCandidate,
        candidates: [
          ...values.candidates,
          { index, ...initialCandidate, order: values.candidates.length },
        ],
      };
    });
    setTrySubmit(false);
  };

  const parseValuesForSubmit = (currentValues: CseCollegeListType_Params) => {
    const parsedValues = {};
    const valuesCopy = { ...currentValues };
    valuesCopy.candidates = valuesCopy.candidates.map((candidate) => {
      const { index } = candidate;
      Object.keys(candidate).forEach((key) => {
        if (key !== "index" && key !== "order") {
          candidate[key] = valuesCopy[`${key}_${index}`];
        }
      });
      return candidate;
    });

    Object.keys(initialValuesTemplate).forEach((key) => {
      parsedValues[key] = valuesCopy[key];
    });
    return parsedValues;
  };

  const parseInitialValues = (cseCollegeList: CseCollegeListType_Params) => {
    const cseCollegeListCopy = { ...cseCollegeList };
    cseCollegeListCopy.candidates.forEach((candidate, ind) => {
      const { index } = candidate;
      Object.keys(candidate).forEach((key) => {
        if (key !== "index") {
          cseCollegeListCopy[`${key}_${index}`] = candidate[key];
        }
      });
    });
    cseCollegeListCopy.listOrder = listOrder;
    return cseCollegeListCopy;
  };

  const handleTrySubmit = () => {
    setTrySubmit(true);
  };

  useEffect(() => {
    (async () => {
      if (isEdit && cseCollegeList) {
        if (cseCollegeList.hasCandidate === 0) {
          cseCollegeList.candidates = [];
        }
        setInitialValues(parseInitialValues(cseCollegeList));
        setDocuments(cseCollegeList.documents);
      }
      setIsReady(true);
    })();
  }, [isEdit, cseCollegeList]);

  return (
    <StrictMode>
      {isOpen && (
        <ModalOverlay>
          <Modal
            style={{ marginTop: "5%" }}
            lgModal
            onClick={(e: any) => e.stopPropagation()}
          >
            <>
              {isReady ? (
                <>
                  <Formik
                    initialValues={initialValues}
                    validate={(values: any) => {
                      const errors: Partial<CseCollegeListType_Params__Error> =
                        {};
                      if (
                        Number(values.hasCandidate) === 1 &&
                        !values.candidates.length
                      ) {
                        errors.candidates =
                          "Vous devez avoir au moins un candidat";
                      }

                      if (!values.name) {
                        errors.name = "Requis";
                      }
                      if (!values.image) {
                        errors.image = "Requis";
                      }
                      if (!values.affiliation) {
                        errors.affiliation = "Requis";
                      }

                      values.candidates.forEach(
                        (candidate: CseCollegeListType_Params) => {
                          if (!values[`sex_${candidate.index}`]) {
                            errors[`sex_${candidate.index}`] = "Requis";
                          }
                          if (!values[`lastname_${candidate.index}`]) {
                            errors[`lastname_${candidate.index}`] = "Requis";
                          }
                          if (!values[`firstname_${candidate.index}`]) {
                            errors[`firstname_${candidate.index}`] = "Requis";
                          }
                        }
                      );
                      console.log(">>>>>>", values.candidates);
                      return errors;
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                      onSubmit({ ...parseValuesForSubmit(values), documents });
                      onClose();
                    }}
                    validateOnMount
                  >
                    {({
                      isSubmitting,
                      values,
                      setValues,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                    }) => (
                      <Form autoComplete="off">
                        <h2 className={classes.title}>
                          {isEdit
                            ? "Modification de la liste"
                            : "Création de la liste"}
                          {` ( n° ${values.listOrder})`}
                        </h2>
                        <div className={classes.CreationListFormContainer}>
                          <div>
                            <div>
                              <Label>Type d'élection*</Label>
                              <Input
                                component="select"
                                name="electionName"
                                onChange={(event) => {
                                  handleChange(event);
                                  setFieldValue(
                                    "listOrder",
                                    collegeLists.filter(
                                      (collegeLists) =>
                                        collegeLists.electionName ===
                                        event.currentTarget.value
                                    ).length + 1
                                  );
                                }}
                              >
                                {[
                                  { value: "Titulaire", display: "Titulaire" },
                                  {
                                    value: "Suppléant",
                                    display: "Suppléant",
                                  },
                                ].map((x) => (
                                  <option key={x.display} value={x.value}>
                                    {x.display}
                                  </option>
                                ))}
                              </Input>
                              <Error name="electionName" component="div" />
                            </div>

                            <div>
                              <Label>Nom de la liste*</Label>
                              <Input name="name" />
                              <Error name="name" component="div" />
                            </div>

                            <div>
                              <Label>Ordre de la liste*</Label>
                              <Input name="listOrder" />
                              <Error name="listOrder" component="div" />
                            </div>

                            <div>
                              <Label>Logo de la liste*</Label>
                              <FileInput
                                accept="image/*"
                                name="image"
                                filename={{
                                  key: "imageFilename",
                                  value: values.imageFilename,
                                }}
                                customOnChange={() => {
                                  setIsFileUploading(true);
                                }}
                                onLoadingComplete={() => {
                                  setIsFileUploading(false);
                                }}
                              />
                              <Error name="image" component="div" />
                            </div>

                            <TabletWideShow>
                              {documents.map((doc, ind) => (
                                <div>
                                  <Label>Autre document ajouté</Label>
                                  <FileInput
                                    name="otherDoc"
                                    filename={{
                                      key: doc.documentFilename,
                                      value: doc.documentFilename,
                                    }}
                                    onDelete={handleDocumentDelete(ind)}
                                    onChange={(file) =>
                                      handleDocumentChange(file, ind)
                                    }
                                    customOnChange={() => {
                                      setIsFileUploading(true);
                                    }}
                                    onLoadingComplete={() => {
                                      setIsFileUploading(false);
                                    }}
                                  />
                                  <Error name="manifesto" component="div" />
                                </div>
                              ))}
                              <CustomButton onClick={handleAddDocument} invert>
                                <>Ajouter un document</>
                              </CustomButton>
                            </TabletWideShow>
                          </div>
                          <div
                            className={classes.CreationListFormContainer_Right}
                          >
                            <div>
                              <Label>Affiliation*</Label>
                              <Input name="affiliation" />
                              <Error name="affiliation" component="div" />
                              <Error
                                name="canditat-name-index"
                                component="div"
                              />
                            </div>

                            <div>
                              <Label>Vidéo de la campagne</Label>
                              <FileInput
                                accept="video/*"
                                name="campaignVideo"
                                filename={{
                                  key: "campaignVideoFilename",
                                  value: values.campaignVideoFilename,
                                }}
                                customOnChange={() => {
                                  setIsFileUploading(true);
                                }}
                                onLoadingComplete={() => {
                                  setIsFileUploading(false);
                                }}
                              />
                              <Error name="campaignVideo" component="div" />
                            </div>
                            <div>
                              <Label>Profession de foi</Label>
                              <FileInput
                                name="manifesto"
                                filename={{
                                  key: "manifestoFilename",
                                  value: values.manifestoFilename,
                                }}
                                customOnChange={() => {
                                  setIsFileUploading(true);
                                }}
                                onLoadingComplete={() => {
                                  setIsFileUploading(false);
                                }}
                              />
                              <Error name="manifesto" component="div" />
                            </div>

                            <TabletWideHide>
                              {documents.map((doc, ind) => (
                                <div>
                                  <Label>Autre document ajouté</Label>
                                  <FileInput
                                    name="otherDoc"
                                    filename={{
                                      key: doc.documentFilename,
                                      value: doc.documentFilename,
                                    }}
                                    onDelete={handleDocumentDelete(ind)}
                                    onChange={(filename: string) =>
                                      handleDocumentChange(filename, ind)
                                    }
                                    customOnChange={() => {
                                      setIsFileUploading(true);
                                    }}
                                    onLoadingComplete={() => {
                                      setIsFileUploading(false);
                                    }}
                                  />
                                </div>
                              ))}

                              <CustomButton onClick={handleAddDocument} invert>
                                <>Ajouter un document</>
                              </CustomButton>
                            </TabletWideHide>
                            <div>
                              <Label>Liste sans candidat</Label>
                              <Input component="select" name="hasCandidate">
                                {[
                                  { value: "1", display: "Avec candidat" },
                                  {
                                    value: "0",
                                    display: "Sans  candidat",
                                  },
                                ].map((x) => (
                                  <option key={x.display} value={x.value}>
                                    {x.display}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>
                        {Number(values.hasCandidate) === 1 && (
                          <>
                            <div className={cn(classes.CandidatesList)}>
                              <Label>Candidats</Label>
                              <Error name="candidates" component="div" />

                              <div className={cn(classes.CandidatesList_List)}>
                                <FieldArray
                                  name="candidates"
                                  render={(arrayHelpers) => (
                                    <>
                                      {values.candidates
                                        .filter((x) => {
                                          return x.sex !== null;
                                        })
                                        .map((candidate, ind) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <DataMover
                                                data={values.candidates}
                                                order={candidate.order}
                                                setData={(data) =>
                                                  setValues((values) => ({
                                                    ...values,
                                                    candidates: data,
                                                  }))
                                                }
                                              />
                                              <CseCandidatCreate
                                                touched={touched}
                                                isSubmitting={trySubmit}
                                                values={values}
                                                errors={errors}
                                                egalityManagement={
                                                  cse?.egalityManagement
                                                }
                                                setIsFileUploading={
                                                  setIsFileUploading
                                                }
                                                index={candidate.index}
                                                onDelete={() => {
                                                  setValues((values) => ({
                                                    ...values,
                                                    candidates:
                                                      values.candidates
                                                        .filter(
                                                          (can) =>
                                                            can.index !==
                                                            candidate.index
                                                        )
                                                        .map(
                                                          (candidate, ind) => ({
                                                            ...candidate,
                                                            order: ind,
                                                          })
                                                        ),
                                                  }));
                                                }}
                                              />
                                            </div>
                                          );
                                        })}
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className={cn(classes.AddCandidate_Button)}>
                              <CustomButton
                                onClick={handleAddCandidates(setValues)}
                                invert
                              >
                                <>Ajouter un candidat</>
                              </CustomButton>
                            </div>
                          </>
                        )}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <CustomButton
                            white
                            style={{
                              marginRight: "10px",
                            }}
                            onClick={toggleIsOpenValidation}
                          >
                            <>Annuler</>
                          </CustomButton>
                          {isOpenValidation && (
                            <ModalOverlay>
                              <Modal
                                style={{ marginTop: "10%" }}
                                smModal
                                onClick={(e: any) => e.stopPropagation()}
                              >
                                <h3>
                                  Toutes les modifications effectuées sur cette
                                  liste (candidats inclus) seront perdues
                                </h3>
                                <CustomButton
                                  style={{ width: "auto", marginRight: 20 }}
                                  invert
                                  onClick={toggleIsOpenValidation}
                                >
                                  <>Continuer les modifications</>
                                </CustomButton>
                                <CustomButton
                                  style={{ width: "auto" }}
                                  onClick={() => {
                                    toggleIsOpenValidation();
                                    onClose();
                                  }}
                                >
                                  <>Annuler les modifications</>
                                </CustomButton>
                              </Modal>
                            </ModalOverlay>
                          )}

                          <CustomButton
                            disabled={isFileUploading}
                            onClick={handleTrySubmit}
                            type="submit"
                          >
                            <>Valider</>
                          </CustomButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              ) : null}
            </>
          </Modal>
        </ModalOverlay>
      )}
    </StrictMode>
  );
};

export default CseCollegeListCreate;
