window._token = null;

export const getToken = () => {
  if (process.env.NODE_ENV === "development") {
    return window.localStorage.getItem("token") || null;
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    return window._token || urlParams.get("token") || null;
  }
};

export const setToken = (token) => {
  if (process.env.NODE_ENV === "development") {
    window.localStorage.setItem("token", token);
  } else {
    window._token = token;
  }
};

export const removeToken = () => {
  if (process.env.NODE_ENV === "development") {
    window.localStorage.removeItem("token");
  } else {
    window._token = null;
  }
};
