import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import API from "../../utils/API";
import { UserContext } from "../../App";
import { Button, Label, Input } from "../../utils/styled-components-library";
import { toast } from "react-toastify";
import CustomButton from "../../components/buttons/CustomButton";

const CseDuplicate = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [loaded, setIsloaded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useContext(UserContext);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"clone"} />
          <CseLayout title={"Clonage"} showResults={loaded} loading={!loaded}>
            <>
              Tous les éléments du CSE seront clonés excepté les votants. Avant
              de cloner, il est impératif de lier les bureaux de votes aux
              collèges, si il en existe.
              {user.userType === "client" || user.userType === "clientAdmin" ? (
                <CustomButton
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    setIsSubmitting(true);
                    API.post(
                      "/cseRouteCRUD",
                      {
                        cseId: cse.cseId,
                        module: "duplicateCse",
                      },
                      (data: { success: boolean; data: any }) => {
                        setIsSubmitting(false);
                        if (data.success) {
                          toast.success(`Le CSE a bien été cloné!`);
                        } else {
                          toast.error(`Le CSE n'a pas pu être cloné`);
                        }
                      },
                      () => {
                        toast.error(`Le CSE n'a pas pu être cloné`);
                      }
                    );
                  }}
                >
                  Cloner
                </CustomButton>
              ) : (
                <div>Vous n'avez pas les droits nécessaires</div>
              )}
            </>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseDuplicate;
