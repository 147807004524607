import { MediumContainer } from "../utils/styled-components-library";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Back = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  zindex: 2;
  cursor: pointer;
  padding-left: 15;
`;

const BackToVote = () => {
  return (
    <MediumContainer>
      <Link to="/vote" style={{ textDecoration: "none", color: "black" }}>
        <Back>
          <img
            style={{
              objectFit: "contain",
            }}
            src="/img/back.svg"
            alt="retour"
          />
          <span>Retour</span>
        </Back>
      </Link>
    </MediumContainer>
  );
};

export default BackToVote;
