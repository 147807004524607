import { useEffect, useState } from "react";
import { CseType_Db } from "../types/cse";
import API from "../utils/API";
import * as Auth from "../utils/Auth";

const useCse = (cseId: number) => {
  const [cse, setSce] = useState<CseType_Db | null>(null);
  useEffect(() => {
    API.post(
      "/cseRouteCRUD",
      { cseId, path: "/getCse", module: "cse", token: Auth.getToken() },
      (data: any) => {
        setSce(data.data);
      }
    );
  }, []);
  return cse;
};

export default useCse;
