import React from "react";
import { cseNavigationSection } from "../../navigation/navigationConstants";
import classes from "./CseNavigation.module.scss";
import Link from "../../components/links/Link";
import useCse from "../../hooks/useCse";

const CseNavigation = ({ selectedTab = "", cseId = 0 }) => {
  const cse = useCse(cseId);

  return (
    <>
      {cse && (
        <div className={classes.CseNavigation}>
          <div className={classes.CseNavigation_container}>
            {cseNavigationSection.map((tab, ind) => (
              <React.Fragment key={ind}>
                {((tab.key != "generer-courrier" ||
                  (tab.key === "generer-courrier" &&
                    cse.isLetterCredential)) && (
                  <div key={ind} className={classes.CseNavigation_Link}>
                    <Link
                      selected={selectedTab === tab.key}
                      href={`/cse/${cseId}/${tab.key}`}
                    >
                      {tab.value}
                    </Link>{" "}
                    {ind !== cseNavigationSection.length - 1 && <span>•</span>}
                  </div>
                )) ||
                  null}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default CseNavigation;
