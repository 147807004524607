import React from "react";
import {
	MediumContainer,
	ListHeader,
	ListTitle,
	Button,
	Modal,
	ModalOverlay,
	ModalClose,
	Title,
	Label,
	Input,
	Error,
	ColoredMessage,
	SmallContainer,
	LargeContainer,
} from "../utils/styled-components-library";
import moment from "moment";
import AGMenu from "../components/AGMenu";
import API from "../utils/API";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { DatePickerField } from "../components/DatePicker";
import FileInput from "../components/FileInput";
import config from "../config";

const AttendanceTable = styled.table`
	padding: 20px 0;
	width: 100%;
	text-align: left;
	border-collapse: collapse;
`;
const AttendanceHeader = styled.tr`
	padding: 5px 0;
`;

const AttendanceHeaderField = styled.th`
	width: ${(props) => (props.width ? props.width : "100%")};
	text-align: ${(props) => (props.alignRight ? "right" : "left")};
	font-weight: bold;
`;

const AttendanceRow = styled.tr`
	border-bottom: 2px solid #ddd;
`;

const AttendanceField = styled.td`
	width: ${(props) => (props.width ? props.width : "100%")};
	padding: 5px 0;
	text-align: ${(props) => (props.alignRight ? "right" : "left")};
`;

class ClientAttendance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ag: null,
			attendances: null,
			customFields: null,
			hasAttendanceModuleEnabled: false,
			isLoading: false,
			showCreationModal: false,
			showCreationError: false,
			showDeleteAttendanceModal: false,
			showFieldsModal: false,
			selectedAttendance: null,
		};
	}

	getAgData() {
		API.get(
			"/agDetails",
			{
				agId: this.props.agId ? this.props.agId : this.props.match.params.agId,
			},
			(data) => {
				this.setState(
					{
						ag: data,
					},
					() => {
						if (this.state.ag.hasSignatureList) {
							this.setState({ hasAttendanceModuleEnabled: true });
							this.getAttendances();
						} else {
							this.setState({ redirect: `/ag/${this.state.ag.agId}` });
						}
					}
				);
			}
		);
	}

	getAttendances = () => {
		API.get(
			"/clientAttendancesDetails",
			{ agId: this.props.agId ? this.props.agId : this.state.ag.agId },
			(data) => {
				this.setState({
					attendances: data.attendances,
					customFields: data.customFields,
				});
			}
		);
	};

	computeRatio = (attendance) => {
		let expectedAttendance = 0;
		let actualAttendance = 0;
		attendance.attendanceVoters.map((voter) => {
			if (!voter.delegateVoterId) {
				expectedAttendance++;
				if (voter.status === "present") {
					actualAttendance += 1;
				}
			}
		});
		return actualAttendance + "/" + expectedAttendance;
	};

	computeRatioPowerCounts = (attendance) => {
		let expectedAttendance = 0;
		let actualAttendance = 0;
		attendance.attendanceVoters.map((voter) => {
			expectedAttendance += voter.voterPowerCount;
			if (!voter.delegateVoterId) {
				if (voter.status === "present") {
					actualAttendance += voter.voterPowerCount;
				}
			} else {
				let delegatedVoter = attendance.attendanceVoters.find(
					(el) => el.voterId == voter.delegateVoterId
				);
				if (delegatedVoter && delegatedVoter.status == "present") {
					actualAttendance += voter.voterPowerCount;
				}
			}
		});
		return actualAttendance + "/" + expectedAttendance;
	};

	componentDidMount() {
		this.interval = setInterval(() => this.getAgData(), 3000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	deleteAttendance() {
		API.get(
			"/attendance",
			{
				action: "delete",
				attendanceId: this.state.selectedAttendance.attendanceId,
			},
			(data) => {
				if (data.success) {
					this.setState({
						selectedAttendance: null,
						showDeleteAttendanceModal: false,
					});
					this.getAttendances();
				}
			}
		);
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} push />;
		}
		return (
			<>
				{this.state.hasAttendanceModuleEnabled ? (
					<>
						<MediumContainer>
							<ListHeader>
								<ListTitle>{this.state.ag && this.state.ag.agName}</ListTitle>
							</ListHeader>
							<AGMenu
								activeTab="Emargement"
								ag={this.state.ag}
								{...this.props}
							/>
						</MediumContainer>
						<LargeContainer>
							{this.state.ag &&
								this.state.ag.isClosed === 1 &&
								!this.state.ag.presidentId && (
									<div
										style={{
											color: "red",
											marginTop: "10px",
											fontWeight: "bold",
										}}
									>
										Cette session de vote est clôturée, vous ne pouvez plus y
										ajouter d'émargement.
									</div>
								)}
							{!this.state.ag.presidentId && (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										margin: "20px 0px",
									}}
								>
									<Button
										onClick={() => this.setState({ showCreationModal: true })}
										disabled={
											(this.state.ag && !this.state.ag.hasAttendance) ||
											(this.state.attendances &&
												this.state.attendances.length > 0 &&
												!this.state.ag.multipleAttendance) ||
											(this.state.ag && this.state.ag.isClosed === 1)
										}
									>
										{this.state.ag.multipleAttendance ||
										!this.state.ag.hasAttendance
											? "Créer un émargement"
											: this.state.attendances &&
											  this.state.attendances.length > 0
											? "Plus d'émargement disponible"
											: "Créer mon unique émargement"}
									</Button>
									<Button
										style={{ marginLeft: "15px" }}
										onClick={() => this.setState({ showFieldsModal: true })}
									>
										Gérer l'affichage des informations
									</Button>
								</div>
							)}

							<AttendanceTable>
								<thead>
									<AttendanceHeader>
										<AttendanceHeaderField width="15%">
											Date
										</AttendanceHeaderField>
										<AttendanceHeaderField width="15%">
											Titre
										</AttendanceHeaderField>
										<AttendanceHeaderField width="15%">
											Période
										</AttendanceHeaderField>
										<AttendanceHeaderField width="15%">
											Présents / Attendus (individus)
										</AttendanceHeaderField>
										<AttendanceHeaderField width="15%">
											Présents / Attendus (pouvoirs)
										</AttendanceHeaderField>
										<AttendanceHeaderField width="15%">
											Quorum
										</AttendanceHeaderField>
										<AttendanceHeaderField width="15%">
											Code
										</AttendanceHeaderField>
										<AttendanceHeaderField width="20%">
											Action
										</AttendanceHeaderField>
									</AttendanceHeader>
								</thead>
								<tbody>
									{!!this.state.attendances
										? this.state.attendances.map((attendance) => {
												return (
													<AttendanceRow key={attendance.attendanceId}>
														<AttendanceField width="15%">
															{moment(attendance.date).format("DD/MM/YYYY")}
														</AttendanceField>
														<AttendanceField width="15%">
															{attendance.label}
														</AttendanceField>
														<AttendanceField width="15%">
															{attendance.choice}
														</AttendanceField>
														<AttendanceField width="15%">
															{this.computeRatio(attendance)}
														</AttendanceField>
														<AttendanceField width="15%">
															{this.computeRatioPowerCounts(attendance)}
														</AttendanceField>
														<AttendanceField width="15%">
															{attendance.quorum &&
															attendance.quorum.value &&
															attendance.quorum.unit
																? attendance.quorum.value +
																  (attendance.quorum.unit.indexOf("%") > -1
																		? "%"
																		: "")
																: "-"}
															{attendance.quorum &&
															attendance.quorum.value &&
															attendance.quorum.unit
																? attendance.isQuorumReached
																	? " (atteint)"
																	: " (non atteint)"
																: ""}
														</AttendanceField>
														<AttendanceField width="15%">
															{attendance.code}
														</AttendanceField>
														<AttendanceField width="20%">
															{!this.state.ag.presidentId && (
																<Button
																	style={{
																		backgroundColor: "red",
																		padding: "5px",
																		width: "auto",
																	}}
																	onClick={() =>
																		this.setState({
																			showDeleteAttendanceModal: true,
																			selectedAttendance: attendance,
																		})
																	}
																>
																	Supprimer
																</Button>
															)}

															<a
																href={`${config.apiUrl}/attendanceDetails?codeEmargement=${attendance.code}&pdf=true`}
																target="_blank"
																rel="noreferrer"
															>
																<Button
																	style={{
																		backgroundColor: "green",
																		padding: "5px",
																		width: "100%",
																		marginTop: "5px",
																	}}
																>
																	Rapport PDF
																</Button>
															</a>
															<a
																href={`${config.apiUrl}/attendanceDetails?codeEmargement=${attendance.code}&xlsx=true`}
																target="_blank"
																rel="noreferrer"
															>
																<Button
																	style={{
																		backgroundColor: "green",
																		padding: "5px",
																		width: "100%",
																		marginTop: "5px",
																	}}
																>
																	Rapport XLSX
																</Button>
															</a>
														</AttendanceField>
													</AttendanceRow>
												);
										  })
										: null}
								</tbody>
							</AttendanceTable>
						</LargeContainer>
					</>
				) : null}

				{this.state.showCreationModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({
								showCreationModal: false,
								showCreationError: false,
							});
						}}
					>
						<Modal
							onClick={(e) => {
								e.stopPropagation();
							}}
							maxWidth="800"
						>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({
										showCreationModal: false,
										showCreationError: false,
									});
								}}
							/>
							<SmallContainer>
								<Title>Création d'un émargement</Title>
								<Formik
									initialValues={{
										date: moment.utc(),
										choice: "Matinée",
										label: "",
										voterList: "",
									}}
									onSubmit={(
										values,
										{ setSubmitting, setErrors, setValues }
									) => {
										this.setState({ isLoading: true });
										const postValues = {
											agId: this.props.match.params.agId,
											date: values.date,
											choice: values.choice,
											voterList: values.voterList,
											label: values.label,
										};
										API.post("/attendance", postValues, (data) => {
											setSubmitting(false);
											this.setState({ isLoading: false });
											if (data.success) {
												this.getAttendances();
												this.setState({
													showCreationModal: false,
													showCreationError: false,
												});
											} else if (data.message) {
												setErrors({ voterList: data.message });
											} else {
												this.setState({ showCreationError: true });
											}
										});
									}}
									validateOnMount
								>
									{({ isSubmitting, isValid }) => (
										<Form>
											<Label>Date</Label>
											<DatePickerField name="date" />
											<Label>
												Titre d'émargement{" "}
												<span style={{ fontSize: "12px" }}>(facultatif)</span>
											</Label>
											<Input name="label" />
											<Label>Option d'émargement</Label>

											<Input component="select" name="choice">
												{["Matinée", "Après-midi", "Toute la journée"].map(
													(x) => (
														<option key={x} value={x}>
															{x}
														</option>
													)
												)}
											</Input>

											<Label>
												Liste des attendus (format XLSX,{" "}
												<a href="/Modèle liste pour émargement.xlsx" download>
													télécharger le modèle
												</a>
												)
											</Label>
											<FileInput name="voterList" />
											<div
												style={{
													textAlign: "left",
													marginBottom: "15px",
													fontSize: "12px",
												}}
											>
												Ce fichier est facultatif et permet de sélectionner les
												inscrits de la session de vote qui seront intégrés à
												l'émargement. Si aucun fichier n'est ajouté, tous les
												inscrits à la session de vote seront ajoutés à
												l'émargement.
											</div>
											<Error name="voterList" component="div" />

											{this.state.isLoading ? (
												<Loader
													type="Bars"
													color="#1D529C"
													height={50}
													width={50}
												/>
											) : (
												<>
													<Button
														type="submit"
														onClick={this.submit}
														disabled={!isValid || isSubmitting}
													>
														Créer
													</Button>
													{this.state.showCreationError ? (
														<ColoredMessage color="red">
															La création de l'émargement n'a pas abouti,
															veuillez recommencer.
														</ColoredMessage>
													) : null}
												</>
											)}
											<Error name="createAttendance" component="div" />
										</Form>
									)}
								</Formik>
							</SmallContainer>
						</Modal>
					</ModalOverlay>
				) : null}

				{this.state.showDeleteAttendanceModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({
								showDeleteAttendanceModal: false,
							});
						}}
					>
						<Modal
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({
										showDeleteAttendanceModal: false,
									});
								}}
							/>
							<SmallContainer>
								<h3>Êtes-vous sûr(e) de vouloir supprimer cet émargement ?</h3>
								<div
									style={{
										color: "red",
										fontWeight: "500",
										marginBottom: "10px",
									}}
								>
									Attention, cette action est irréversible.
								</div>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<Button
										style={{ backgroundColor: "red", marginRight: "10px" }}
										onClick={() => this.deleteAttendance()}
									>
										Supprimer
									</Button>
									<Button
										style={{ backgroundColor: "green" }}
										onClick={() =>
											this.setState({ showDeleteAttendanceModal: false })
										}
									>
										Annuler
									</Button>
								</div>
							</SmallContainer>
						</Modal>
					</ModalOverlay>
				) : null}

				{this.state.showFieldsModal && (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({
								showFieldsModal: false,
							});
						}}
					>
						<Modal onClick={(e) => e.stopPropagation()}>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({
										showFieldsModal: false,
									});
								}}
							/>
							<Label style={{ textAlign: "center" }}>
								Choisissez la visibilité des champs sur la liste des
								participants :
							</Label>
							{this.state.customFields.map((customField) => {
								return (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginTop: "20px",
										}}
									>
										{customField.name} :
										<select
											component="select"
											name="visibility"
											onChange={(e) => {
												API.post(
													"/voterInfo",
													{
														action: "setCustomFieldVisibility",
														id: customField.id,
														hidden: e.target.value,
													},
													(data) => {
														if (data.success) {
															this.getAttendances();
														}
													}
												);
											}}
											style={{ marginLeft: "5px" }}
										>
											<option value="0" selected={customField.hidden == 0}>
												Visible
											</option>
											<option value="1" selected={customField.hidden == 1}>
												Caché
											</option>
										</select>
									</div>
								);
							})}
						</Modal>
					</ModalOverlay>
				)}
			</>
		);
	}
}

export default ClientAttendance;
