import React from "react";
import useCse from "../../hooks/useCse";
import CseCreate from "./CseCreate";
import CseHeader from "./CseHeader";

const CseDetails = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  return (
    cse && (
      <>
        <CseHeader cse={cse} selectedTab={"info"} />
        <CseCreate isEdit cse={cse} />
      </>
    )
  );
};

export default CseDetails;
