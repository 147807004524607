import React, { useEffect, useState } from "react";
import CustomButton from "../../components/buttons/CustomButton";
import useCse from "../../hooks/useCse";
import API from "../../utils/API";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";

const getRsaKeyBody = (rsaKey: string) => {
  return rsaKey
    .split("\n")
    .slice(1, rsaKey.split("\n").length - 2)
    .join("\n");
};

function downloadBase64File(contentBase64, fileName) {
  const mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = mediaType;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
}

const CsePreElectoral = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [csePreElectoral, setCsePreElectoral] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {cse && (
        <>
          <CseHeader
            cse={cse}
            selectedTab={"protocole-d-accord-pre-electoral"}
          />
          <CseLayout
            title={"Protocole d’accord pré-electoral"}
            loading={isLoading}
            NoResultText={""}
            buttonText={""}
            href={`#`}
            showResults
          >
            <>
              {/* 
              <CustomButton
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  API.post(
                    "/cseRouteCRUD",
                    {
                      cseId,
                      module: "attendanceExportXLS",
                      path: "/xls",
                    },
                    (data: { success: boolean; data: any }) => {
                      console.log("DATA : ", data.data.body);
                      downloadBase64File(data.data.body, "Rapport émargement");
                    },
                    () => {}
                  );
                }}
              >
                <>test</>
              </CustomButton>
              */}
            </>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CsePreElectoral;
