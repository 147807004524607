import classes from "./AccordionTitle.module.scss";
import cn from "classnames";

const AccordionTitle = ({
  type = "white",
  left = <></>,
  right = <></>,
  separator = "",
}) => {
  return (
    <div
      className={cn(classes.AccordionTitle, {
        [classes.AccordionTitle__white]: type === "white",
        [classes.AccordionTitle__blue]: type === "blue",
        [classes.AccordionTitle__grey]: type === "grey",
      })}
    >
      <div className={classes.left}>{left}</div>
      {separator && <div className={classes.left}>{separator}</div>}
      <div className={classes.right}>{right}</div>
    </div>
  );
};

export default AccordionTitle;
