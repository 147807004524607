import styled from "styled-components";
import React, { useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "../utils/styled-components-library";

function useWindowDimensions() {
	const hasWindow = typeof window !== "undefined";

	function getWindowDimensions() {
		const width = hasWindow ? window.innerWidth : null;
		const height = hasWindow ? window.innerHeight : null;
		return {
			width,
			height,
		};
	}

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		if (hasWindow) {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}
	}, [hasWindow, getWindowDimensions]);

	return windowDimensions;
}

function useCombinedRefs(...refs) {
	const targetRef = React.useRef();

	React.useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) return;

			if (typeof ref === "function") {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
}

const CanvasContainer = styled.div`
	border: 2px solid #ddd;
	margin: auto;
	position: relative;
`;

export default React.forwardRef((props, ref) => {
	const innerRef = React.useRef(ref);
	const combinedRef = useCombinedRefs(ref, innerRef);
	const { width } = useWindowDimensions();

	const canvasWidth = Math.min(width, 500) - 70;
	const canvasHeight = canvasWidth * 0.5;

	return (
		<>
			<CanvasContainer
				style={{
					width: canvasWidth,
					height: canvasHeight,
				}}
			>
				<SignatureCanvas
					canvasProps={{
						width: canvasWidth,
						height: canvasHeight,
					}}
					ref={combinedRef}
				/>
			</CanvasContainer>
			<Button
				maxWidth="504px"
				style={{ marginTop: 5 }}
				onClick={() => {
					combinedRef.current.clear();
				}}
			>
				Effacer
			</Button>
		</>
	);
});
