import React, { useEffect, useContext, useState } from "react";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import API from "../../utils/API";
import CseLayout from "./CseLayout";
import CustomButton from "../../components/buttons/CustomButton";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import FileInput from "../../components/FileInput";
import {
  Button,
  ClassicInput,
  Error,
  Input,
  Label,
  Modal,
  ModalClose,
  ModalOverlay,
} from "../../utils/styled-components-library";
import { CseVoterType_Db } from "../../types/cseVoter";
import { UserContext } from "../../App";

const SendCodeButton = ({ cseVoterId, cseId, path }) => {
  const [isSendingCode, setIsSendingCode] = useState(false);
  let type = ""
  if (path === "/sms") type = "SMS"
  else if (path === "/email") type = "Email"
  else if (path === "/all") type = "SMS et Email"
  else if (path === "/letter") type = "Courrier"
  console.log("sendCode", cseVoterId, cseId, path)

  const sendCode = (cseVoterId, cseId, path) => {
    console.log("sendCode", cseVoterId, cseId, path)
    setIsSendingCode(true);
    API.post(
      "/cseRouteCRUD",
      {
        module: "cseSend",
        path,
        cseVoterId,
        cseId,
      },
      (data) => {
        setIsSendingCode(false);
        if (data.success) {
          toast.success("Code envoyé !");
          setIsSendingCode(false);
          if (data.url) {
            window.open(data.url, "_blank").focus();
          }
        } else {
          toast.error(data.message);
        }
      },
      () => {
        setIsSendingCode(false);
        toast.error("Erreur interne, veuillez réessayer plus tard");
      }
    );
  };
  return (
    <CustomButton
      disabled={isSendingCode}
      onClick={() => sendCode(cseVoterId, cseId, path)}
      style={{ width: "auto", marginTop: -8, minWidth: 315 }}
    >
      <>Envoyer un nouveau code ({type})</>
    </CustomButton>
  );
};

const CseVotersList = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const [cseVoters, setCseVoters] = useState<CseVoterType_Db[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isOpenAddVoters, setIsOpenAddVoters] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [addListErrorMessage, setAddListErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredVoters, setFileredVoters] = useState([]);
  const cse = useCse(cseId);
  const [showPdfGeneratingModal, setShowPdfGeneratingModal] = useState(false);
  const user = useContext(UserContext);
  const isAdmin = user.userType === "client" || user.userType === "clientAdmin";

  useEffect(() => {
    setIsLoading(false);
    API.get(
      "/getCseVotersList",
      { cseId },
      (data: { success: boolean; data: any }) => {
        setCseVoters(data.data);
        setIsLoading(false);
      }
    );
  }, [refresh]);

  useEffect(() => {
    if (!searchTerm) {
      setFileredVoters(cseVoters);
    } else {
      setFileredVoters(
        cseVoters.filter((voter) => {
          const loweredSearchTerm = searchTerm.toLocaleLowerCase();
          if (
            voter.cseVoterEmail &&
            voter.cseVoterEmail.toLocaleLowerCase().includes(loweredSearchTerm)
          )
            return true;
          if (
            voter.cseVoterLastname &&
            voter.cseVoterLastname
              .toLocaleLowerCase()
              .includes(loweredSearchTerm)
          )
            return true;
          return false;
        })
      );
    }
  }, [searchTerm, cseVoters]);

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"liste-des-votants"} />
          <CseLayout
            title={"Liste des votants"}
            loading={isLoading}
            NoResultText={"Aucun votant enregistré pour ce scrutin"}
            buttonText={
              cse.isLetterCredential ? "Télécharger la liste des votants" : ""
            }
            onClick={() => {
              API.get("/getCse", { path: "/getCse", cseId }, (data: any) => {
                if (!data.data.credentialsPdfUrl) {
                  setShowPdfGeneratingModal(true);
                } else {
                  window.open(data.data.credentialsPdfUrl, "_blank").focus();
                }
              });
            }}
            href={`#`}
            showResults={!!cseVoters.length}
            alwaysShowChildren
          >
            <>
              {(isAdmin && (
                <CustomButton onClick={() => setIsOpenAddVoters(true)}>
                  <>Ajouter des votants</>
                </CustomButton>
              )) ||
                null}
              <div style={{ width: "100%" }}>
                <Label style={{ textAlign: "left" }}>Rechercher</Label>
                <ClassicInput
                  component="text"
                  name="delegateVoterId"
                  onChange={(e) => setSearchTerm(e.target.value)}
                ></ClassicInput>
              </div>

              {filteredVoters.map((voter) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <strong>
                        {voter.cseVoterLastname + " " + voter.cseVoterFirstname}
                      </strong>
                    </div>
                    <div>
                      <strong>{voter.collegeName}</strong>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Pour afficher les éléments en colonnes
                      width: "100%",
                      gap: "10px"
                    }}
                  >
                    {voter.cseVoterEmail && cse.isEmailCredential && (
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                        <i>{voter.cseVoterEmail}</i>
                        {isAdmin && (
                          <SendCodeButton cseVoterId={voter.cseVoterId} cseId={match.params.cseId} path={"/email"} />
                        )}
                      </div>
                    ) || null}
                    {voter.cseVoterPhone && cse.isSmsCredential && (
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                        <i>+{voter.cseVoterPhone}</i>
                        {isAdmin && (
                          <SendCodeButton cseVoterId={voter.cseVoterId} cseId={match.params.cseId} path={"/sms"} />
                        )}
                      </div>
                    ) || null}
                    {voter.cseVoterEmail && voter.cseVoterPhone && cse.isEmailCredential && cse.isSmsCredential && (
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                        <div></div>
                        {isAdmin && (
                          <SendCodeButton cseVoterId={voter.cseVoterId} cseId={match.params.cseId} path={"/all"} />
                        )}
                      </div>
                    ) || null}
                    {cse.isLetterCredential && (
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                        <div></div>
                        {isAdmin && (
                          <SendCodeButton cseVoterId={voter.cseVoterId} cseId={match.params.cseId} path={"/letter"} />
                        )}
                      </div>
                    ) || null}
                    <hr/>
                  </div>
                </>
              ))}
            </>
          </CseLayout>
          {isOpenAddVoters && (
            <ModalOverlay
              onClick={() => {
                setIsOpenAddVoters(false);
                setAddListErrorMessage("");
              }}
            >
              <Modal onClick={(e) => e.stopPropagation()}>
                <ModalClose
                  onClick={() => {
                    setIsOpenAddVoters(false);
                    setAddListErrorMessage("");
                  }}
                />
                <Formik
                  initialValues={{ votersList: "", votersListFilename: "" }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.votersList) {
                      errors.votersList = "Requis";
                    }
                    return errors;
                  }}
                  onSubmit={async (values, { setErrors }) => {
                    setIsSubmitting(true);
                    API.post(
                      "/cseVotersList",
                      {
                        ...values,
                        cseId: cse.cseId,
                        credentialsSendingType: cse.credentialsSendingType,
                      },
                      (data) => {
                        setIsSubmitting(false);
                        if (data.success) {
                          toast.success("Votants ajoutés !");
                          setRefresh(refresh + 1);
                          setIsOpenAddVoters(false);
                          setAddListErrorMessage("");
                        } else {
                          setAddListErrorMessage(data.error);
                        }
                      }
                    );
                  }}
                  validateOnMount
                >
                  {({ values }) => (
                    <Form autoComplete="off">
                      <div>
                        <Label>
                          Liste des votants (format XLSX,{" "}
                          <a href="/Modèle liste pour CSE.xlsx" download>
                            télécharger le modèle)
                          </a>
                        </Label>
                        <FileInput
                          accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          name="votersList"
                          tmp={true}
                          filename={{
                            key: "votersListFilename",
                            value: values.votersListFilename,
                          }}
                          customOnChange={() => {
                            setIsFileUploading(true);
                          }}
                          onLoadingComplete={() => {
                            setIsFileUploading(false);
                          }}
                        />
                        <Error name="votersList" component="div" />
                        {addListErrorMessage.split("\n\n").map(
                          (message) =>
                            message && (
                              <li
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: "5px",
                                }}
                              >
                                {message}
                              </li>
                            )
                        )}
                        <CustomButton
                          disabled={isSubmitting || isFileUploading}
                          type="submit"
                        >
                          <>Ajouter</>
                        </CustomButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal>
            </ModalOverlay>
          )}
        </>
      )}

      {showPdfGeneratingModal && (
        <ModalOverlay
          onClick={() => {
            window.location.hash = "";
            setShowPdfGeneratingModal(false);
          }}
        >
          <Modal onClick={(e) => e.stopPropagation()}>
            <ModalClose
              onClick={() => {
                window.location.hash = "";
                setShowPdfGeneratingModal(false);
              }}
            />
            <h4>
              Le PDF est en cours de génération par ProVote, cela peut prendre
              quelques minutes. Veuillez réessayer dans quelques instants.
            </h4>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                onClick={() => {
                  window.location.hash = "";
                  setShowPdfGeneratingModal(false);
                }}
              >
                Fermer
              </Button>
            </div>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
};

export default CseVotersList;
