import React, { useEffect, useState } from "react";
import API from "../utils/API";
import { toast } from "react-toastify";

const sortReverseByKey = (data: any, key: string) => {
  return data.sort((a, b) => b[key].localeCompare(a[key]));
};

const useCseVoteReceipts = (user) => {
  const [voteReceipts, setVoteReceipts] = useState([]);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    API.get(
      "/cseVoteReceipts",
      {},
      (data) => {
        setIsReady(true);
        if (data.success) {
          setVoteReceipts(data.data);
        } else {
          toast.error(data.message);
        }
      },
      () => {
        setIsReady(true);
        toast.error("Erreur interne, veuillez réessayer plus tard");
      }
    );
  }, [user]);

  const votedReceipts = [];
  const notVotedReceipts = [];
  const electionResults = !isReady
    ? []
    : user?.electionNames?.map((electionName) => {
        const receipts =
          voteReceipts.filter(
            (receipt) => receipt.collegeListElectionName === electionName
          ) || [];
        const result = {
          collegeListElectionName: electionName,
          receipts,
        };
        if (receipts.length) {
          votedReceipts.push(result);
        } else {
          notVotedReceipts.push(result);
        }
        return result;
      });

  return {
    isReady,
    votedReceipts: sortReverseByKey(votedReceipts, "collegeListElectionName"),
    notVotedReceipts: sortReverseByKey(
      notVotedReceipts,
      "collegeListElectionName"
    ),
  };
};

export default useCseVoteReceipts;
