import React from "react";
import classes from "./Accordion.module.scss";
import cn from "classnames";

const Accordion = ({
  title = <></>,
  children = <></>,
  alwaysClosed = false,
  initiallyOpen = false,
  color = "white",
}) => {
  const [isOpen, setOpen] = React.useState(initiallyOpen);
  return (
    <div className={classes.Accordion}>
      <div
        className={cn(classes.Accordion_titleContainer, {
          [classes.Accordion_titleContainer__white]: color === "white",
          [classes.Accordion_titleContainer__blue]: color === "blue",
          [classes.Accordion_titleContainer__grey]: color === "grey",
          [classes.Accordion_titleContainer__alwaysClosed]: alwaysClosed,
        })}
        onClick={() => setOpen(alwaysClosed ? initiallyOpen : !isOpen)}
      >
        {title}
        <div className={cn(classes.PlusContainer)}>
          {!alwaysClosed && (
            <div
              aria-expanded={isOpen}
              className={cn(classes.PlusContainer_plus)}
            ></div>
          )}
        </div>
      </div>
      <div
        className={cn(classes.Accordion_item, {
          [classes.Accordion_item__collapsed]: !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
