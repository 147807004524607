import React, { useContext } from "react";
import styled from "styled-components";
import { HelperContext } from "../App";

const TooltipsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  z-index: 200;
  position: relative;
  width: 100%;
`;

const TooltipText = styled.div`
  text-align: left;
  line-height: 1.5em;
  padding: 8px;
  gap: 10px;
  background: #ff5c00;
  border-radius: 4px;
  color: white;
  width: 100%;
`;

const TooltipsArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ff5c00;
`;

const Next = styled.div`
  color: white;
  border: 1px solid white;
  padding: 5px;
  cursor: pointer;
  width: min-content;
`;

const ButtonContainer = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
`;

export const Tooltips = ({
  children = <></>,
  tooltipNumber,
  handleNext,
  step,
}: {
  children: any;
  tooltipNumber: number;
  handleNext?: any;
  step?: number;
}) => {
  console.log("Tooltips numer : ", tooltipNumber);

  return (
    <>
      {(tooltipNumber === 1 && (
        <TooltipsContainer>
          <TooltipsArrow></TooltipsArrow>
          <TooltipText>
            {children}
            {step && handleNext && (
              <ButtonContainer>
                <Next
                  onClick={() => {
                    handleNext(step + 1);
                  }}
                >
                  Suivant
                </Next>
              </ButtonContainer>
            )}
          </TooltipText>
        </TooltipsContainer>
      )) ||
        null}
    </>
  );
};
