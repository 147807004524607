import { useHistory } from "react-router-dom";
import classes from "./Link.module.scss";
import cn from "classnames";

const Link = ({ href = "", children, selected = false, type = "" }) => {
  const history = useHistory();
  return (
    <button
      className={cn(classes.Link, {
        [classes.Link__selected]: selected,
        [classes.Link__streamlined]: type === "streamlined",
      })}
      onClick={() => history.push(href)}
    >
      {children}
    </button>
  );
};

export default Link;
