export const cseNavigationSection = [
  { key: "info", value: "Informations du scrutin" },
  { key: "info-entreprise", value: "Informations de l'entreprise" },
  { key: "colleges", value: "Paramétrage des collèges" },
  {
    key: "protocole-d-accord-pre-electoral",
    value: "Protocole d’accord pré-électoral",
  },
  { key: "acces-autorises", value: "Accès autorisés" },
  { key: "bureaux-de-vote", value: "Bureaux de vote" },
  { key: "emargement", value: "Émargement" },
  { key: "liste-des-votants", value: "Liste des votants" },
  { key: "generer-courrier", value: "Générer les courriers" },
  { key: "depouillement", value: "Dépouillement" },
  { key: "resultats", value: "Résultats" },
  { key: "cerfa", value: "CERFA" },
  { key: "clone", value: "Cloner" },
  { key: "supprimer", value: "Supprimer" },
];
