import React, { StrictMode, useContext, useEffect, useState } from "react";
import classes from "./CseCollegeCreate.module.scss";
import {
  Input,
  Label,
  Error,
  Modal,
  ModalOverlay,
  MediumContainer,
} from "../../utils/styled-components-library";
import { Formik, Form, FormikProps } from "formik";
import API from "../../utils/API";
import cn from "classnames";

import CustomButton from "../../components/buttons/CustomButton";
import {
  CseCollegeType_Db,
  CseCollegeType_Params,
  CseCollegeType_Params__Error,
} from "../../types/cseCollege";
import CseCollegeListCreate from "./CseCollegeListCreate";
import config from "../../config";
import { CseCollegeListType_Params } from "../../types/cseCollegeList";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { ElectionListDocuments } from "./cseVote/CseVote";
import { UserContext } from "../../App";
import { StyledLink } from "../../utils/styled-components-library";

const convertCseCandidateToDbType = (candidate) => {
  return {
    cseCandidateId: candidate.cseCandidateId,
    candidateSex: candidate.sex,
    candidateLastname: candidate.lastname,
    candidateFirstname: candidate.firstname,
    candidateImage: candidate.image,
    candidateImageFilename: candidate.imageFilename,
    candidateVideo: candidate.video,
    candidateVideoFilename: candidate.videoFilename,
    candidateManifesto: candidate.manifesto,
    candidateManifestoFilename: candidate.manifestoFilename,
    candidateOrder: candidate.order,
    candidateCustomField: candidate.customField,
    candidateAge: candidate.candidateAge,
    candidateSeniority: candidate.candidateSeniority,
  };
};

export const convertCseCollegeListToDbType = (collegeList) => {
  return {
    cseCollegeListId: collegeList.cseCollegeListId,
    collegeListElectionName: collegeList.electionName,
    collegeListName: collegeList.name,
    collegeListImage: collegeList.image,
    collegeListImageFilename: collegeList.imageFilename,
    collegeListManifesto: collegeList.manifesto,
    collegeListManifestoFilename: collegeList.manifestoFilename,
    collegeListAffiliation: collegeList.affiliation,
    collegeListCampaignVideo: collegeList.campaignVideo,
    collegeListCampaignVideoFilename: collegeList.campaignVideoFilename,
    cseCollegeListDocuments: collegeList.documents,
    cseCandidates: collegeList.candidates
      .sort((a, b) => a.order - b.order)
      .map((candidate) => convertCseCandidateToDbType(candidate)),
  };
};

const RenderList = ({
  collegeList,
  setSelectedList,
  setIsOpenEditListModal,
  handleDeleteList,
  setListOrder,
  listOrder,
  index,
}: {
  collegeList: CseCollegeListType_Params;
  setSelectedList: any;
  setIsOpenEditListModal: any;
  handleDeleteList: any;
  setListOrder: any;
  listOrder: any;
  index: any;
}) => {
  const [isOpenValidation, setIsOpenValidation] = useState(false);
  const toggleIsOpenValidation = () => setIsOpenValidation(!isOpenValidation);
  const [isOpenListPreview, setIsOpenListPreview] = useState(false);

  const toggleListPreview = () => setIsOpenListPreview(!isOpenListPreview);
  const user = useContext(UserContext);

  return (
    <div className={classes.CollegeList}>
      <ElectionListDocuments
        onClose={toggleListPreview}
        isOpen={isOpenListPreview}
        collegeList={convertCseCollegeListToDbType(collegeList)}
      />
      <div className={classes.ListInfoContainer}>
        <img
          style={{
            objectFit: "contain",
            height: "56px",
            width: "56px",
          }}
          src={config.s3BaseUrl + collegeList.image}
          alt="Entité"
        />
        <div className={classes.CollegeList_titleContainer}>
          <span className={classes.name}>{collegeList.name}</span>
          <span className={classes.affiliation}>
            {" "}
            {collegeList.affiliation} {`(Liste n° ${collegeList.listOrder})`}
          </span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 6,
        }}
      >
        <StyledLink
          underline
          style={{
            cursor: "pointer",
            color: "#1D529C",
            display: "contents",
          }}
          onClick={toggleListPreview}
        >
          Voir
          <img
            style={{
              marginLeft: 5,
              objectFit: "contain",
              maxWidth: "30px",
            }}
            src={"/img/focus-eye.svg"}
            alt="Modifier le collège"
          />
        </StyledLink>

        {(user.userType === "client" || user.userType === "clientAdmin") && (
          <>
            <CustomButton
              style={{
                width: "auto",
              }}
              onClick={() => {
                setSelectedList(collegeList);
                setIsOpenEditListModal(true);
                setListOrder(index + 1);
              }}
              invert
            >
              <>Modifier</>
            </CustomButton>
            <CustomButton
              style={{
                backgroundColor: "red",
                color: "white",
                width: "auto",
                border: "1px solid red",
              }}
              onClick={toggleIsOpenValidation}
              invert
            >
              <>Supprimer</>
            </CustomButton>
          </>
        )}
        {isOpenValidation && (
          <ModalOverlay>
            <Modal
              style={{ marginTop: "10%" }}
              smModal
              onClick={(e: any) => e.stopPropagation()}
            >
              <h3>
                La liste, ses candidats et les résultats associés seront
                supprimés
              </h3>
              <CustomButton
                style={{ width: "auto", marginRight: 20 }}
                invert
                onClick={toggleIsOpenValidation}
              >
                <>Ne pas supprimer la liste</>
              </CustomButton>
              <CustomButton
                style={{ width: "auto" }}
                onClick={() => {
                  handleDeleteList(collegeList);
                  toggleIsOpenValidation();
                }}
              >
                <>Supprimer la liste</>
              </CustomButton>
            </Modal>
          </ModalOverlay>
        )}
      </div>
    </div>
  );
};

const CseCollegeCreate = ({
  match,
  isEdit = false,
  college,
}: {
  match: any;
  isEdit: boolean;
  college: CseCollegeType_Db;
}) => {
  const [isOpenValidation, setIsOpenValidation] = useState(false);
  const toggleIsOpenValidation = () => setIsOpenValidation(!isOpenValidation);

  const history = useHistory();
  const cseId = match.params.cseId;
  const cseCollegeId = match.params.cseCollegeId;
  const [isSubmitting, setIsSubmitting] = useState(false);

  /*
  // prevent reload / page leave
  window.onbeforeunload = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = "";
    }
    return "";
  };
  */

  const initialValuesTemplate = {
    numberOfTitulars: 0,
    numberOfSubstitutes: 0,
    name: "",
    collegeType: "-1",
    collegeComposition: [],
  } as CseCollegeType_Params;

  const [initialValues, setInitialValues] = useState<CseCollegeType_Params>(
    initialValuesTemplate
  );
  const [isReady, setIsReady] = useState(false);
  const [isOpenCreatListModal, setIsOpenCreatListModal] = useState(false);
  const [listOrder, setListOrder] = useState(-1);
  const [isOpenEditListModal, setIsOpenEditListModal] = useState(false);
  const [collegeLists, setCollegeLists] = useState<CseCollegeListType_Params[]>(
    []
  );
  const [selectedList, setSelectedList] = useState<CseCollegeListType_Params>();
  const user = useContext(UserContext);

  const handleSubmitCollegeList =
    ({ isEdit }: { isEdit: boolean }) =>
    (currentCollegeList: CseCollegeListType_Params) => {
      if (isEdit) {
        setCollegeLists(
          collegeLists.map((list: CseCollegeListType_Params) =>
            list.index === currentCollegeList.index ? currentCollegeList : list
          )
        );
      } else {
        setCollegeLists([...collegeLists, currentCollegeList]);
      }
    };

  const handleDeleteList = (collegeListParam: CseCollegeListType_Params) => {
    const toDelete = collegeLists.find((x) => {
      return x.index === collegeListParam.index;
    });
    const lists = collegeLists
      .filter(
        (currentCollegeLists) =>
          collegeListParam.index !== currentCollegeLists.index
      )
      .map((list) => {
        if (
          list.electionName === toDelete?.electionName &&
          list.listOrder > toDelete.listOrder
        ) {
          list.listOrder = list.listOrder - 1;
        }
        return list;
      });
    setCollegeLists(lists);
  };

  const highlightInput = (
    values: FormikProps<CseCollegeType_Params>,
    index: number
  ) => {
    return values.values.collegeComposition &&
      values.values.collegeComposition.find((x: string) => x === String(index))
      ? {
          background: "aliceblue",
          display: "flex",
          justifyContent: "space-between",
          margin: "5px 6px",
          padding: "6px",
        }
      : {
          display: "flex",
          justifyContent: "space-between",
          margin: "5px 6px",
          padding: "6px",
        };
  };

  useEffect(() => {
    (async () => {
      if (isEdit) {
        API.post(
          "/cseRouteCRUD",
          { cseId, cseCollegeId, path: "/getCollege", module: "college" },
          (data: { success: boolean; data: CseCollegeType_Db }) => {
            const college = data.data;
            setInitialValues({
              name: college.collegeName,
              numberOfTitulars: college.collegeNumberOfTitulars,
              numberOfSubstitutes: college.collegeNumberOfSubstitutes,
              collegeType: college.collegeType,
              collegeComposition: college.collegeComposition
                ? college.collegeComposition.split(",")
                : [],
            });
            setCollegeLists(
              college.cseCollegeLists
                .sort((a, b) => {
                  return a.listOrder - b.listOrder;
                })
                .map((collegeList, index) => ({
                  electionName: collegeList.collegeListElectionName,
                  cseCollegeListId: collegeList.cseCollegeListId,
                  index: collegeList.cseCollegeListId,
                  name: collegeList.collegeListName,
                  image: collegeList.collegeListImage,
                  imageFilename: collegeList.collegeListImageFilename,
                  manifesto: collegeList.collegeListManifesto,
                  manifestoFilename: collegeList.collegeListManifestoFilename,
                  affiliation: collegeList.collegeListAffiliation,
                  campaignVideo: collegeList.collegeListCampaignVideo,
                  hasCandidate: collegeList.hasCandidate,
                  campaignVideoFilename:
                    collegeList.collegeListCampaignVideoFilename,
                  documents: collegeList.cseCollegeListDocuments,
                  listOrder: collegeList.listOrder,
                  candidates: collegeList.cseCandidates.map(
                    (cseCandidate, ind) => ({
                      cseCandidateId: cseCandidate.cseCandidateId,
                      index: cseCandidate.cseCandidateId,
                      sex: cseCandidate.candidateSex,
                      lastname: cseCandidate.candidateLastname,
                      firstname: cseCandidate.candidateFirstname,
                      image: cseCandidate.candidateImage,
                      imageFilename: cseCandidate.candidateImageFilename,
                      video: cseCandidate.candidateVideo,
                      videoFilename: cseCandidate.candidateVideoFilename,
                      manifesto: cseCandidate.candidateManifesto,
                      manifestoFilename:
                        cseCandidate.candidateManifestoFilename,
                      order: cseCandidate.candidateOrder || 0,
                      customField: cseCandidate.candidateCustomField,
                      candidateAge: cseCandidate.candidateAge,
                      candidateSeniority: cseCandidate.candidateSeniority,
                    })
                  ),
                }))
            );
            setIsReady(true);
          }
        );
      } else {
        setIsReady(true);
      }
    })();
  }, [isEdit]);
  return (
    <StrictMode>
      <MediumContainer style={{ "max-width": 500 }}>
        <h1 className={classes.title}>
          {user.userType === "client" || user.userType === "clientAdmin"
            ? isEdit
              ? "Modifier le collège"
              : "Créer un collège"
            : " Voir le collège"}
        </h1>

        {isReady && (
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: Partial<CseCollegeType_Params__Error> = {};

              if (values.numberOfTitulars < 0) {
                errors.numberOfTitulars = "La valeur doit être positive";
              }
              if (values.numberOfSubstitutes < 0) {
                errors.numberOfSubstitutes = "La valeur doit être positive";
              }
              if (!values.name) {
                errors.name = "Requis";
              }

              return errors;
            }}
            onSubmit={async (values, { setErrors }) => {
              setIsSubmitting(true);
              console.log("Values", values);
              if (isEdit) {
                API.post(
                  "/cseRouteCRUD",
                  {
                    ...values,
                    cseId,
                    cseCollegeId,
                    collegeLists,
                    path: "/updateCollege",
                    module: "college",
                  },
                  (data) => {
                    setIsSubmitting(false);
                    if (data.success) {
                      toast.success("La modification a été prise en compte !");
                      history.push(`/cse/${cseId}/colleges`);
                    } else {
                      toast.error(data.error);
                    }
                  }
                );
              } else {
                API.post(
                  "/cseRouteCRUD",
                  {
                    ...values,
                    cseId,
                    collegeLists,
                    path: "createCollege",
                    module: "college",
                  },
                  (data) => {
                    setIsSubmitting(false);
                    if (data.success) {
                      history.push(`/cse/${cseId}/colleges`);
                    } else {
                      toast.error(data.error);
                    }
                  }
                );
              }
            }}
            validateOnMount
          >
            {(values) => (
              <>
                {console.log("Values :", values.values.collegeComposition)}
                <Form className={classes.form} autoComplete="off">
                  <div>
                    <Label>Sous-titre*</Label>
                    <Input name="name" placeholder="Libellé du collège" />
                    <Error name="name" component="div" />
                  </div>

                  <div>
                    <Label>Nombre de sièges titulaires*</Label>
                    <Input name="numberOfTitulars" type="number" min="0" />
                    <Error name="numberOfTitulars" component="div" />
                  </div>

                  <div>
                    <Label>Nombre de sièges suppléants*</Label>
                    <Input name="numberOfSubstitutes" type="number" min="0" />
                    <Error name="numberOfSubstitutes" component="div" />
                  </div>
                  <div>
                    <Label>Type de collège</Label>
                    <Input component="select" name="collegeType">
                      {[
                        { value: -1, display: "" },
                        { value: 0, display: "Collège unique" },
                        {
                          value: 1,
                          display: "1 er collège : ouvriers, employés",
                        },
                        {
                          value: 2,
                          display:
                            "2 ème collège : techniciens, agents de maîtrise, ingénieurs et cadres",
                        },
                        {
                          value: 3,
                          display: "3 ème collège : ingénieurs et cadres",
                        },
                        {
                          value: 4,
                          display: "Autre collège",
                        },
                      ].map((x) => (
                        <option key={x.display} value={x.value}>
                          {x.display}
                        </option>
                      ))}
                    </Input>
                    <Error name="numberOfSubstitutes" component="div" />
                  </div>
                  <div style={{ marginBottom: 15 }}>
                    <Label>Composition du collège : </Label>
                    <div role="group" aria-labelledby="checkbox-group">
                      <div style={highlightInput(values, 1)}>
                        <>Ouvriers</>

                        <Input
                          type="checkbox"
                          name="collegeComposition"
                          value="1"
                          style={{ width: "auto", margin: 5 }}
                        />
                      </div>

                      <div style={highlightInput(values, 2)}>
                        <>Employés</>

                        <Input
                          type="checkbox"
                          name="collegeComposition"
                          value="2"
                          style={{ width: "auto", margin: 5 }}
                        />
                      </div>

                      <div style={highlightInput(values, 3)}>
                        <>Techniciens</>

                        <Input
                          type="checkbox"
                          name="collegeComposition"
                          value="3"
                          style={{ width: "auto", margin: 5 }}
                        />
                      </div>

                      <div style={highlightInput(values, 4)}>
                        <>Agents de maîtrise</>

                        <Input
                          type="checkbox"
                          name="collegeComposition"
                          value="4"
                          style={{ width: "auto", margin: 5 }}
                        />
                      </div>

                      <div style={highlightInput(values, 5)}>
                        <>Ingénieurs</>

                        <Input
                          type="checkbox"
                          name="collegeComposition"
                          value="5"
                          style={{ width: "auto", margin: 5 }}
                        />
                      </div>

                      <div style={highlightInput(values, 6)}>
                        <>Cadres</>

                        <Input
                          type="checkbox"
                          name="collegeComposition"
                          value="6"
                          style={{ width: "auto", margin: 5 }}
                        />
                      </div>

                      <div style={highlightInput(values, 7)}>
                        <>Autres</>

                        <Input
                          type="checkbox"
                          name="collegeComposition"
                          value="7"
                          style={{ width: "auto", margin: 5 }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Label>Listes syndicales*</Label>
                    <>
                      <p style={{ textAlign: "left" }}>Titulaires</p>
                      {collegeLists
                        .filter(
                          (collegeLists) =>
                            collegeLists.electionName === "Titulaire"
                        )
                        .map((collegeList, index) => (
                          <RenderList
                            collegeList={collegeList}
                            setSelectedList={setSelectedList}
                            setIsOpenEditListModal={setIsOpenEditListModal}
                            handleDeleteList={handleDeleteList}
                            listOrder={listOrder}
                            setListOrder={setListOrder}
                            index={index}
                          />
                        ))}
                      <p style={{ textAlign: "left" }}>Suppléantes</p>

                      {collegeLists
                        .filter(
                          (collegeLists) =>
                            collegeLists.electionName === "Suppléant"
                        )
                        .map((collegeList, index) => (
                          <RenderList
                            collegeList={collegeList}
                            setSelectedList={setSelectedList}
                            setIsOpenEditListModal={setIsOpenEditListModal}
                            handleDeleteList={handleDeleteList}
                            listOrder={listOrder}
                            setListOrder={setListOrder}
                            index={index}
                          />
                        ))}
                    </>
                    {(user.userType === "client" ||
                      user.userType === "clientAdmin") && (
                      <CustomButton
                        onClick={() => {
                          setIsOpenCreatListModal(true);
                        }}
                        invert
                      >
                        <>Créer une liste</>
                      </CustomButton>
                    )}
                  </div>

                  <div className={cn(classes.buttonContainer)}>
                    {((user.userType === "client" ||
                      user.userType === "clientAdmin") && (
                      <CustomButton white onClick={toggleIsOpenValidation}>
                        <>Retour</>
                      </CustomButton>
                    )) || (
                      <CustomButton href={`/cse/${cseId}/colleges`}>
                        <>Retour</>
                      </CustomButton>
                    )}
                    {isOpenValidation && (
                      <ModalOverlay>
                        <Modal
                          style={{ marginTop: "10%" }}
                          smModal
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          <h3>
                            {isEdit
                              ? "Toutes les modifications effectuées sur ce collège (listes incluses) seront perdues"
                              : "Le collège ne sera pas créé"}
                          </h3>
                          <CustomButton
                            style={{ width: "auto", marginRight: 20 }}
                            invert
                            onClick={toggleIsOpenValidation}
                          >
                            <>Rester sur la page</>
                          </CustomButton>
                          <CustomButton
                            style={{ width: "auto" }}
                            href={`/cse/${cseId}/colleges`}
                            onClick={() => {
                              toggleIsOpenValidation();
                            }}
                          >
                            <>Ok, j'ai compris</>
                          </CustomButton>
                        </Modal>
                      </ModalOverlay>
                    )}
                    {(user.userType === "client" ||
                      user.userType === "clientAdmin") && (
                      <CustomButton type="submit" disabled={isSubmitting}>
                        <>Valider</>
                      </CustomButton>
                    )}
                  </div>
                </Form>
                {isOpenCreatListModal && (
                  <CseCollegeListCreate
                    collegeLists={collegeLists}
                    listOrder={listOrder}
                    onSubmit={handleSubmitCollegeList({ isEdit: false })}
                    isOpen={isOpenCreatListModal}
                    onClose={() => {
                      setIsOpenCreatListModal(false);
                    }}
                    cseId={cseId}
                  />
                )}
                {isOpenEditListModal && (
                  <CseCollegeListCreate
                    collegeLists={collegeLists}
                    listOrder={listOrder}
                    isEdit
                    cseCollegeList={selectedList}
                    onSubmit={handleSubmitCollegeList({ isEdit: true })}
                    isOpen={isOpenEditListModal}
                    onClose={() => {
                      setIsOpenEditListModal(false);
                    }}
                    cseId={cseId}
                  />
                )}
              </>
            )}
          </Formik>
        )}
      </MediumContainer>
    </StrictMode>
  );
};

export default CseCollegeCreate;
