import React, { useState } from "react";
import config from "../config";
import styled from "styled-components";
import {
  StyledLink,
  HorizontalSeparator,
  StyledA,
  ModalOverlay,
  Modal,
  ModalClose,
  Button,
} from "../utils/styled-components-library";
import * as Auth from "../utils/Auth";
import API from "../utils/API";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom";

const Container = styled.div`
  margin-top: 15px;
  text-align: left;
  margin-bottom: 20px;
`;

const closeAG = (agId) => {
  API.post("/createAg", { agId, close: true }, (data) => {
    if (data.success) {
      Swal.fire({
        title: "Session de vote clôturée !",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: "Impossible de clôturer la session de vote.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  });
};

const AGMenu = ({ ...props }) => {
  const [showCloseAgModal, setShowCloseAgModal] = useState(false);
  const [showCloneAgModal, setShowCloneAgModal] = useState(false);
  const [showPdfGeneratingModal, setShowPdfGeneratingModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const cloneAg = (agId) => {
    API.post("/createAg", { agId, clone: true }, (data) => {
      setRedirect(`/`);
    });
  };
  if (redirect) {
    return <Redirect to={redirect} push />;
  }
  return (
    <>
      <Container>
        {!props.ag?.presidentId && (
          <>
            <StyledLink
              active={props.activeTab === "edit"  ? "true" : "false"}
              to={`/ag/${props.match.params.agId}/modifier`}
              style={{ fontSize: 18 }}
            >
              Modifier les paramètres
            </StyledLink>{" "}
            •{" "}
          </>
        )}
        <StyledLink
          active={props.activeTab === "Votes" ? "true" : "false"}
          to={props.ag?.presidentId ? `/` : `/ag/${props.match.params.agId}`}
          style={{ fontSize: 18 }}
        >
          Votes
        </StyledLink>{" "}
        •{" "}
        {!props.ag?.presidentId && (
          <>
            <StyledLink
              active={props.activeTab === "Documents" ? 1 : 0}
              to={`/ag/${props.match.params.agId}/documents`}
              style={{ fontSize: 18 }}
            >
              Documents
            </StyledLink>{" "}
            •{" "}
            <StyledA
              href={`${config.apiUrl}/ordreDuJourPdf?agId=${
                props.match.params.agId
              }&token=${Auth.getToken()}`}
              target="_blank"
              style={{ fontSize: 18 }}
            >
              Ordre du jour
            </StyledA>{" "}
            •{" "}
          </>
        )}
        <StyledLink
          active={props.activeTab === "Inscrits" ? 1 : 0}
          to={
            props.ag?.presidentId
              ? `/inscrits`
              : `/ag/${props.match.params.agId}/inscrits`
          }
          style={{ fontSize: 18 }}
        >
          Inscrits ({props.ag && props.ag.voterCount})
        </StyledLink>{" "}
        {props.ag && props.ag.hasSignatureList && props.ag.hasAttendance ? (
          <StyledLink
            active={props.activeTab === "Emargement" ? 1 : 0}
            to={
              props.ag?.presidentId
                ? `/emargement-president`
                : `/ag/${props.match.params.agId}/emargement`
            }
            style={{ fontSize: 18 }}
          >
            {"• Émargements "}
          </StyledLink>
        ) : null}
        •{" "}
        <StyledLink
          active={props.activeTab === "Résultats" ? 1 : 0}
          to={
            props.ag?.presidentId
              ? `/resultats`
              : `/ag/${props.match.params.agId}/resultats`
          }
          style={{ fontSize: 18 }}
        >
          Résultats
        </StyledLink>
        {!props.ag?.presidentId && (
          <>
            {" "}
            •{" "}
            <StyledLink
              active={props.activeTab === "Accès président" ? 1 : 0}
              to={`/ag/${props.match.params.agId}/accesPresident`}
              style={{ fontSize: 18 }}
            >
              Accès président
            </StyledLink>
          </>
        )}
        {props.ag && props.ag.credentialsSendingType === "Courrier" ? (
          <>
            {" "}
            •{" "}
            <StyledA
              href={props.ag.credentialsPdfUrl}
              onClick={
                props.ag.credentialsPdfUrl
                  ? null
                  : () => setShowPdfGeneratingModal(true)
              }
              target="_blank"
              style={{ fontSize: 18, cursor: "pointer" }}
            >
              PDF Identifiants
            </StyledA>
          </>
        ) : null}
        {!props.ag?.presidentId && (
          <>
            {" "}
            •{" "}
            <span
              onClick={() => {
                setShowCloneAgModal(true);
              }}
              style={{
                color: "#1574f2",
                textDecoration: "none",
                fontSize: "18px",
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              Cloner la session de vote
            </span>{" "}
            {props.ag && props.ag.isClosed == 0 && (
              <>
                •{" "}
                <span
                  onClick={() => {
                    setShowCloseAgModal(true);
                  }}
                  style={{
                    color: "#1574f2",
                    textDecoration: "none",
                    fontSize: "18px",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  Clôturer la session de vote
                </span>
              </>
            )}
          </>
        )}
      </Container>
      <HorizontalSeparator />

      {showCloseAgModal && (
        <ModalOverlay
          onClick={() => {
            window.location.hash = "";
            setShowCloseAgModal(false);
          }}
        >
          <Modal onClick={(e) => e.stopPropagation()}>
            <ModalClose
              onClick={() => {
                window.location.hash = "";
                setShowCloseAgModal(false);
              }}
            />
            <h4>
              Êtes-vous sûr(e) de vouloir clôturer cette session de vote ? Cette
              action est irréversible.
            </h4>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                style={{ backgroundColor: "red", marginRight: "5px" }}
                onClick={() => {
                  setShowCloseAgModal(false);
                  closeAG(props.match.params.agId);
                }}
              >
                Clôturer
              </Button>
              <Button
                onClick={() => {
                  window.location.hash = "";
                  setShowCloseAgModal(false);
                }}
              >
                Annuler
              </Button>
            </div>
          </Modal>
        </ModalOverlay>
      )}
      {showCloneAgModal && (
        <ModalOverlay
          onClick={() => {
            window.location.hash = "";
            setShowCloneAgModal(false);
          }}
        >
          <Modal onClick={(e) => e.stopPropagation()}>
            <ModalClose
              onClick={() => {
                window.location.hash = "";
                setShowCloneAgModal(false);
              }}
            />
            <h4>Souhaitez-vous cloner cette session de vote ?</h4>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                white
                style={{ marginRight: 6 }}
                onClick={() => {
                  window.location.hash = "";
                  setShowCloneAgModal(false);
                }}
              >
                Annuler
              </Button>
              <Button
                onClick={() => {
                  setShowCloneAgModal(false);
                  cloneAg(props.match.params.agId);
                }}
              >
                Cloner
              </Button>
            </div>
          </Modal>
        </ModalOverlay>
      )}
      {showPdfGeneratingModal && (
        <ModalOverlay
          onClick={() => {
            window.location.hash = "";
            setShowPdfGeneratingModal(false);
          }}
        >
          <Modal onClick={(e) => e.stopPropagation()}>
            <ModalClose
              onClick={() => {
                window.location.hash = "";
                setShowPdfGeneratingModal(false);
              }}
            />
            <h4>
              Le PDF est en cours de génération par ProVote, cela peut prendre
              quelques minutes. Veuillez réessayer dans quelques instants.
            </h4>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                onClick={() => {
                  window.location.hash = "";
                  setShowPdfGeneratingModal(false);
                }}
              >
                Fermer
              </Button>
            </div>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
};

export default AGMenu;
