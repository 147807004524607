import React, { useEffect, useState, useContext } from "react";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import API from "../../utils/API";
import CseLayout from "./CseLayout";
import { CseCollegeType_Db } from "../../types/cseCollege";
import CseCollegeAccordion from "./CseCollegeAccordion";
import { UserContext } from "../../App";

const CseColleges = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [cseColleges, setCseCollege] = useState<CseCollegeType_Db[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useContext(UserContext);
  const hideModify = !(
    user.userType === "client" || user.userType === "clientAdmin"
  );

  useEffect(() => {
    API.post(
      "/cseRouteCRUD",
      { cseId, path: "/getColleges", module: "college" },
      (data: { success: boolean; data: CseCollegeType_Db[] }) => {
        setCseCollege(data.data);
        setIsLoading(false);
      }
    );
  }, [cseId]);
  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"colleges"} />
          <CseLayout
            loading={isLoading}
            title={"Paramètrage des collèges"}
            NoResultText={"Vous n’avez pas encore de collèges"}
            buttonText={"Créer un collège"}
            href={`/cse/${cseId}/colleges/creation`}
            showResults={!!cseColleges.length}
          >
            <>
              {cseColleges.map((college) => (
                <CseCollegeAccordion
                  college={college}
                  hideModify={hideModify}
                />
              ))}
            </>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CseColleges;
