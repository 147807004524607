import React from "react";
import AGList from "../screens/AGList";
import AGCreateOrEdit from "../screens/AGCreateOrEdit";
import AGDetails from "../screens/AGDetails";
import AGDocuments from "../screens/AGDocuments";
import AGVoterList from "../screens/AGVoterList";
import VoteCreateOrEdit from "../screens/VoteCreateOrEdit";
import UpdatePassword from "../screens/UpdatePassword";
import PageNotFound from "../screens/PageNotFound";
import Vote from "../screens/Vote";
import ClientAttendance from "../screens/ClientAttendance";
import VoteResults from "../screens/VoteResults";
import { Route, Switch } from "react-router-dom";
import TitleComponent from "../components/TitleComponent";
import AGPresidentAccess from "../screens/AGPresidentAccess";
import ClientLandingPage from "../screens/ClientLandingPage";
import CseList from "../screens/Cse/CseList";
import CseCreate from "../screens/Cse/CseCreate";
import CseDetails from "../screens/Cse/CseDetails";
import CseColleges from "../screens/Cse/CseColleges";
import CseCollegeCreate from "../screens/Cse/CseCollegeCreate";
import CseAccess from "../screens/Cse/CseAccess";
import CsePollingStation from "../screens/Cse/CsePollingStation";
import CseAttendance from "../screens/Cse/CseAttendance";
import CseResults from "../screens/Cse/CseResults";
import CseVotersList from "../screens/Cse/CseVotersList";
import CsePreElectoral from "../screens/Cse/CsePreElectoral";
import CseStripping from "../screens/Cse/CseStripping";
import CseReload from "../screens/Cse/CseReload";
import CseDuplicate from "../screens/Cse/CseDuplicate";
import CseDelete from "../screens/Cse/CseDelete";
import CseCerfa from "../screens/Cse/cseCerfa";
import CseLetter from "../screens/Cse/cseLetter";
import CseSecurity from "../screens/Cse/CseSecurity";
import CseEnterpriseInfo from "../screens/Cse/CseEnterpriseInfo";

class ClientNavigator extends React.Component {
  render() {
    return (
      <>
        <TitleComponent title="ProVote - Administrateur" />
        <Switch>
          <Route exact path="/" component={ClientLandingPage} />
          <Route exact path="/ag" component={AGList} />
          <Route exact path="/ag/:agId" component={AGDetails} />
          <Route exact path="/ag/:agId/documents" component={AGDocuments} />
          <Route exact path="/ag/:agId/inscrits" component={AGVoterList} />
          <Route
            exact
            path="/ag/:agId/modifier"
            render={(props) => <AGCreateOrEdit edit={true} {...props} />}
          />
          <Route
            exact
            path="/ag/:agId/parametres/:voteId"
            render={(props) => <VoteCreateOrEdit edit={true} {...props} />}
          />
          <Route exact path="/creer-ag" component={AGCreateOrEdit} />
          <Route
            exact
            path="/ag/:agId/creer-vote"
            render={(props) => <VoteCreateOrEdit {...props} />}
          />
          <Route exact path="/ag/:agId/emargement" component={ClientAttendance} />
          <Route exact path="/ag/:agId/resultats/:voteId?" component={VoteResults} />
          <Route exact path="/ag/:agId/accesPresident" component={AGPresidentAccess} />

          <Route
            exact
            path="/modifier-mot-de-passe"
            render={(props) => (
              <UpdatePassword
                userType={this.props.user.userType}
                user={this.props.user}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/vote/:voteId"
            component={(props) => <Vote user={this.props.user} {...props} />}
          />

          <Route exact path="/cse" component={CseList} />
          <Route exact path="/creer-cse" component={CseCreate} />
          <Route exact path="/cse/:cseId" component={CseDetails} />

          <Route exact path="/cse/:cseId/info" component={CseDetails} />
          <Route exact path="/cse/:cseId/colleges" component={CseColleges} />
          <Route exact path="/cse/:cseId/colleges/creation" component={CseCollegeCreate} />
          <Route
            exact
            path="/cse/:cseId/colleges/modification/:cseCollegeId"
            render={(props) => <CseCollegeCreate isEdit={true} {...props} />}
          />
          <Route exact path="/cse/:cseId/acces-autorises" component={CseAccess} />

          <Route exact path="/cse/:cseId/bureaux-de-vote" component={CsePollingStation} />

          <Route exact path="/cse/:cseId/emargement" component={CseAttendance} />

          <Route exact path="/cse/:cseId/resultats" component={CseResults} />

          <Route exact path="/cse/:cseId/liste-des-votants" component={CseVotersList} />

          <Route
            exact
            path="/cse/:cseId/protocole-d-accord-pre-electoral"
            component={CsePreElectoral}
          />

          <Route exact path="/cse/:cseId/depouillement" component={CseStripping} />

          <Route exact path="/cse/:cseId/reload/:tab" component={CseReload} />

          <Route exact path="/cse/:cseId/clone" component={CseDuplicate} />

          <Route exact path="/cse/:cseId/supprimer" component={CseDelete} />

          <Route exact path="/cse/:cseId/cerfa" component={CseCerfa} />

          <Route exact path="/cse/:cseId/securite" component={CseSecurity} />

          <Route exact path="/cse/:cseId/generer-courrier" component={CseLetter} />

          <Route exact path="/cse/:cseId/info-entreprise" component={CseEnterpriseInfo} />

          <Route component={PageNotFound} />
        </Switch>
      </>
    );
  }
}

export default ClientNavigator;
