import "../components/EmailRichEditor.css";
import React from "react";
import styled from "styled-components";
import API from "../utils/API";
import {
	Button,
	MediumContainer,
	Modal,
	ModalOverlay,
	ModalClose,
	Input,
	Error,
	Label,
} from "../utils/styled-components-library";
import { Formik, Form } from "formik";
import { Redirect } from "react-router-dom";
import AGMenu from "../components/AGMenu";
import Swal from "sweetalert2";
import FileInput from "../components/FileInput";
import { EditorState, convertToRaw } from "draft-js";
import { EmailRichEditor } from "../components/EmailRichEditor";
import draftToHtml from "draftjs-to-html";
import { voterIsParentVoter } from "../utils/votes";

const ListContainer = styled.div`
	text-align: left;
`;

const ListRow = styled.div`
	border-bottom: 1px solid #c0c3cb;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const VoteName = styled.div`
	padding: 10px 0px;
	width: 200px;
`;

const ListHeader = styled.div`
	flex-direction: row;
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 6px;
`;

const ListTitle = styled.div`
	font-size: 25px;
`;

class AGList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ag: null,
			showDelegateModal: false,
			shouldDelegatePowerOf: null,
			showEmailModal: false,
			modalType: null,
			isFileUploading: false,
			uploadedFileName: "",
		};
	}

	componentDidMount() {
		this.refreshData();
		this.interval = setInterval(() => this.refreshData(), 3000);
	}

	componentWillUnmount() {
		if (this.interval) clearInterval(this.interval);
	}

	refreshData() {
		API.get(
			"/agDetails",
			{
				agId: this.props.agId ? this.props.agId : this.props.match.params.agId,
				withVoterList: true,
			},
			(ag) => {
				console.log(ag);
				this.setState({
					ag,
				});
			}
		);
	}

	sendOrderOfBusinessEmail() {
		API.get(
			"/ordreDuJourPdf",
			{ send: true, agId: this.state.ag.agId },
			(data) => {
				if (data.success) {
					this.setState({
						showEmailModal: false,
					});
					this.interval = setInterval(() => this.refreshData(), 3000);
					Swal.fire({
						title: "Envoyé !",
						icon: "success",
						confirmButtonText: "Ok",
					});
				} else {
					Swal.fire({
						title: "Erreur",
						text: "Erreur lors de l'envoi des emails.",
						icon: "error",
						confirmButtonText: "Ok",
					});
				}
			}
		);
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} push />;
		}
		return (
			<>
				<MediumContainer>
					<ListHeader>
						<ListTitle>{this.state.ag && this.state.ag.agName}</ListTitle>
					</ListHeader>
					<AGMenu activeTab="Inscrits" ag={this.state.ag} {...this.props} />

					{!this.state.ag?.presidentId && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								marginBottom: "10px",
								marginTop: "10px",
							}}
						>
							<Button
								style={{ marginRight: "30px" }}
								onClick={() => {
									this.setState({
										showEmailModal: true,
										modalType: "dayOrder",
									});
									clearInterval(this.interval);
								}}
							>
								Envoyer l'ordre du jour aux inscrits
							</Button>
							<Button
								style={{}}
								onClick={() => {
									this.setState({
										showEmailModal: true,
										modalType: "sendReminderToVoters",
									});
									clearInterval(this.interval);
								}}
							>
								Envoyer un email aux inscrits
							</Button>
						</div>
					)}

					{this.state.ag &&
						this.state.ag.voters &&
						this.state.ag.voters.length > 0 && (
							<>
								<ListContainer>
									{this.state.ag.voters.map((voter) => (
										<ListRow key={voter.voterId}>
											<VoteName>
												{voter.voterFirstname} {voter.voterLastname}
											</VoteName>
											{voter.delegateVoterId ? (
												<div>Pouvoir délégué à {voter.delegateVoterName}</div>
											) : !this.state.ag?.presidentId ? (
												<Button
													onClick={() => {
														this.setState({
															showDelegateModal: true,
															shouldDelegatePowerOf: voter,
														});
													}}
													style={{ width: 200, padding: 5 }}
													disabled={voter.voterPowerCount == 0}
												>
													Déléguer le pouvoir
												</Button>
											) : null}
										</ListRow>
									))}
								</ListContainer>
							</>
						)}
				</MediumContainer>
				{this.state.showDelegateModal ? (
					<ModalOverlay
						onClick={() => this.setState({ showDelegateModal: false })}
					>
						<Modal onClick={(e) => e.stopPropagation()}>
							<ModalClose
								onClick={() => this.setState({ showDelegateModal: false })}
							/>
							<Formik
								initialValues={{
									voterId: this.state.shouldDelegatePowerOf.voterId,
									delegateVoterId: null,
								}}
								validate={(values) => {
									const errors = {};
									if (!values.delegateVoterId) {
										errors.delegateVoterId = "Required";
									}
									return errors;
								}}
								onSubmit={(values, { setSubmitting, setErrors }) => {
									API.post("/delegatePower", values, (data) => {
										setSubmitting(false);
										this.refreshData();
										if (data.success) {
											this.setState({ showDelegateModal: false });
										}
									});
								}}
								validateOnMount
							>
								{({ isSubmitting, isValid, values }) => (
									<Form>
										<Label>
											Déléguer le pouvoir de{" "}
											{this.state.shouldDelegatePowerOf.voterFirstname}{" "}
											{this.state.shouldDelegatePowerOf.voterLastname} à
										</Label>
										<Input component="select" name="delegateVoterId">
											<option value="">-</option>
											{this.state.ag.voters
												.filter((voter) => {
													// Voter to delegate power
													const selectedVoterIsParentVoter = voterIsParentVoter(
														this.state.ag.voters,
														this.state.shouldDelegatePowerOf
													);

													const isUnderDelegatedPowerLimits =
														this.state.ag.maximumDelegatedPower === 0 ||
														voter.receivedDelegations + 1 <=
															this.state.ag.maximumDelegatedPower;

													const voterHasParentVoter =
														voter.parentVoterId &&
														voter.parentVoterId ===
															this.state.shouldDelegatePowerOf.voterId;

													return (
														!voter.delegateVoterId &&
														voter.voterId !==
															this.state.shouldDelegatePowerOf.voterId &&
														(voter.voterPowerCount !== 0 ||
															voterHasParentVoter) &&
														((!voter.parentVoterId &&
															!selectedVoterIsParentVoter &&
															isUnderDelegatedPowerLimits) ||
															(voterHasParentVoter &&
																isUnderDelegatedPowerLimits))
													);
												})
												.map((voter) => (
													<option key={voter.voterId} value={voter.voterId}>
														{voter.voterFirstname} {voter.voterLastname}
													</option>
												))}
										</Input>
										<Button
											onClick={this.submit}
											disabled={!isValid || isSubmitting}
										>
											Valider
										</Button>
									</Form>
								)}
							</Formik>
						</Modal>
					</ModalOverlay>
				) : null}

				{this.state.showEmailModal ? (
					<ModalOverlay
						onClick={() => {
							this.setState({ showEmailModal: false });
							this.interval = setInterval(() => this.refreshData(), 3000);
						}}
					>
						<Modal onClick={(e) => e.stopPropagation()}>
							<ModalClose
								onClick={() => {
									this.setState({ showEmailModal: false });
									this.interval = setInterval(() => this.refreshData(), 3000);
								}}
							/>
							{this.state.modalType === "dayOrder" ? (
								<>
									<p>
										Êtes-vous sûr(e) de vouloir envoyer l'ordre du jour{" "}
										{this.state.ag.voters.length > 1 ? "aux" : "à"}{" "}
										{this.state.ag.voters.length} inscrit
										{this.state.ag.voters.length > 1 ? "s" : ""} ?
									</p>
									<div style={{ display: "flex", flexDirection: "row" }}>
										<Button
											style={{ backgroundColor: "green", marginRight: "10px" }}
											onClick={() => this.sendOrderOfBusinessEmail()}
										>
											Envoyer
										</Button>
										<Button
											style={{ backgroundColor: "red" }}
											onClick={() => {
												this.setState({ showEmailModal: false });
												this.interval = setInterval(
													() => this.refreshData(),
													3000
												);
											}}
										>
											Annuler
										</Button>
									</div>
								</>
							) : (
								<>
									<Formik
										initialValues={{
											subject: "",
											content: EditorState.createEmpty(),
											attachment: "",
										}}
										validate={(values) => {
											console.log(values);
											let errors = {};
											if (!values.subject)
												errors.mailError = "Veuillez indiquer un objet.";
											if (!values.content.getCurrentContent().hasText())
												errors.mailError = "Veuillez indiquer un message.";
											return errors;
										}}
										onSubmit={(values, { setSubmitting, setErrors }) => {
											API.post(
												"/sendMail",
												{
													...values,
													agId: this.props.match.params.agId,
													filename: this.state.uploadedFileName,
													content: draftToHtml(
														convertToRaw(values.content.getCurrentContent())
													),
												},
												(data) => {
													setSubmitting(false);
													if (data.success) {
														Swal.fire(
															"Email envoyé !",
															"L'email a été envoyé à tous les inscrits.",
															"success"
														);
														this.setState({ showEmailModal: false });
														this.interval = setInterval(
															() => this.refreshData(),
															3000
														);
													} else {
														setErrors({
															mailError:
																"Impossible d'envoyer l'email, réessayez ultérieurement.",
														});
													}
												}
											);
										}}
										validateOnMount
									>
										{({
											isSubmitting,
											isValid,
											values,
											setFieldValue,
											handleBlur,
										}) => (
											<Form>
												<Label style={{ textAlign: "center" }}>
													Entrez l'objet ainsi que le corps de l'email que vous
													souhaitez envoyer à tous les inscrits.
												</Label>
												<Input
													type="text"
													name="subject"
													placeholder="Objet de l'email"
												/>

												<EmailRichEditor
													editorState={values.content}
													onChange={setFieldValue}
													onBlur={handleBlur}
												/>

												<Label style={{ marginTop: 6 }}>Pièce jointe</Label>
												<FileInput
													name="attachment"
													customOnChange={(e) => {
														this.setState({
															uploadedFileName: e.target.files[0].name,
														});
														this.setState({ isFileUploading: true });
													}}
													onLoadingComplete={() => {
														this.setState({ isFileUploading: false });
													}}
												/>
												<Error name="attachment" component="div" />
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														marginTop: "20px",
													}}
												>
													<Button
														type="submit"
														onClick={this.submit}
														disabled={
															!isValid ||
															isSubmitting ||
															this.state.isFileUploading
														}
														style={{ marginRight: "10px" }}
													>
														Envoyer
													</Button>
													<Button
														white
														onClick={() => {
															this.setState({ showEmailModal: false });
															this.interval = setInterval(
																() => this.refreshData(),
																3000
															);
														}}
													>
														Annuler
													</Button>
												</div>
											</Form>
										)}
									</Formik>
								</>
							)}
						</Modal>
					</ModalOverlay>
				) : null}
			</>
		);
	}
}

export default AGList;
