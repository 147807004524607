import { CseCollegeListType_Db } from "../../../types/cseCollegeList";
import classes from "./CseVote.module.scss";
import config from "../../../config";

const ListPreview = ({
  collegeList,
}: {
  collegeList: CseCollegeListType_Db;
}) => (
  <div className={classes.ListPreviewContainer}>
    <img
      style={{
        objectFit: "contain",
        height: "76px",
        width: "76px",
      }}
      src={config.s3BaseUrl + collegeList.collegeListImage}
      alt="Entité"
    />
    <div className={classes.CollegeList_titleContainer}>
      <span className={classes.name}>{collegeList.collegeListName}</span>
      <span className={classes.affiliation}>
        {" "}
        {collegeList.collegeListAffiliation}{" "}
      </span>
    </div>
  </div>
);

export default ListPreview;
