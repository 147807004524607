import React, { useContext, useEffect, useState } from "react";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import CseLayout from "./CseLayout";
import API from "../../utils/API";
import { UserContext } from "../../App";
import {
  Modal,
  ModalClose,
  ModalOverlay,
} from "../../utils/styled-components-library";
import { toast } from "react-toastify";
import CustomButton from "../../components/buttons/CustomButton";
import moment from "moment";

const CseDelete = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [loaded, setIsloaded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const user = useContext(UserContext);

  const isFinished = moment.utc(cse?.cseEndDate).isBefore(moment.utc());

  return (
    <>
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"supprimer"} />
          <CseLayout
            title={"Supprime le CSE"}
            showResults={loaded}
            loading={!loaded}
          >
            <>
              <strong>Tous les éléments du CSE seront supprimés</strong>
              {!isFinished && (
                <>
                  <br />
                  <strong>
                    Vous ne pouvez pas supprimer un CSE qui n'est pas terminé
                    (la date de clôture n'est pas encore passée).
                  </strong>
                  Si vous souhaitez supprimer ce CSE vous pouvez modifier la
                  date de fin.
                  <br />
                </>
              )}
              {user.userType === "client" || user.userType === "clientAdmin" ? (
                <CustomButton
                  type="submit"
                  disabled={isSubmitting || !isFinished}
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  Supprimer ce CSE
                </CustomButton>
              ) : (
                <div>Vous n'avez pas les droits nécessaires</div>
              )}
            </>
          </CseLayout>
          {isOpenModal && (
            <ModalOverlay
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              <Modal onClick={(e) => e.stopPropagation()}>
                <ModalClose
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                />
                <>
                  <p>
                    Attention, après la suppression du CSE, toutes les données
                    associées seront supprimées. Voulez-vous vraiment supprimer
                    le CSE ?
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "15px",
                    }}
                  >
                    <CustomButton
                      style={{ backgroundColor: "red" }}
                      onClick={() => {
                        setIsSubmitting(true);
                        API.post(
                          "/cseRouteCRUD",
                          {
                            cseId,
                            module: "cse",
                            path: "/deleteCse",
                          },
                          (data: { success: boolean; data: any }) => {
                            setIsSubmitting(false);
                            if (data.success) {
                              toast.success(
                                `Le CSE a été supprimé avec succès`
                              );
                            }
                          },
                          () => {
                            setIsSubmitting(false);
                            toast.error(`Le CSE n'a pas été supprimé !`);
                          }
                        );
                      }}
                    >
                      Supprimer
                    </CustomButton>
                    <CustomButton
                      onClick={() => {
                        setIsOpenModal(false);
                      }}
                    >
                      Non
                    </CustomButton>
                  </div>
                </>
              </Modal>
            </ModalOverlay>
          )}
        </>
      )}
    </>
  );
};

export default CseDelete;
