import React, { useEffect, useState, useContext } from "react";
import useCse from "../../hooks/useCse";
import CseHeader from "./CseHeader";
import API from "../../utils/API";
import CseLayout from "./CseLayout";
import {
  Button,
  ModalOverlay,
  Modal,
  ModalClose,
  Input,
  Error,
  Label,
} from "../../utils/styled-components-library";
import { Formik, Form, Field } from "formik";
import CustomButton from "../../components/buttons/CustomButton";
import { toast } from "react-toastify";
import { UserContext } from "../../App";
import BackToVote from "../../components/BackTovote";

const CsePollingStation = ({ match }: { match: any }) => {
  const cseId = match.params.cseId;
  const cse = useCse(cseId);
  const [csePollingStations, setCsePollingStations] = useState([]);
  const [collegesWithNoPollingStation, setCollegesWithNoPollingStation] =
    useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [selectedPollingStation, setSelectedPollingStation] = useState({});

  const user = useContext(UserContext);
  const isAdmin = user.userType === "client" || user.userType === "clientAdmin";

  useEffect(() => {
    setIsLoading(false);
    API.get(
      "/getPollingStations",
      { cseId },
      (data: { success: boolean; data: any }) => {
        console.log(data);
        setCsePollingStations(data.data.pollingStations);
        setCollegesWithNoPollingStation(data.data.collegesWithNoPollingStation);
        setIsLoading(false);
      }
    );
  }, [isOpenModal, cseId]);

  return (
    <>
      {(user.userType === "cseVoter" && <BackToVote />) || null}
      {cse && (
        <>
          <CseHeader cse={cse} selectedTab={"bureaux-de-vote"} />
          <CseLayout
            showResults
            title={"Bureaux de vote"}
            loading={isLoading}
            buttonText={"Créer un bureau de vote"}
            onClick={() => {
              setIsModify(false);
              setIsOpenModal(true);
            }}
          >
            <>
              {!!collegesWithNoPollingStation.length && (
                <p
                  style={{ textAlign: "left", width: "100%", color: "#58626F" }}
                >
                  Il manque l’association de{" "}
                  {collegesWithNoPollingStation.length} collège(s) à un bureau
                  de vote
                </p>
              )}
              {csePollingStations.map((pollingStation) => (
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#F5F6F9",
                    padding: "28px 23px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <strong>Collège : {pollingStation.collegeName}</strong>
                    <p
                      style={{
                        marginTop: 5,
                        marginBottom: 0,
                        color: "#58626F",
                      }}
                    >
                      {pollingStation.pollingStationName}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    {(isAdmin && (
                      <CustomButton
                        type="submit"
                        disabled={isSubmitting}
                        style={{ width: "auto", padding: "8px 41px" }}
                        onClick={() => {
                          setIsModify(true);
                          setIsOpenModal(true);
                          setSelectedPollingStation(pollingStation);
                        }}
                      >
                        <>Modifier</>
                      </CustomButton>
                    )) ||
                      null}
                    <CustomButton
                      type="submit"
                      disabled={isSubmitting}
                      style={{ width: "auto", padding: "8px 41px" }}
                      href="acces-autorises"
                    >
                      <>Voir</>
                    </CustomButton>
                  </div>
                </div>
              ))}
              {isOpenModal && (
                <ModalOverlay
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                >
                  <Modal onClick={(e) => e.stopPropagation()}>
                    <ModalClose
                      onClick={() => {
                        setIsOpenModal(false);
                      }}
                    />
                    <>
                      <h4 style={{ textAlign: "left" }}>
                        Créer un bureau de vote
                      </h4>

                      <Formik
                        initialValues={{
                          pollingStationName:
                            selectedPollingStation?.pollingStationName || "",
                          selectedCollege: "",
                        }}
                        validate={(values) => {
                          const errors = {};

                          if (!values.pollingStationName) {
                            errors.pollingStationName = "Requis";
                          }

                          if (!values.selectedCollege && !isModify) {
                            errors.selectedCollege = "Requis";
                          }
                          return errors;
                        }}
                        onSubmit={async (values) => {
                          setIsSubmitting(true);

                          API.post(
                            "/createPollingStations",
                            {
                              cseId,
                              pollingStationName: values.pollingStationName,
                              cseCollegeId: values.selectedCollege,
                              isModify,
                              pollingStationId:
                                selectedPollingStation.pollingStationId,
                            },
                            (data: { success: boolean; data: any }) => {
                              setIsSubmitting(false);
                              if (data.success) {
                                toast.success(
                                  `Le bureau de vote a été ${
                                    isModify ? "modifié" : "créé"
                                  } !`
                                );
                                setIsOpenModal(false);
                              } else {
                                toast.error(
                                  `Le bureau de vote n'a pas pu être ${
                                    isModify ? "modifié" : "créé"
                                  } !`
                                );
                              }
                            },
                            () => {
                              setIsSubmitting(false);
                              toast.error(
                                `Le bureau de vote n'a pas pu être ${
                                  isModify ? "modifié" : "créé"
                                } !`
                              );
                            }
                          );
                        }}
                        validateOnMount
                      >
                        <Form autoComplete="off">
                          <Label>Nom du bureau de vote *</Label>
                          <Input
                            name="pollingStationName"
                            placeholder="Nom du bureau de vote"
                          />
                          <Error
                            style={{
                              textAlign: "left",
                            }}
                            name="pollingStationName"
                            component="div"
                          />
                          {!isModify && (
                            <>
                              <Label>Associer un collège *</Label>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: 5,
                                }}
                                role="group"
                                aria-labelledby="my-radio-group"
                              >
                                {collegesWithNoPollingStation.map((college) => (
                                  <label>
                                    <Field
                                      type="radio"
                                      name="selectedCollege"
                                      value={String(college.cseCollegeId)}
                                    />
                                    {college.collegeName}
                                  </label>
                                ))}
                              </div>
                              <Error
                                style={{
                                  textAlign: "left",
                                }}
                                name="selectedCollege"
                                component="div"
                              />
                            </>
                          )}
                          <div
                            style={{
                              marginTop: 20,
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <CustomButton
                              style={{ width: "auto" }}
                              white
                              onClick={() => {
                                setIsOpenModal(false);
                              }}
                            >
                              <>Retour</>
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              disabled={isSubmitting}
                              style={{ width: "auto" }}
                            >
                              <>
                                {" "}
                                {`${
                                  isModify ? "Modifier" : "Créer"
                                }  le bureau de vote`}
                              </>
                            </CustomButton>
                          </div>
                        </Form>
                      </Formik>
                    </>
                  </Modal>
                </ModalOverlay>
              )}
            </>
          </CseLayout>
        </>
      )}
    </>
  );
};

export default CsePollingStation;
